import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadFileStatus, UploadFileProgressInfo } from '../..';

@Component({
  selector: 'app-upload-file-toast',
  templateUrl: './upload-file-toast.component.html',
  styleUrls: ['./upload-file-toast.component.scss']
})
export class UploadFileToastComponent implements OnInit {
  @Input() filesInfo: UploadFileProgressInfo[] = [];
  @Output() cancelFile = new EventEmitter<UploadFileProgressInfo>();
  @Output() cancelAll = new EventEmitter<boolean>();
  status = UploadFileStatus;
  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

  get hasUploadData() {
    return this.filesInfo?.length > 0;
  }

  getStatusCss(info: UploadFileProgressInfo) {
    return info.status;
  }

  onCancel(info: UploadFileProgressInfo) {
    info?.subscription?.unsubscribe();
    info.status = UploadFileStatus.CANCEL;
    this.cancelFile.emit(info);
  }

  onCancelAll() {
    this.filesInfo?.forEach(e=>e?.subscription?.unsubscribe());
    this.cancelAll.emit(true);
  }

}
