import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';

const baseMetadata = {
  additionalCollectionState: { page: {} }
};

const entityMetadata: EntityMetadataMap = {
  'leave-requests': {
    ...baseMetadata,
  },
  'shifts': {
    ...baseMetadata,
  },
  'banks': {},
  'companies': {
    ...baseMetadata,
  },
  'categories': {
    ...baseMetadata,
  },
  'type-of-leaves': {
    ...baseMetadata,
  },
  'users': {
    ...baseMetadata,
  },
  'projects': {
    ...baseMetadata,
  },
  'tasks': {
    ...baseMetadata,
  },
  'locations': {
    ...baseMetadata,
  },
  'teams': {
    ...baseMetadata,
  },
  'groups': {
    ...baseMetadata,
  },
  'holidays': {
    ...baseMetadata,
  },
  'roles': {
    ...baseMetadata,
    // selectId: (role: Role) => role.roleCode,
  },
  'shift-profiles': {
    ...baseMetadata,
  },
  'backlogs': {
    ...baseMetadata,
  },
  'epics': {
    ...baseMetadata,
  },
  'okr-workspace': {
    ...baseMetadata,
  },
  'okr-timeline': {
    ...baseMetadata,
  },
  'okr': {
    ...baseMetadata
  },
  'audit-log': {
    ...baseMetadata
  },
  'job-title': {
    ...baseMetadata,
  },
  'hrm/members': {
    ...baseMetadata,
  }
};

const pluralNames = {
  'leave-requests': 'leave-requests',
  'shifts': 'shifts',
  'banks': 'banks',
  'type-of-leaves': 'type-of-leaves',
  'users': 'users',
  'roles': 'roles',
  'locations': 'locations',
  'teams': 'teams',
  'groups': 'groups',
  'holidays': 'holidays',
  'categories': 'categories',
  'companies': 'companies',
  'shift-profiles': 'shift-profiles',
  'projects': 'projects',
  'tasks': 'tasks',
  'backlogs': 'backlogs',
  'epics': 'epics',
  'okr-workspace': 'okr-workspace',
  'okr-timeline': 'okr-timeline',
  'okr': 'okr',
  'audit-log': 'audit-log',
  'job-title': 'job-title',
  'company-structure': 'company-structure',
  'hrm/members': 'hrm/members',
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
