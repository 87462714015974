import { Policy } from '..';

export interface UserPolicy {
  policy: PolicyInfo;
  acknowledged: boolean;
  benefitRule: UserBenefit[];
  orderNumber?: number;
}

export interface UserBenefit {
  benefitsRules: {
    id: number;
    key: string;
    name: string;
    description: string;
    value: number;
    type: string;
  };
  acknowledged: boolean;
}

export interface UserPolicyRequest {
  userId: number;
}

export interface UserPolicyPutRequest {
  policyId: number;
  benefitRuleId: number;
  acknowledged: boolean;
}

export interface PolicyInfo {
  id: number;
  key: string;
  name: string;
  description: string;
  applyDate: string;
  endDate: string;
  deleted: boolean;
  members: PolicyMember[];
}

export interface PolicyMember {
  id: number;
  user: UserInfo;
  group: GroupInfo;
  team: TeamInfo;
}

export interface UserInfo {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  fullName: string;
  avatar: string;
  status: string;
  jobTitle: string;
  companyId: string;
}

export interface GroupInfo {
  id?: number;
  name: string;
  description: string;
  status: string;
  deleted: false;
  defaultFlg: true;
  totalMember: number;
}

export interface TeamInfo {
  id?: number;
  name: string;
  description?: string;
  leaderId: number;
  leaderName?: string;
  status: string;
  deleted: false;
  totalMember: number;
  defaultFlg: true;
}
