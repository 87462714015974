import { Component, OnInit } from '@angular/core';
import { debounceTime, startWith } from 'rxjs/operators';
import { GroupService } from 'src/app/site-management/group/_services/group.service';
import { SearchControlComponent } from '../base/search-base.component';
import { IGroup } from './search-group';
import * as queryString from 'query-string';

@Component({
  selector: 'app-search-group',
  templateUrl: './search-group.component.html',
  styleUrls: ['./search-group.component.scss']
})
export class SearchGroupComponent extends SearchControlComponent<IGroup> implements OnInit {
  constructor(private groupService: GroupService) {
    super();
  }

  ngOnInit(): void {
    this.typing$.pipe(startWith(''), debounceTime(500)).subscribe((res) => {
      this.searchDto.keyword = res;
      this.fetch();
    });
  }

  fetch(): void {
    const query = queryString.stringify(this.searchDto);

    this.sub?.unsubscribe();
    this.sub = this.groupService.getWithQuery(query).subscribe((res) => {
      this.items = res;
    });
  }
}
