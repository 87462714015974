import { createReducer, on } from '@ngrx/store';
import { SearchSystemState } from './search-system.model';
import * as TaskAction from './search-system.actions';
export const featureKey = 'searchSystem';

const initialState: SearchSystemState = {
  latestPage: null
};

export const searchSystemReducer = createReducer(
  initialState,
  on(TaskAction.saveLatestPage, (state, { latestPage }) => {

    return {
      ...state,
      latestPage
    };
  })
);
