import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { pickerColors } from '../../_utils';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
  @Input() selectedColor = '';
  @Input() colors: string[] = pickerColors;
  @Input() size = 20;

  @Output() colorChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {

  }

  changeColor(color: string) {
    this.selectedColor = color;
    this.colorChange.emit(this.selectedColor);
  }

}
