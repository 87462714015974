

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject } from 'rxjs';

import { AuthService } from 'src/app/authentication/_services/auth.service';
import { DATE_UI_FORMAT, User } from 'src/app/shared';

// TODO: this component should be moved to site-management _components shared folder
@Component({
    selector: 'app-base-upsert',
    template: `
        <div>
            base upsert component!
        </div>`
})

export class BaseUpsertComponent implements OnInit, OnDestroy {
    @Input() model: any;
    form: UntypedFormGroup;
    @Output() formSubmit = new EventEmitter<any>();
    loggedUser: User;
    isFormSubmitClicked = false;
    isFormSubmitted = false;

    bsConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: DATE_UI_FORMAT,
        customTodayClass: 'bs-datepicker-today'
    };
    destroyed$ = new Subject<void>();

    constructor(protected fb: UntypedFormBuilder, protected authService: AuthService) {
        this.loggedUser = this.authService.getLoggedUser();
    }

    async ngOnInit() {
        this.createForm();
    }
    ngOnDestroy() {
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            description: [' test '],
            privateFlg: [false],
        }, {
            validator: Validators.compose([

            ])
        });

    }

    onSubmit() {
        this.formSubmit.emit();
        this.isFormSubmitted = true;
    }

    onCancel() {

        this.formSubmit.emit();
    }

    protected getFormCtrl(controlName: string): UntypedFormControl {
        return this.form.get(controlName) as UntypedFormControl;
    }

}
