import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ANTLRInputStream, CommonTokenStream } from 'antlr4ts';
import { FieldExpressionLexer } from './_antlr/FieldExpressionLexer';
import { FieldExpressionParser } from './_antlr/FieldExpressionParser';

@Component({
  selector: 'app-fx-editor',
  templateUrl: './fx-editor.component.html',
  styleUrls: ['./fx-editor.component.scss']
})
export class FxEditorComponent implements OnInit {
  control = new UntypedFormControl('1 + 2 + (field_1 * field_2) * 3');
  constructor() { }

  ngOnInit(): void {
    this.sqlTest();
  }

  sqlTest() {
    // Create the lexer and parser
    const text = this.control.value;
    const inputStream = new ANTLRInputStream(text);
    const lexer = new FieldExpressionLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new FieldExpressionParser(tokenStream);
    const tree = parser.expression();
  }
}
