import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { JiraMarkService } from 'libs/jira-mark/src/public-api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskSkeletonUrl } from 'src/app/shared';
import { FilePreview, FilePreviewComponent } from 'src/app/shared/_components/file-preview/file-preview.component';
import { FADE } from 'src/app/shared/_utils/animate';
import { FileService } from 'src/app/site-management/document/_services/file.service';
import { Subject, takeUntil, Subscription } from 'rxjs';

export interface AwsImg {
  url: string;
  objectUrl: string;
}
@Component({
  selector: 'app-editor-html-view',
  templateUrl: './editor-html-view.component.html',
  styleUrls: ['./editor-html-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [FADE],
})
export class EditorHtmlViewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('html') html: ElementRef<HTMLElement>;

  @Input() skeletonUrl = TaskSkeletonUrl.DESCRIPTION;
  @Input() innerHTML: string;
  @Input() placeholderHtml: string = '';
  @Output() contentParsed: EventEmitter<{html: string, awsImgs: AwsImg[]}> = new EventEmitter<{html: string, awsImgs: AwsImg[]}>();

  awsImgs: AwsImg[] = [];
  loading = false;
  customKey = `editor-coment-file.svg?file-url=`;
  destroyed$ = new Subject<void>();
  subscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private fileService: FileService,
    private jiraMark: JiraMarkService
  ) { }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.innerHTML.currentValue !== changes.innerHTML.previousValue) {
      this.innerHTML = this.jiraMark.parse(this.innerHTML);

      // fix bug innerHTML change after init
      this.getImages();
    }
  }

  ngOnInit(): void {
    this.getImages();
  }

  getImages(): void {
    this.awsImgs = this.innerHTML?.match(/src\s*=\s*"(.+?)"/g)?.filter(e => !e.includes(this.customKey)).map(src => {
      const url = src.slice(5, src.length - 1); // convert src="url" => url;
      return {
        url,
        objectUrl: '',
      };
    }) || [];

    if (this.awsImgs.length > 0) {
      this.loading = true;
      this.subscription?.unsubscribe();
      this.subscription = this.fileService.getAuthenticatedUrls(this.awsImgs.map(e=> e.url))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((urls) => {
          urls.forEach((url, index) => {
            if (this.awsImgs?.[index]) {
              this.awsImgs[index].objectUrl = url || this.awsImgs[index].url;
            }
          });
          this.checkAwsImgsLoading();
        },
        (error) => {
          this.awsImgs.forEach(e=> e.objectUrl = e.url);
          this.checkAwsImgsLoading();
        }
      );
      // this.awsImgs.forEach((e, index) => {
      //   this.fileService.getAuthenticatedUrl(e.url).subscribe(
      //     (objectUrl) => {
      //       e.objectUrl = objectUrl;
      //       this.checkAwsImgsLoading();
      //     },
      //     (error) => {
      //       e.objectUrl = e.url;
      //       this.checkAwsImgsLoading();
      //     }
      //   );
      // });
    }
  }

  checkAwsImgsLoading() {
    if (this.awsImgs.every(e => e.objectUrl)) {
      this.awsImgs.forEach(e => {
        this.innerHTML = this.innerHTML.replace(e.url, e.objectUrl);
      });
      this.contentParsed.emit({
        awsImgs: this.awsImgs,
        html: this.innerHTML,
      });
      this.loading = false;
    }
  }

  getCurrentInnerHTML(): string {
    return this.html?.nativeElement?.innerHTML || '';
  }

  onClick(event: any, html: HTMLElement) {
    if (event?.target?.nodeName === 'IMG') {
      const fileUrl = this.getOriginalFileUrl(event?.target?.src);

      const files: FilePreview[] = Array.from(html.getElementsByTagName('img'))
        .map(e => {
          const url = e.src;
          return {
            name: null,
            url: this.getOriginalFileUrl(url),
            objectUrl: '',
          };
        });

      this.modalService.show(FilePreviewComponent, {
        backdrop: 'static',
        class: 'modal-preview',
        initialState: {
          files,
          currentIndex: files.findIndex(e => e.url === fileUrl) || 0
        }
      });
    }
  }

  getOriginalFileUrl(url: string) {
    return url.includes(this.customKey) ? url.split(this.customKey)[1] : (this.awsImgs.find(e => e.objectUrl === url)?.url || url);
  }

}
