<div class="switch-date-wrapper">
  <div class="switch-date-group">
    <button class="btn btn-flex btn-icon-circle previous" (click)="changeDate(-1)">
      <span class="material-icons">
        navigate_before
      </span>
    </button>

    <div class="date-info" [ngClass]="{'day': type === periodDay}">
      <span *ngFor="let i of splitDate(formatDateUI(date)) || [];let first = first">
      {{first?'':', '}}<app-i18n [key]="i"></app-i18n>
      </span>
    </div>

    <button class="btn btn-flex btn-icon-circle next" (click)="changeDate(1)">
      <span class="material-icons">
        navigate_next
      </span>
    </button>
  </div>

  <button class="btn btn-today ml-3" *ngIf="showThisPeriodBtn" (click)="changeToThisPeriod()"
    [ngClass]="isToday() ? 'btn-primary' : ''"
    >
    <app-i18n [key]="getThisPriodLabel()"></app-i18n></button>
</div>
