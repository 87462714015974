import { DocumentTemplatePatchType, DocumentTemplateType } from '../_utils';
import { BaseAttachment } from './attachment.model';
import { UserShort, Attachment, Company } from './index';

export interface DocumentTemplate {
  id: number;
  name: string;
  type: string;
  fileName?: string;
  path?: string;
  fileSize?: number;
  createdAt?: string;
  modifiedAt?: string;
  owner?: UserShort;
  company?: Company;
  attachments?: Attachment[];
  totalAttachments?: number;
}

export interface DocumentTemplateRequest {
  name?: string;
  type?: DocumentTemplateType;
}

export interface DocumentTemplateUpsertFile {
  file?: File;
  type?: DocumentTemplateAttachmentType;
  id?: number;
  typeOfObject?: string;
  previewUrl?: string;
}

export type DocumentTemplateAttachmentType = 'Comment' | 'Description' | 'Other';

export interface DocumentTemplateAttachmentRequest {
  attachments: File[];
  type?: DocumentTemplateAttachmentType;
  id?: number;
  typeOfObject?: string;
}

export class DocumentTemplateAttachment extends BaseAttachment { }

export interface DocumentTemplatePatch {
  type: DocumentTemplatePatchType;
  value: string | Date;
}

export interface DocumentTemplateSearch {
  name?: string;
  type?: string;
}

export interface DocumentTemplateDownloadRequest {
  userId: number;
  templateId: number;
}
