
import { BenefitRule } from "..";
import { Group } from "./group.model";
import { Team } from "./team.model";
import { User } from "./user.model";

export interface Policy {
  id: number;
  key: string;
  name: string;
  description: string;
  applyOrder: string;
  applyDate: string;
  endDate: string;
  deleted: boolean;
  assigned: string;
  members: Member[];
  benefitsRules?: BenefitRule[];
  expanded?: boolean;
  addLink?: boolean;
}

export interface Member {
  id: number;
  user: User;
  group: Group;
  team: Team;
}
