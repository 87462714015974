import { BoardEpic, BoardLabel, BoardReleaseListResponse, BoardSprint, BoardStatus,
  Project, ProjectMemberUser, ProjectPlatform, ProjectPriority
} from 'src/app/shared';
import { BoardStoryline } from 'src/app/shared/_models/board-storyline.model';

export interface ProjectState {
  projects: Project[];
  members: ProjectMemberUser[];
  statuses: BoardStatus[];
  priorities: ProjectPriority[];
  releases: BoardReleaseListResponse[];
  epics: BoardEpic[];
  sprints: BoardSprint[];
  labels: BoardLabel[];
  storyLines: BoardStoryline[];
  platforms: ProjectPlatform[];
}
