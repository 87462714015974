import { Component, Output, EventEmitter, ElementRef, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { MultiFilterComponent } from '../multi-filter/multi-filter.component';
import { I18nService } from 'src/app/site-management/_services';
import { TranslateService } from '@ngx-translate/core';
import { DATE_FORMAT, DATE_UI_FORMAT, FilterItem, FilterObject, FilterType, SelectOption, dateFiltersValue, exceptFilterByKeys } from '../..';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { UtilService } from 'src/app/core';

@Component({
  selector: 'app-basic-filter',
  templateUrl: './basic-filter.component.html',
  styleUrls: ['./basic-filter.component.scss', '../multi-filter/multi-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicFilterComponent extends MultiFilterComponent {
  @ViewChild('dateRangePicker') dateRangePicker: ElementRef;
  @Input() xqlQuery = '';
  @Input() showFilterBy = true;
  @Output() filterSubmit = new EventEmitter<FilterObject>();
  @Output() showFilter = new EventEmitter<void>();
  @Output() hideFilter = new EventEmitter<void>();

  isCustomFilter = false;
  FilterType = FilterType;
  selectedDateRangePickerValues: Date[] = [];
  currentFilterSelecteds = {};
  currentCustomQuery: string[] = [];

  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    rangeInputFormat: DATE_UI_FORMAT,
  };

  constructor(
    i18nService: I18nService,
    translateService: TranslateService
  ) {
    super(i18nService, translateService);
    this.checkWindowSize();
  }

  get selectedDateFilterOption(): SelectOption {
    return this.filter?.dueDate?.selecteds[0] || null;
  }

  get filtersWithoutExceptedKey() {
    const filterKey = Object.keys(this.filter).filter(key => !exceptFilterByKeys.includes(key));
    return filterKey.map(key => this.filter[key]);
  }

  get hasFilterSelected() {
    for (const key in this.filter) {
      if (this.filter[key].selecteds.length > 0 && !exceptFilterByKeys.includes(key)) {
        return true;
      }
    }
    return false;
  }

  canClearable(filterItem) {
    return filterItem.selecteds.length > 0;
  }

  clearFilterItemSelected(filterItem) {
    filterItem.selecteds = [];
  }

  onSubmit() {
    this.filterSubmit.next(this.filter);
  }

  onDateChanged(event, filter) {
    if (event) {
      filter.selecteds = [moment(event).format(DATE_UI_FORMAT)];
    }
  }

  selectPeriodOption(filterItem, option: SelectOption) {
    filterItem.selecteds = [option];
    if (option?.value === dateFiltersValue.CUSTOM) {
      this.dateRangePicker.nativeElement.click();
    }
  }

  changeDates(selectedDates: any[], filter) {
    if (selectedDates?.length === 2 && filter.selecteds[0]?.value === dateFiltersValue.CUSTOM) {
      filter.extraData = [{
        startDate: String(selectedDates[0] ?? new Date()),
        endDate: String(selectedDates[1] ?? new Date())
      }];
    }
  }

  onShowFilterChange(event, filterItem) {
    filterItem.hidden = !event.target.checked;
  }

  setupCurrentFilter() {
    const newObj = {};
    for (const prop in this.filter) {
      if (this.filter.hasOwnProperty(prop)) {
        newObj[prop] = Object.assign({}, this.filter[prop]);
        delete newObj[prop].typeahead;
      }
    }
    this.currentFilterSelecteds = JSON.parse(JSON.stringify(newObj));
    this.showFilter.next(null);
  }

  onCancelFilter() {
    for (const prop in this.currentFilterSelecteds) {
      if (this.currentFilterSelecteds.hasOwnProperty(prop)) {
        this.filter[prop].selecteds = this.currentFilterSelecteds[prop].selecteds;
      }
    }
  }

  addNewCustomQuery() {
    this.filter.customQuery.selecteds.push('');
  }

  onCustomQueryChanged(event, index: number) {
    this.currentCustomQuery[index] = event;
  }

  removeCustomQuery(index: number) {
    this.filter.customQuery.selecteds.splice(index, 1);
  }

  approveQuery(index: number) {
    this.filter.customQuery.selecteds[index] = this.currentCustomQuery[index];
  }

  removeDropdownSelectedItem(filter, selected) {
    filter.selecteds = filter.selecteds.filter(item => item.name !== selected.name);
  }

  removeSelected(event: Event, filter: FilterItem<any>, index: number, reload: boolean = false) {
    this.stopEvent(event);
    if (index === -1) {
      filter.selecteds = [];
    } else {
      filter.selecteds.splice(index, 1);
    }
    if (reload) {
      this.onSubmit();
    }
  }

  getPeriodLabel(filter) {
    let startDate = '';
    let endDate = '';
    const selectedDate = filter.selecteds[0];

    if (selectedDate.value === dateFiltersValue.CUSTOM && filter?.extraData) {
      const extraData = filter.extraData[0];
      startDate = moment(extraData.startDate).format(DATE_UI_FORMAT);
      endDate = moment(extraData.endDate).format(DATE_UI_FORMAT);
      return `${startDate} - ${endDate}`;
    }
    return selectedDate.name;
  }

  isSelectedFilter(filter, selected) {
    return filter.selecteds.some(el => el.name === selected.name);
  }
}
