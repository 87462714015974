import { StatusCategory } from './board-status.model';

export interface TaskStatus {
  id: number;
  name: string;
  colorCode: string;
  icon: string;
  defaultFlg: boolean;
  statusCategory?: StatusCategory;
}

export interface StatusDefault {
  name: string;
  icons: StatusDefaultIcon[];
}

export interface StatusDefaultIcon {
  icon: string;
  colorCode: string;
}
