import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActionLinkField, DynamicActionType, DynamicField, DynamicFieldHash, DynamicFieldTree,
  DynamicFieldType, FieldPatchRequest, FieldProperties, getRouterObjectByHash, routerObject
} from 'src/app/shared';
import { FieldService } from './field-service';


@Injectable({
  providedIn: 'root'
})
export class DynamicFieldDataService {
  dynamicFields: DynamicField[];
  fieldTrees: DynamicFieldTree[] = [];
  changeActionType$ = new Subject<{type: DynamicActionType, value?: DynamicField}>();
  reloadDynamicField$ = new Subject<boolean>();
  destroyed$ = new Subject<void>();

  constructor(
    private fieldService: FieldService,
  ) {
  }

  storeFields(fields: DynamicField[] = []) {
    this.dynamicFields = fields;
    this.buildFieldTrees();
  }

  buildFieldTrees() {
    this.fieldTrees = [];
    this.dynamicFields.forEach(field => {
      if (!field.parentId) {
        this.fieldTrees.push(field as any);
        return;
      }

      const parent: DynamicFieldTree = this.dynamicFields.find(e => e.id === field.parentId) as any;

      if (parent) {
        field.parent = parent;
        parent.childs = parent.childs || [];
        parent.childs.push(field);
      }
    });
    this.addRouterLink();
  }

  addRouterLink() {
    // hardcode myprofile
    const org = this.fieldTrees.find(e=> e.hash === DynamicFieldHash.ORGANIZATION);
    const myprofile = org?.childs?.find(e=> e.hash === DynamicFieldHash.MY_PROFILE);
    if (myprofile) {
      myprofile.routerLink = routerObject.orgMyProfile.fullPath;
    }
  }

  getAllFieldsOfSectionHash(hash: string) {
    const item = this.getDynamicFieldByHash(hash);
    if (!item) {
      return;
    }

    if (item.fieldChilds) {
      return of(item.fieldChilds);
    }

    return this.fieldService.getAllBySection(item?.id)
      .pipe(
        tap((fields) => {
          fields.forEach(e => {
            e.sectionId = item.id;
            e.parent = item;
          });
          item.fieldChilds = fields;
        }),
        takeUntil(this.destroyed$)
      );
  }

  getDynamicFieldByHash(hash: string): DynamicField {
    return this.dynamicFields.find(f => f.hash === hash);
  }

  isVisibleField(field: DynamicField) {
    if (!field) {
      return true;
    }
    return field.isVisible && this.isVisibleField(field.parent);
  }

  canActiveHash(hash: string) {
    const field = this.getDynamicFieldByHash(hash);
    return this.isVisibleField(field);
  }

  getInitVisibilePath() {
    const fieldTree = this.fieldTrees.find(e => e.isVisible && e.childs?.some(i => i.isVisible));
    const hash = fieldTree?.childs?.find(e => e.isVisible)?.hash;
    const routerObj = getRouterObjectByHash(hash);
    return routerObj?.fullPath;
  }

  getFieldById(id: number) {
    return this.dynamicFields?.find((f) => f.id === id);
  }

  getAllFieldsOfSectionId(id: number) {
    if (!id) {
      return;
    }
    const section = this.dynamicFields?.find((f) => f.id === id);

    return this.fieldService.getAllBySection(section?.id)
    .pipe(
      tap((fields) => {
        fields.forEach(e => {
          e.sectionId = section.id;
          e.parent = section;
        });
        section.fieldChilds = fields;
      }),
      takeUntil(this.destroyed$)
    );
  }

  getMenuLevel1(section: DynamicField, type: string, value: boolean = true) {
    if (!section) {
      return null;
    }

    if (section.type === DynamicFieldType.Menulevel1) {
      this.setValueOfDynamicField(section, type, value);
      return section;
    }
    const selectItem = section.parent?.childs?.find(e => e.id === section.id);
    if (selectItem) {
      this.setValueOfDynamicField(selectItem, type, value);
    }
    return this.getMenuLevel1(section.parent, type, value);
  }

  setValueOfDynamicField(field: DynamicField, type: string, value: boolean) {
    const actions = [
      { type: ActionLinkField.Preview, property: FieldProperties.LinkedField },
      { type: ActionLinkField.Delete, property: FieldProperties.Deleted },
      { type: ActionLinkField.Search, property: FieldProperties.Searched },
      { type: ActionLinkField.Archived, property: FieldProperties.Archived},
    ];

    const action = actions.find(item => item.type === type);
    if (action) {
      field[action.property] = value;
    }
  }

  isDynamicField(field: DynamicFieldTree) {
    return [
      DynamicFieldType.Menulevel1,
      DynamicFieldType.Menulevel2,
      DynamicFieldType.Menulevel3,
      DynamicFieldType.Sections,
      DynamicFieldType.Tabs
    ].some(i => i === field.type);
  }
}
