import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FieldOption, Pageable } from 'src/app/shared/_models';
import { FieldOptionType } from 'src/app/shared/_utils';
import { FieldService } from 'src/app/site-management/dynamic-field/_services/field-service';
import { DFieldBaseComponent } from '../d-field-base.component';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-d-field-select',
  templateUrl: './d-field-select.component.html',
  styleUrls: ['./d-field-select.component.scss']
})
export class DFieldSelectComponent extends DFieldBaseComponent {
  @Input() bindValue = '';

  items: FieldOption[] = [];
  keyword = '';
  pageable: Pageable = new Pageable();
  searchSubscription: Subscription;
  constructor(
    private fieldService: FieldService,
    protected fb: UntypedFormBuilder,
  ) {
    super(fb);
  }

  get isLinkObject() {
    return this.field.optionType && this.field.optionType !== FieldOptionType.default;
  }

  openDropdown() {
    if (!this.items.length) {
      this.keyword = '';
      this.search();
    }
  }

  search() {
    this.searchSubscription?.unsubscribe();
    this.searchSubscription = this.fieldService.getOptions(this.field.id, { keyword: this.keyword }, this.pageable)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.items = res.content;
        },
        (err) => {
          this.errorFn(err);
          this.items = [];
        }
      );
  }

  showUserAvatar() {
    return this.field.optionType === FieldOptionType.user;
  }
}
