<app-modal-container #modal>
  <ng-container header> <app-i18n [key]="'Change Password'"></app-i18n> </ng-container>

  <ng-container body>
    <div class="row my-2" [formGroup]="form">
      <div class="col-lg-12 col-12">
        <div class="form-group position-relative">
          <label><app-i18n [key]="'Current Password'"></app-i18n></label>
          <input class="form-control custom" passwordVisibility formControlName="currentPassword" />
          <app-form-error [form]="form" [controlName]="'currentPassword'"></app-form-error>
        </div>
      </div>

      <div class="col-lg-12 col-12">
        <div class="form-group position-relative">
          <label><app-i18n [key]="'New Password'"></app-i18n></label>
          <input class="form-control custom" passwordVisibility formControlName="password" />
          <app-form-error [form]="form" [controlName]="'password'"></app-form-error>
        </div>
      </div>

      <div class="col-lg-12 col-12">
        <div class="form-group position-relative">
          <label><app-i18n [key]="'Re-enter Password'"></app-i18n></label>
          <input class="form-control custom" passwordVisibility formControlName="confirmPassword" />
          <app-form-error [form]="form" [controlName]="'confirmPassword'"></app-form-error>
        </div>
      </div>

    </div>
  </ng-container>

  <ng-container footer>
    <button type="button" class="btn btn-white mr-3" (click)="modal.hide()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="loading || form.pristine"
      (click)="onSubmit(modal)"
    >
      <app-i18n [key]="'Change'"></app-i18n>
    </button>
  </ng-container>
</app-modal-container>
