import { Component, EventEmitter, Output, Input } from '@angular/core';
import { AbstractComponent } from '../_base-component';

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.scss']
})
export class MobileSearchComponent extends AbstractComponent {
  @Input() searchText = '';
  @Output() search = new EventEmitter<string>();
  @Output() keywordChange = new EventEmitter<string>();
  showSearchBar = false;

  constructor() {
    super();
    this.checkWindowSize();
  }

  observeCheckWindowSize() {
    this.showSearchBar = false;
  }

  onSearch(text: string) {
    this.search.next(text);
  }

  toggleSearch(value: boolean) {
    this.showSearchBar = value;
  }

  onInput(text: string) {
    this.keywordChange.next(text);
  }
}
