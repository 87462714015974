import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { GeneralConfirmComponent } from 'src/app/site-management/_components/popups/general-confirm/general-confirm.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard  {
  constructor(private modalService: BsModalService) {

  }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate() ?
      true : this.openConfirmDialog();
  }

  openConfirmDialog() {
    const initialState = {
      title: 'Warning!',
      message: `It looks like you have been editing something.If you leave before saving, your changes will be lost.`,
      saveBtnName: 'Leave without Save',
      cancelBtnName: 'Cancel',
    };

    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });
    return confirmModalRef.content.result$;
  }
}
