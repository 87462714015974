import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserSetting, UserSettingKey, UserSettingRequest } from './user-setting.model';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {

  constructor(
    private http: HttpClient,
  ) {}

  get(key: UserSettingKey) {
    return this.http.get<UserSetting>(`${environment.apiUrl}/user-setting/?key=${key}`);
  }

  update(payload: UserSettingRequest) {
    return this.http.post<UserSetting>(`${environment.apiUrl}/user-setting/`, payload);
  }

  // update(payload: UserSettingRequest) {
  //   const params = queryString.stringify({ ...payload });
  //   return this.http.post<UserSetting>(`${environment.apiUrl}/user-setting/${params}`, {});
  // }
}
