import { Component } from '@angular/core';
import { AbstractComponent } from './abstract.component';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormBuilder } from '@angular/forms';
import { AppInjector } from 'src/app/app.module';
@Component({
  template: ``
})
export abstract class FormlyAbstractComponent extends AbstractComponent {
  fb: FormBuilder = AppInjector.get(FormBuilder);
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};
  model = {};
  form = this.fb.group(this.model);


  // Call this function on init()
  abstract setFormly(): void;

  isInvalid(extra?: boolean[]): boolean {
    this.form.markAllAsTouched();
    return this.form.invalid;
  }


  setInitialFormValue(model: {[key: string]: any}): void {
    this.model = model;
  }

  setFormlyFields(fields: FormlyFieldConfig[]) {
    this.fields = fields;
  }

  reset() {
    this.options.resetModel();
  }
}
