import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { BoardReleaseDetailNote, BoardReleaseDetailNoteRequest } from 'src/app/shared';


@Injectable({
  providedIn: 'root'
})

export class ProjectBoardReleaseDetailNoteService {
  public loading$ = new Subject<boolean>();

  constructor(private http: HttpClient) {
  }

  get(projectId: number, releaseId: number): Observable<BoardReleaseDetailNote> {
    this.loading$.next(true);
    return this.http.get<BoardReleaseDetailNote>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/release-note`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  update(projectId: number, releaseId: number, payload: BoardReleaseDetailNoteRequest) {
    this.loading$.next(true);
    return this.http.put<BoardReleaseDetailNote>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/release-note`, payload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }
}