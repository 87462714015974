<div
  class="t-user dropdown-control"
  dropdown
  #dropdown="bs-dropdown"
  container="body"
  [insideClick]="true"
  placement="bottom left"
  (click)="dropdown.isOpen && searchAssignees()"
>
  <div class="assignee-avatar">
    <app-avatar
      dropdownToggle
      [url]="task.assignee?.avatar"
      [name]="task.assignee?.fullName"
    ></app-avatar>
  </div>
  <div class="dropdown-menu custom-search-dropdown-menu" *dropdownMenu>
    <div class="header">
      <div class="title"><app-i18n [key]="'Member'"></app-i18n></div>

      <app-search-input
        (keywordChange)="assigneeTypeahead$.next($event)"
      ></app-search-input>
    </div>
    <div class="option-list">
      <div
        class="custom-option"
        *ngIf="task?.assignee"
        (click)="dropdown.isOpen = false; selectAssignee()"
      >
        <span class="material-icons icon-md unassigned-avatar">
          account_circle
        </span>
        <span class="option-label"
          ><app-i18n [key]="'Unassigned'"></app-i18n
        ></span>
      </div>

      <div
        class="custom-option"
        [ngClass]="{ selected: item.id === task?.assignee?.id }"
        *ngFor="let item of assignees"
        (click)="dropdown.isOpen = false; selectAssignee(item)"
      >
        <app-avatar
          [url]="item.avatar"
          [name]="item.fullName"
        ></app-avatar>
        <span class="option-label">{{ item.fullName }}</span>
      </div>

      <div
        class="text-center"
        *ngIf="!isLoadingAssignee && assignees.length === 0"
      >
        No options
      </div>
    </div>
  </div>
</div>
