import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';

@Pipe({
  name: 'getName'
})

export class GetNamePipe implements PipeTransform {
  transform(fullName: string): any {
    return UtilService.getAbbreviationName(fullName);
  }
}