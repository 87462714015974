import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateTimePickerOverlayService } from 'src/app/site-management/_services/date-time-picker-overlay.service';

@Directive({
  selector: '[bsDateTimePicker]',
})
export class DateTimePickerDirective {
  @Input() title: string;
  @Input() control: UntypedFormControl;

  constructor(
    private dateTimePickerOverlayService: DateTimePickerOverlayService
  ) {}

  @HostListener('click', ['$event.target'])
  onFocusEvent(elementRef: ElementRef) {
    if (elementRef) {
      this.dateTimePickerOverlayService.open(elementRef, this.control, this.title);
    }
  }
}
