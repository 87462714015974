import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/shared';
import { EmojiUserListsComponent } from 'src/app/shared/_components/emoji-user-lists/emoji-user-lists.component';

@Injectable({ providedIn: 'root' })
export class EmojiUsersOverlayService {
  overlayRef: OverlayRef;
  users$ = new BehaviorSubject<User[]>(null);

  constructor(private overlay: Overlay) {}

  get users(): User[] {
    return this.users$.value;
  }

  open(elementRef: ElementRef, users: User[]) {
    this.overlayRef?.dispose(); // clean profile overlay when open new overlay
    this.overlayRef = this.overlay.create(this.getConfig(elementRef));
    this.users$.next(users);
    this.overlayRef.attach(new ComponentPortal(EmojiUserListsComponent)); // add component to overlay
  }

  hide() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  getConfig(elementRef: ElementRef): OverlayConfig {
    return {
        width: 'max-content',
        height: 'max-content',
        maxHeight: 100,
        hasBackdrop: false,
        disposeOnNavigation: true,
        scrollStrategy: this.overlay.scrollStrategies.block(),
        positionStrategy: this.overlay
            .position()
            .flexibleConnectedTo(elementRef)
            .withPositions([
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'center'
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom'
                }
            ])
            .withFlexibleDimensions(true)
            .withViewportMargin(40)
            .withGrowAfterOpen(true)
            .withPush(false)
    };
  }
}

