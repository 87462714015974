import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BoardColumn, BoardColumnUpsertRequest, BoardStatus } from 'src/app/shared';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ProjectBoardColumnService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  getByProjectId(projectId: number): Observable<BoardColumn[]> {
    this.loading$.next(true);
    return this.http.get<BoardColumn[]>(`${environment.apiUrl}/projects/${projectId}/board-columns/`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  add(projectId: number, payload: BoardColumnUpsertRequest): Observable<BoardColumn> {
    this.loading$.next(true);
    return this.http.post<BoardColumn>(`${environment.apiUrl}/projects/${projectId}/board-columns/`, payload)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  update(projectId: number, columnId, payload: BoardColumnUpsertRequest): Observable<BoardColumn> {
    this.loading$.next(true);
    return this.http.put<BoardColumn>(`${environment.apiUrl}/projects/${projectId}/board-columns/${columnId}`, payload)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  delete(projectId: number, columnId: number) {
    this.loading$.next(true);
    return this.http.delete(`${environment.apiUrl}/projects/${projectId}/board-columns/${columnId}`)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  changeOrder(projectId: number, columnId: number, preColumnId?: number, nextColumnId?: number) {
    this.loading$.next(true)
    let payload: any = {
      columnId: columnId,
    }

    if (preColumnId) {
      payload.preColumnId = preColumnId
    }
    if (nextColumnId) {
      payload.nextColumnId = nextColumnId
    }

    return this.http
      .put(`${environment.apiUrl}/projects/${projectId}/board-columns/change-order`, payload)
      .pipe(finalize(() => this.loading$.next(false)))
  }
}
