import { StatusFeedbackUI } from "../..";
import { StatusFeedback } from "../_enums";

export const StatusFeedbacks: StatusFeedbackUI[] = [
  {
    name: 'Waiting for feedback',
    value: StatusFeedback.WAITING,
    icon: 'waiting'
  },
  {
    name: 'Completed',
    value: StatusFeedback.COMPLETED,
    icon: 'approve'
  }
];
