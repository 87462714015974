<div class="user-avatar-list-wrapper d-flex">
  <div class="list">
    <div class="avatar"
      [ngStyle]="{'left.px': index * 35, 'z-index': index}"
      *ngFor="let m of avatarMembers; let index = index" (dblclick)="navigateUser(m)">
      <app-avatar [url]="m.avatar" [name]="m.fullName" size="large"></app-avatar>
    </div>
    <div *ngIf="dropdownMembers.length > 0" class="avatar more-member">
      <div (click)="openDropdown()"
        class="text-avatar"
        [ngStyle]="{'left.px': avatarMembers.length * 35, 'z-index': avatarMembers.length }">
        <div class="bg bg-more-member d-flex justify-content-center align-items-center">
          <div>+{{dropdownMembers.length}}</div>
        </div>
      </div>
      <div class="dropdown"
        *ngIf="isShowDropdown"
        clickOutside (onClickOutside)="closeMemberDropdown()">
        <div class="menu d-flex flex-column">
          <div class="item d-inline-flex align-items-center py-2"
            *ngFor="let m of dropdownMembers" (dblclick)="navigateUser(m)">
            <app-avatar class="ml-2"
              [url]="m.avatar"
              [name]="m.fullName"></app-avatar>
            <div class="ml-2">{{m.fullName}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
