import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

export interface DateTimePickerConfig {
  position?: ConnectionPositionPair[];
}

export const DATE_TIME_PICKER_CONFIG = new InjectionToken<DateTimePickerConfig>('Date Time Picker Config');

export interface TooltipOverlayConfig {
  position?: ConnectionPositionPair[];
}

export const TOOLTIP_OVERLAY_CONFIG = new InjectionToken<TooltipOverlayConfig>('Tooltip Overlay Config');
