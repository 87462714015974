import { Component } from '@angular/core';
import { RowValue } from 'src/app/shared/_models';
import { DFieldCellBaseTableComponent } from '../d-field-cell-base-table';

@Component({
  selector: 'app-d-field-cell-file-table',
  templateUrl: './d-field-cell-file-table.component.html',
  styleUrls: ['./d-field-cell-file-table.component.scss']
})
export class DFieldCellFileTableComponent extends DFieldCellBaseTableComponent{

  cellFile: RowValue[];

  constructor() {
    super();
  }


  init() {
    super.init();
    this.cellFile = this.getDataCell() as RowValue[];
  }

  getNameOfFile() {
    if (Array.isArray(this.cellFile)) {
      const files = this.cellFile as RowValue[];
      return files?.length > 0 ?  `${files.length} items(s)` : '';
    }
    return '';
  }
}
