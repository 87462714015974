import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percent-change',
  templateUrl: './percent-change.component.html',
  styleUrls: ['./percent-change.component.scss']
})
export class PercentChangeComponent implements OnInit {
  @Input() oldval: number;
  @Input() newval: number;
  @Input() change: number;
  isNegative = true;
  constructor() {}

  ngOnInit(): void {
    if (this.oldval || this.newval){
      this.change = Math.round(this.newval - this.oldval);
    }
    if (this.change < 0) {
      this.isNegative = false;
    }
  }
}
