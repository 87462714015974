<div class="cell-date-editable">
  <span class="d-block date-label mr-1" stopEvent [innerHTML]="getDateString()" [title]="getDateString()"></span>

  <input class="date-picker"
  [(ngModel)]="selectedDate"
  #dp="bsDatepicker"
  bsDatepicker
  [bsConfig]="bsConfig"
  placement="bottom right"
  (bsValueChange)="onDateChange($event)">

  <button class="btn btn-icon btn-light btn-date" [title]="'setDate' | translate" (click)="dp.show()">
    <span class="material-icons-outlined">
      edit
    </span>
  </button>

</div>
