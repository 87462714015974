<div class="epic-inline-task-wrapper d-flex-center" [formGroup]="form">
  <div class="d-flex-center t-epic">
    <app-input [control]="form.get(formCtrlName.name)" [focus]="true"></app-input>
    <div dropdown #epicDropdown="bs-dropdown" placement="bottom right" [insideClick]="true" class="position-relative">
      <div dropdownToggle class="epic-color ml-2"
        [ngStyle]="{'background-color': form.controls[formCtrlName.colorCode]?.value}"></div>
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right user-account-dropdown-menu epic-color-dropdown">
        <app-color-picker (colorChange)="selectColor($event)"></app-color-picker>
      </div>
    </div>
  </div>
  <button class="btn btn-flex action" [tooltip]="'Save' | translate" [placement]="'bottom'" appRestrictMultiSubmit (multiClick)="onSubmit()">
    <mat-icon [svgIcon]="'check-done'" class="icon"></mat-icon>
  </button>
</div>
