import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { validWorkLogParternForTest } from '../_services/time-duration.service';

export class DateValidators {
  /**
   * Compare value of two dates without hours
   */
  static dateLessThan(startDate: string, endDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let date1: moment.Moment;
      let date2: moment.Moment;

      if (control.get(startDate).value) {
        date1 = moment(control.get(startDate).value);
      }
      if (control.get(endDate).value) {
        date2 = moment(control.get(endDate).value);
      }

      if (date1 && date2 && date1.isAfter(date2)) {
        return { invalidStartDate: true };
      }
      return null;
    };
  }

  static validateLogTime(durationCtrlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let durationValue = control.value;
      if (durationValue === undefined
        || durationValue === '') {
        return null;
      }

      durationValue = <string>durationValue.replace(/\s/g, '')
      // need to new regx here because of last index issue
      let tempRegx = new RegExp(validWorkLogParternForTest)
      let isValid = tempRegx.test(durationValue)

      if (!isValid) {
        return { invalidLogTime: true };
      }

      return null;
    };
  }
}
