<div class="d-tab-wrapper">
  <ng-container *ngFor="let section of tab?.childs">
    <div class="d-section-wrapper" *ngIf="getFieldsBySectionId(section.id)?.length">
      <div class="section-header" *ngIf="hasMoreThanOneTab()">
        <div class="section-icon">
          <img class="icon-md" src="{{'assets/icons/' + section.icon + '.svg'}}" alt="" *ngIf="section.icon">
        </div>
        <div class="section-title">
          <span>{{section.name}}</span>
        </div>
      </div>
      <div [ngClass]="{'section-content': hasMoreThanOneTab()}">
        <app-d-field
          *ngIf="sectionFields[section.id]?.length > 0"
          [fields]="sectionFields[section.id]"
          (fieldChange)="onFieldChange($event)"
          >
        </app-d-field>
      </div>
    </div>
  </ng-container>
</div>
