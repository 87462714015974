import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

export interface TreeFolder {
  name?: string;
  files: File[];
  directories?: TreeFolder[];
}

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
  @Output() onFileDropped = new EventEmitter<any>();
  @Output() onFolderDropped = new EventEmitter<TreeFolder>();
  @Output() onDragover = new EventEmitter<any>();
  @Output() onDragleave = new EventEmitter<any>();
  uploadPaths: any;
  @HostBinding('style.background-color') public background = '#FFFFFF';
  // @HostBinding('style.opacity') public opacity = '1';
  @HostBinding('class.on-drag-drop') public isDragging = false;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#DDE8F1';
    // this.opacity = '0.7';
    this.isDragging = true;
    this.onDragover.emit();
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFFFFF';
    // this.opacity = '1';
    this.isDragging = false;
    this.onDragleave.emit();
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#FFFFFF';
    // this.opacity = '1';
    this.isDragging = false;
    const files = evt.dataTransfer.files;

    const entries = Array.from(evt.dataTransfer.items)
      .filter(item => {
        return item.kind === 'file';
      })
      .map(item => item.webkitGetAsEntry());

    this.buildTree(entries, '').then(tree => {
      this.uploadPaths = [];
      this.upload(tree, '');
      this.onFolderDropped.emit(tree);
    });

    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }

  filesPicked(files) {
    this.uploadPaths = [];
    Array.prototype.forEach.call(files, file => {
      this.uploadPaths.push(file.webkitRelativePath);
    });
  }

  private parseFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        file => {
          resolve(file);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  private parseDirectoryEntry(directoryEntry) {
    const directoryReader = directoryEntry.createReader();
    return new Promise((resolve, reject) => {
      directoryReader.readEntries(
        entries => {
          resolve(this.buildTree(entries, directoryEntry.name));
        },
        err => {
          reject(err);
        }
      );
    });
  }

  private buildTree(entries, name) {
    const tree = { name, files: [], directories: [] };
    const promises = [];
    entries.forEach(entry => {
      if (entry.isFile) {
        const promise = this.parseFileEntry(entry).then(file => {
          tree.files.push(file);
        });
        promises.push(promise);
      } else if (entry.isDirectory) {
        const promise = this.parseDirectoryEntry(entry).then(directory => {
          tree.directories.push(directory);
        });
        promises.push(promise);
      }
    });
    return Promise.all(promises).then(() => tree);
  }

  private upload(tree, path) {
    tree.files.forEach(file => {
      this.uploadPaths.push(path + file.name);
    });
    tree.directories.forEach(directory => {
      const newPath = path + directory.name + '/';
      this.uploadPaths.push(newPath);
      this.upload(directory, newPath);
    });
  }

}
