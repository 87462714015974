
export enum FileProviderType {
  s3 = 'S3',
  sftp = 'Sftp',
  googleDrive = 'Google_Drive',
}

export class FileProvider {
  id: number;
  type: FileProviderType;
  name: string;
}

export class FileProviderAddRequest {
  code: string;
  type: FileProviderType;
}

export class ProviderFileSearchRequest {
  parentId?: string;
  keyword?: string;
}

export interface ProviderFile {
  id: string;
  name: string;
  fileSize: number;
  modifiedAt: string;
  type: string;
  webContentLink: string;
  webViewLink: string;
}

export interface CopyToXcorpRequest {
  fileProviderId: number;
  sourceFileId: string;
  destinationFileId: number;
}

export interface CopyToProviderRequest {
  fileProviderId: number;
  sourceFileId: string;
  destinationFileId: string;
}

