import { TemplateRef } from '@angular/core';
import { DynamicFieldType, FieldOptionType, FieldType } from '../_utils';
import { ChangeOrderRequest, FieldAdvanceGroup, FieldAdvanceScope, Pageable } from 'src/app/shared';

export class DynamicField {
  id: number;
  name: string;
  description: string;
  hash: string;
  color: string;
  icon: string;
  rowIndex: number;
  type: DynamicFieldType;
  parentId: number;
  isSystem: boolean;
  isVisible: boolean;
  isCollapsed?: boolean;
  isLinkedField?: boolean;
  isDeleted?: boolean;
  isSearched?: boolean;
  template?: TemplateRef<any>;
  isArchived?: boolean;

  parent?: DynamicField;
  childs?: DynamicField[];
  fieldChilds?: Field[];

  // FE
  routerLink?: string;
}

export class DynamicFieldTree extends DynamicField {
  parent?: DynamicField;
  childs?: DynamicField[];
  fieldChilds?: Field[];
}

export class DynamicFieldRequest {
  name: string;
  hash: string;
  icon: string;
  colorCode: string;
  type: string;
  parentId: number;
}

export interface Field {
  id: number;
  name: string;
  description: string;
  hash: string;
  type: FieldType;
  layout: number;
  sectionId: number;
  isVisible: boolean;
  rowIndex: number;
  optionType: FieldOptionType;
  options: FieldOption[];
  columns: FieldColumn[];
  isSystem: boolean;
  parent?: DynamicFieldTree;
  template?: TemplateRef<any>;
  value?: FieldValue;
  required: boolean;
  history: boolean;
  advance: FieldAdvance;
  variable: string;
  advanceFlg: boolean;
  isRequired?: boolean;
  isArchived?: boolean;
  disabled?: boolean;
  isAdvanced?: boolean;
}

export interface FileValue {
  fileId: number;
  name: string;
  url: string;
  type: string;
  createdAt: Date;
  previewUrl: string;
  thumbnailUrl: string;
}

export interface FieldOption {
  id?: number;
  name: string;
  value: string;
}

export interface FieldColumn {
  id?: number;
  name: string;
  type: FieldType;
  options: FieldOption[];
  searchable: boolean;
  rowIndex: number;
}

export interface FieldRequest {
  name: string;
  description: string;
  type: FieldType;
  layout: number;
  sectionId: number;
  options?: FieldOption[];
  columns?: FieldColumn[];
  required?: boolean;
  advance: FieldAdvance;
  variable: string;
}

export interface FieldAdvance {
  group: FieldAdvanceGroup;
  scope: FieldAdvanceScope;
}

export interface FieldValueRequest {
  attachments?: File[];
  keepIds?: number[];
  text?: string;
  number?: number;
  date?: string;
  groupId?: number;
  id?: number;
  optionIds?: number[];
  rows?: RowValueRequest[];
  fieldId?: number;
}

export interface RowValueRequest {
  id?: number;
  columns: FieldValueRequest[];
  deleteFlag?: boolean;
}

export interface FieldValue {
  id?: number;
  groupId?: number;
  fieldId?: number;
  text?: string;
  number?: number;
  date?: string;
  file?: FileValue[];
  options?: FieldOption[];
  rows?: RowValue[];
  attachments?: File[];
  optionIds?: number[];
}

export interface RowValue {
  id: number;
  columns: FieldValue[];
  deleteFlag?: boolean;
  isUpdatingFlag?: boolean;
}

export interface FieldDetail {
  field: Field;
  value: FieldValue;
}

export enum FieldPatchRequestType {
  isVisible = 'isVisible',
  isDeleted = 'isDeleted',
}

export interface FieldPatchRequest {
  type: FieldPatchRequestType;
  value: string;
  sectionId: number;
}

export interface DynamicFieldPatchRequest {
  type: FieldPatchRequestType;
  value: string;
}
export interface ChangeOrderFieldRequest extends ChangeOrderRequest {
  sectionId: number;
  columnFlg?: boolean;
}

export interface LinkFieldRequest {
  sectionIds: number[];
}

export interface VariableResponse {
  variable: string;
}

export interface DynamicFieldParams {
  deleted: boolean;
}

export interface DynamicFieldTableRequest {
  groupId: number;
  fieldId: number;
  keyword: string;
}

export class DynamicFieldSearch {
  keyword?: string;
  deleted?: boolean;
  constructor(data?: DynamicFieldSearch) {
    this.keyword = data?.keyword || '';
    this.deleted = data?.deleted || false;
  }
}
