import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import { Role } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RoleDataService extends DefaultDataService<Role> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, config: DefaultDataServiceConfig) {
    super('roles', http, httpUrlGenerator, config);
  }

  getWithQuery(queryParams: QueryParams | string): Observable<Role[]> {
    // return super.getWithQuery(queryParams).pipe(map(res => {
    //   const data = res['content'];
    //   return data as any;
    // }));

    return this.http.get<Role[]>(`${environment.apiUrl}/roles/search?${queryParams}`).pipe();
  }
}
