import { ProjectPlatform } from "./project.model";


export interface BoardReleasePlanningSummary {
  platforms: ProjectPlatform[];
  items: PlanningItemDetail[];
  efforts: ReleasePlanningResponse[];
  total: PlanningItem;
  development: PlanningItem;
}

export interface PlanningItem {
  storyPoint: number;
  startDate: string;
  endDate: string;
}

export interface ReleasePlanningResponse {
  storyPoint: number;
  startDate: string;
  endDate: string;
  id: number;
  name: string;
}

export interface PlanningItemDetail {
  storyPoint: number;
  startDate: string;
  endDate: string;
  id: number;
  fullName: string;
  avatar: string;
  platformId?: number;
  colorCode?: string;
  childs?: PlanningItemDetail[];
  isCollapsed?: boolean;
}

export interface EffortUpsertRequest {
  storyPoint?: number;
  startDate?: string;
  endDate?: string;
  name?: string;
}
