import { SelectOption } from '../../_models';

export enum FieldType {
  number = 'NUMBER',
  text = 'TEXT',
  richText = 'RICH_TEXT',
  function = 'FUNCTION',
  select = 'SELECT',
  multiSelect = 'MULTI_SELECT',
  table = 'TABLE',
  file = 'FILE',
  datePicker = 'DATE_PICKER',
  system = 'SYSTEM',
}

export enum DynamicFieldType {
  Menulevel1 = 'MENULEVEL1',
  Menulevel2 = 'MENULEVEL2',
  Menulevel3 = 'MENULEVEL3',
  Tabs = 'TABS',
  Sections = 'SECTIONS',
}

export enum DynamicActionType {
  Create = 'Create',
  Drag = 'Drag',
  Visible = 'Visible',
  Collapsed = 'Collapsed',
  Expanded = 'Expanded',
}

export enum FieldOptionType {
  default = 'DEFAULT',
  user = 'USER',
  leave = 'TYPE_OF_LEAVE',
}

export enum FieldOptionTypeLabel {
  default = 'Default',
  user = 'User',
  leave = 'Leave Type',
}

export enum ActionOfLabel {
  CreateField = 'Create Field',
  CreateSection = 'Create Section',
  CreateTab = 'Create Tab',
  CreateMenu = 'Create Menu',
  EditField = 'Edit Field',
  EditSection = 'Edit Section',
  EditTab = 'Edit Tab',
  EditMenu = 'Edit Menu'
}

export class FieldOptionTypeOptions {
  static get options(): SelectOption[] {
    return Object.keys(FieldOptionType).filter(key => key !== 'default').map((key) => {
      return {
        name: FieldOptionTypeLabel[key],
        value: FieldOptionType[key]
      };
  });
  }
}

export enum FieldAdvanceGroup {
  User = 'USER',
  Hr = 'HR',
  Project = 'PROJECT',
  Task = 'TASK',
}

export enum FieldAdvanceGroupLabel {
  User = 'User',
  Hr = 'HR',
  Project = 'Project',
  Task = 'Task',
}

export enum FieldAdvanceScope {
  Company = 'COMPANY',
  User = 'USER',
}

export enum FieldAdvanceScopeLabel {
  Company = 'Company',
  User = 'User',
}

export class FieldAdvanceOption {
  static scopeOptions(): SelectOption[] {
    return Object.keys(FieldAdvanceScope).map((key) => {
      return {
        name: FieldAdvanceScopeLabel[key],
        value: FieldAdvanceScope[key]
      };
    });
  }

  static groupOptions(): SelectOption[] {
    return Object.keys(FieldAdvanceGroup).map((key) => {
      return {
        name: FieldAdvanceGroupLabel[key],
        value: FieldAdvanceGroup[key]
      };
    });
  }
}

export enum ActionLinkField {
  Preview = 'PREVIEW',
  Link = 'LINK',
  Delete = 'DELETE',
  Search = 'SEARCH',
  Archived = 'ARCHIVED',
  Default = 'DEFAULT',
}

export enum FieldProperties {
  LinkedField = 'isLinkedField',
  Deleted = 'isDeleted',
  Searched = 'isSearched',
  Archived = 'isArchived',
}
