import { AfterViewInit, Directive, ElementRef, HostBinding, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

const PASSWORD_SHOW = 'assets\\img\\visibility-on.svg';
const PASSWORD_HIDE = 'assets\\img\\visibility-off.svg';
const PASSWORD_HIDE_CLASS = 'password-visibility icon-md hide';
const PASSWORD_SHOW_CLASS = 'password-visibility icon-md show';

@Directive({ selector: '[passwordVisibility]' })
export class PasswordVisibilityDirective implements AfterViewInit, OnInit, OnDestroy {
    @HostBinding() type: 'password' | 'string';

    imgEl: HTMLImageElement;
    sub: Subscription;

    constructor(private renderer: Renderer2, private el: ElementRef) {}

    ngOnInit(): void {
        this.type = 'password';
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.imgEl = this.renderer.createElement('img');
        this.imgEl.src = PASSWORD_HIDE;
        this.imgEl.style.right = '12px';
        this.imgEl.style.position = 'absolute';
        this.imgEl.style.top = '34px';
        this.imgEl.style.cursor = 'pointer';
        this.imgEl.className = PASSWORD_HIDE_CLASS

        this.renderer.insertBefore(this.el.nativeElement.parentNode, this.imgEl, this.el.nativeElement);

        this.sub = fromEvent(this.imgEl, 'click').subscribe(() => {
            if (this.type === 'password') {
                this.type = 'string';
                this.imgEl.className = PASSWORD_SHOW_CLASS;
                this.imgEl.src = PASSWORD_SHOW;
                this.imgEl.style.top = '35px';
            } else {
                this.type = 'password';
                this.imgEl.className = PASSWORD_HIDE_CLASS;
                this.imgEl.src = PASSWORD_HIDE;
                this.imgEl.style.top = '34px';
            }
        });
    }
}
