import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Shift } from 'src/app/shared/_models/shift.model';

@Injectable({
  providedIn: 'root'
})

export class ShiftDataService extends DefaultDataService<Shift> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, config: DefaultDataServiceConfig) {
    super('shifts', http, httpUrlGenerator, config);
  }

  // getWithQuery(queryParams: QueryParams | string): Observable<Shift[]> {
  //   return super.getWithQuery(queryParams).pipe(map(res => {
  //     const data = res['content'];
  //     return data as any;
  //   }));
  // }
}
