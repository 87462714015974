export interface BankAccount {
  id: number;
  accountNumber: string;
  isDefault: boolean;
  alias: string;
  bank: Bank;
}

export interface Bank {
  id: number;
  name: string;
  fullName: string;
  code: string;
}
