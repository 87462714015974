import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from 'src/app/core';
import { FileExtension } from '../../_utils';
@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss']
})
export class ImportFileComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<File[]>();
  @Input() title = 'Import Data';
  @Input() saveBtnName = 'Import';
  @Input() cancelBtnName = 'Cancel';
  @Input() validTypes = [FileExtension.csv];
  @Input() fileLink: string;
  @Input() multiple = false;
  files: File[] = [];

  validFile = '';

  constructor(
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.validFile = this.validTypes?.map(e=> '.' + e).join(', ') || '';
  }

  Cancel() {
    this.formSubmit.next(null);
  }

  onSubmmit() {
    this.formSubmit.next(this.files);
  }

  onFileChanged(files: FileList) {
    this.checkFile(files);
  }

  filesDropped(files: FileList): void {
    this.checkFile(files);
  }

  checkFile(files: FileList) {
    if (!files) {
      return ;
    }
    let error = false;

    if (!this.multiple) {
      this.files = [];
    }

    Array.from(files).forEach(file => {
      const extension =  file.name?.split('.')?.pop();
      if (this.validType(extension)) {
        this.files.push(file);
      }
      else {
        error = true;
      }
    });

    if (error) {
      this.toast.error('Incorrect file format');
    }
  }

  validType(type: string) {
    const extend = this.getExtension(type);
    if (this.checkType(extend)) {
      return true;
    }
    return false;
  }

  checkType(typ: FileExtension) {
    return this.validTypes.includes(typ);
  }

  getExtension(type: string): FileExtension {
    let t = type.split('/');
    return t[t.length - 1] as FileExtension;
  }

  deleteItem(index: number) {
    this.files.splice(index, 1);
  }
 
}
