import { Component, Input, SimpleChanges } from '@angular/core';
import { AbstractComponent, DynamicField } from 'src/app/shared';
import { DynamicBreadcrumbDataService } from '../../dynamic-field/_services/dynamic-breadcrumb-data.service';
import { BreadcrumbItem } from 'src/app/shared/_models/dynamic-field-breadcrumb.model';

@Component({
  selector: 'app-dynamic-page-breadcrumb',
  templateUrl: './dynamic-page-breadcrumb.component.html',
  styleUrls: ['./dynamic-page-breadcrumb.component.scss'],
})

export class DPageBreadcrumbComponent extends AbstractComponent {
  @Input() currentMenu: DynamicField;

  breadcrumbItem: BreadcrumbItem;

  constructor(
    private dynamicBreadcrumbDataService: DynamicBreadcrumbDataService,
  ) {
    super();
  }

  changes(changes: SimpleChanges) {
    if (changes?.currentMenu) {
      this.breadcrumbItem = this.dynamicBreadcrumbDataService.breadcrumbItem;
    }
  }

  hasMoreThanOneMenuLevel2() {
    const parentMenu =  this.currentMenu?.parent;
    return parentMenu?.childs?.length > 1;
  }

  hasMoreThanOneTab() {
    return this.currentMenu?.childs?.length > 1;
  }
}
