export enum NumberFormat {
  DEFAULT = '#,##0.00', // 1.234,56
  TWODECIMAL = '0.00', // 1234,56
  INTEGER = '0', // 	1235
  ACCOUNTING = '#,##0_);(#,##0)', // 	1.235 | 	(1.235)
  ACCOUNTING_RED = '#,##0.00_);[Red](#,##0.00)', // 1.234,56   | 	(1.234,56)
  PERCENTAGE_TWODECIMAL = '0.00%', //123456,00%
  PERCENTAGE = '0%', // 123456%
  CURRENCY_RED = '$#,##0.00_);[Red]($#,##0.00)' // 	$1.234,56     |     ($1.234,56)
  // '#,##0_);(#',   // 1.235   |   (1235
  // '#,##0',  //1.235
  // '#,##0_);[Red](#,##0)',   // $1.235     | 	($1.235)
  // '$#,##0.00_);($#,##0.00)',   // $1.234,56  |   ($1.234,56)
  // '@',  //1234,56
  // '0.00E+00',   // 1,23E+03
  // '##0.0E+0', //	1,2E+3
  // '# ?/?',  // # 1234 5/9
  // '# ??/??',   // 1234 14/25
  // '_($* #,##0_);_($* (#,##0);_($* "-"_);_(@_)', // 	$ 1.235   | $ (1.235)
  // '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)',    // 	1.235  | (1.235)
  // '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)'  // $ 1.234,56   | $ (1.234,56)
}

export interface NumberFormatModel {
  pattern: NumberFormat;
  option?: NumberFormatModelOption;
}

export interface NumberFormatModelOption {
  negativeColor: string;
}

export const NumberFormatConfig: NumberFormatModel[] = [
  {
    pattern: NumberFormat.DEFAULT
  },
  {
    pattern: NumberFormat.TWODECIMAL
  },
  {
    pattern: NumberFormat.INTEGER
  },
  {
    pattern: NumberFormat.ACCOUNTING
  },
  {
    pattern: NumberFormat.ACCOUNTING_RED,
    option: {
      negativeColor: 'text-danger'
    }
  },
  {
    pattern: NumberFormat.PERCENTAGE_TWODECIMAL
  },
  {
    pattern: NumberFormat.PERCENTAGE
  },
  {
    pattern: NumberFormat.CURRENCY_RED,
    option: {
      negativeColor: 'text-danger'
    }
  }
];
