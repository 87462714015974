import { Directive, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { PageableModel, PageInfo } from '../../_models';
import { routerObject, TABLE_CONFIG } from '../../_utils';
import { AbstractComponent } from './abstract.component';


export interface SearchPage {
  pageInfo: PageInfo;
  searchSub: Subscription;
  onSearch: (event: any) => void;
  onSort: (event: any) => void;
  onPage: (event: any) => void;
  onPageSizeChange: (page: number) => void;
}

@Directive()
export class SearchPageComponent<T, U = any> extends AbstractComponent {
  @ViewChild('ngxDatatable', {static: false}) ngxDatatable: DatatableComponent;

  pageInfo: PageInfo;
  pageable: PageableModel = new PageableModel();
  searchRequest?: U;
  searchSub: Subscription;
  columns: TableColumn[] = [];
  ColumnMode = ColumnMode;
  TableConfig = TABLE_CONFIG;
  routerObject = routerObject;
  rows: T[];

  constructor() {
    super();
    this.onSidebarResize();
  }

  onSearch() {}
  onSort({ column: { prop }, newValue }) {}
  onPage(page: number) {}
  onPageSizeChange(pageSize: number) {}
  onEdit(row: T) {}
  onDelete(row: T) {}

  onSidebarResize() {
    window.addEventListener('onSidebarResize', (e) => this.calculateTableSize());
  }

  calculateTableSize() {
    if (this.ngxDatatable) {
      this.ngxDatatable.recalculate();
      this.ngxDatatable.recalculateColumns();
    }
  }
}
