import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProjectsService } from 'src/app/site-management/projects/_services';
import { TaskService } from 'src/app/site-management/tasks/_services/task.service';
import { Project, ProjectMemberUser, Task, TaskPatchType } from '../../_models';

@Component({
  selector: 'app-task-assignee',
  templateUrl: './task-assignee.component.html',
  styleUrls: ['./task-assignee.component.scss']
})
export class TaskAssigneeComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() currentProject: Project;
  @Output() selectedAssignee: EventEmitter<Task> = new EventEmitter<Task>();

  isLoadingAssignee = false;
  assigneeSub: Subscription;
  patchSub: Subscription;
  assignees: ProjectMemberUser[] = [];
  assigneeTypeahead$ = new Subject<string>();

  constructor(
    public projectsService: ProjectsService,
    public taskService: TaskService,
    protected store: Store,
  ) {}

  ngOnDestroy(): void {
    this.assigneeSub?.unsubscribe();
    this.patchSub?.unsubscribe();
    this.assigneeTypeahead$.complete();
  }

  ngOnInit(): void {

    this.assigneeTypeahead$
    .pipe(distinctUntilChanged(), debounceTime(500))
    .subscribe(
      (value) => {
        this.searchAssignees(value || '');
      });
  }

  searchAssignees(keyword: string = '') {
    this.isLoadingAssignee = true;
    this.assigneeSub?.unsubscribe();
    this.assigneeSub = this.projectsService
      .getProjectMembers(this.currentProject.id, keyword)
      .subscribe(
        (res) => {
          this.assignees = res;
        },
        (error) => {
          this.assignees = [];
        },
        () => {
          this.isLoadingAssignee = false;
        }
      );
  }

  selectAssignee(user) {
    if (this.task?.assignee?.id !== user?.id || !user) {
      this.patchSub?.unsubscribe();
      this.patchSub = this.taskService
        .patch(this.task?.id, {
          type: TaskPatchType.assigneeId,
          value: user?.id
        })
        .subscribe((task) => {
          this.selectedAssignee.emit(task);
        });
    }
  }
}
