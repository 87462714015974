import { Pipe, PipeTransform } from '@angular/core';
import { SelectColor } from './select-color.component';

@Pipe({
  name: 'selectColor'
})

export class SelectColorPipe implements PipeTransform {
  transform(selectColor: SelectColor, item: SelectColor): any {
    return selectColor === item;
  }
}