import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, withLatestFrom } from 'rxjs';
import { distinctStateChanged } from 'src/app/libs/rxjs/custom-operator.rxjs';
import { ProjectBoardEpicService, ProjectBoardLabelService, ProjectBoardReleaseService, ProjectBoardSprintService, ProjectBoardStatusService, ProjectPriorityService, ProjectsMasterDataService, ProjectsService } from '../../projects/_services';
import { ProjectBoardStorylineService } from '../../projects/_services/project-board-storyline.service';
import { ProjectActions } from './project.actions';
import { ProjectSelectors } from './project.selectors';

@Injectable()
export class ProjectEffects {
  loadAllProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getAllProjects),
      withLatestFrom(this.store.select(ProjectSelectors.selectProjects)),
      distinctStateChanged(),
      exhaustMap(([props]) => {
        return this.projectService.getAllProjects().pipe(
          map((projects) => ProjectActions.setProjects({ projects })),
          catchError(() => of(ProjectActions.setProjects({ projects: [] })))
        );
      })
    );
  });

  loadAllProjectMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getProjectAllMembers),
      withLatestFrom(this.store.select(ProjectSelectors.selectProjectMembers)),
      distinctStateChanged(),
      exhaustMap(([props]) => {
        return this.projectService.getProjectMembers(props.projectId, props.keyword).pipe(
          map((members) => ProjectActions.setProjectMembers({ members })),
          catchError(() =>
            of(ProjectActions.setProjectMembers({ members: [] }))
          )
        );
      })
    );
  });

  loadAllStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getStatuses),
      withLatestFrom(this.store.select(ProjectSelectors.selectStatuses)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectBoardStatusService.get(props.projectId, props.params)
        .pipe(
          map((res) => ProjectActions.setStatuses({ statuses: res.content})),
          catchError(() =>  of(ProjectActions.setStatuses({statuses: []})))
        );
      })
    );
  });

  loadAllPriorities = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getPriorities),
      withLatestFrom(this.store.select(ProjectSelectors.selectPriorities)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectPriorityService.get(props.projectId, props.params)
        .pipe(
          map((res) => ProjectActions.setPriorities({priorities: res?.content})),
          catchError(() =>  of(ProjectActions.setPriorities({priorities: []})))
        );
      })
    );
  });

  loadAllReleases = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getReleases),
      withLatestFrom(this.store.select(ProjectSelectors.selectReleases)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectBoardReleaseService.get(props.payload, props.pageable)
        .pipe(
          map((res) => ProjectActions.setReleases({ releases: res.content})),
          catchError(() =>  of(ProjectActions.setReleases({releases: []})))
        );
      })
    );
  });

  loadAllEpics = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getEpics),
      withLatestFrom(this.store.select(ProjectSelectors.selectEpics)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectBoardEpicService.getListMaster(props.payload, props.pageable)
        .pipe(
          map((res) => ProjectActions.setEpics({epics: res})),
          catchError(() => of(ProjectActions.setEpics({epics: []})))
        );
      })
    );
  });

  loadAllSprints = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getSprints),
      withLatestFrom(this.store.select(ProjectSelectors.selectSprints)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectBoardSprintService.get(props.payload, props.pageable)
        .pipe(
          map((res) => ProjectActions.setSprints({sprints: res.content})),
          catchError(() => of(ProjectActions.setSprints({sprints: []})))
        );
      })
    );
  });

  loadAllLabels = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getLabels),
      withLatestFrom(this.store.select(ProjectSelectors.selectLabels)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectBoardLabelService.get(props.payload, props.pageable)
        .pipe(
          map((res) => ProjectActions.setLabels({labels: res.content})),
          catchError(() => of(ProjectActions.setLabels({labels: []})))
        );
      })
    );
  });

  loadAllStoryLines = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getStorylines),
      withLatestFrom(this.store.select(ProjectSelectors.selectStoryLines)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectBoardStorylineService.searchByAvailablePlatforms(props.projectId, props.payload, props.pageable)
        .pipe(
          map((res) => ProjectActions.setStorylines({storyLines: res.content})),
          catchError(() => of(ProjectActions.setStorylines({storyLines: []})))
        );
      })
    );
  });

  loadAllPlatForms = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.getPlatforms),
      withLatestFrom(this.store.select(ProjectSelectors.selectPlatforms)),
      distinctStateChanged(),
      switchMap(([props]) => {
        return this.projectService.getPlatforms(props.projectId, {}, { page: 0, size: 100000 })
        .pipe(
          map((res) => ProjectActions.setPlatforms({platforms: res.content})),
          catchError(() => of(ProjectActions.setPlatforms({platforms: []})))
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private projectService: ProjectsService,
    private projectBoardStatusService: ProjectBoardStatusService,
    private projectPriorityService: ProjectPriorityService,
    private projectBoardReleaseService: ProjectBoardReleaseService,
    private projectBoardEpicService: ProjectBoardEpicService,
    private projectBoardSprintService: ProjectBoardSprintService,
    private projectBoardLabelService: ProjectBoardLabelService,
    private projectBoardStorylineService: ProjectBoardStorylineService,
    private projectsMasterDataService: ProjectsMasterDataService,
    private store: Store
  ) {}
}
