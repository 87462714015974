import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FileService } from 'src/app/site-management/document/_services/file.service';

@Component({
  selector: 'app-aws-signature-video',
  templateUrl: './aws-signature-video.component.html',
  styleUrls: ['./aws-signature-video.component.scss']
})
export class AwsSignatureVideoComponent implements OnInit {
  @Input() url: string;
  @Input() previewUrl: string;
  @Input() template: TemplateRef<any>;
  @Output() previewUrlChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
    if (!this.previewUrl) {
      this.getPreviewUrl();
    }
  }

  getPreviewUrl() {
    this.fileService.getAuthorization(this.url).subscribe(
      (res) => {
        const data = res[0];
        this.previewUrl = data.fullUrl;
        this.previewUrlChange.emit(this.previewUrl);
      },
    );
  }
}
