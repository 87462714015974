import { ProjectEpicModel } from '../../../shared/_models/epic.model';
import { WorkItemType, Release, WorkItemLabel } from '../../../shared/_models/task.model';
import { Project } from '../../../shared/_models/project.model';
import { TaskStatus, WorkItemPriority } from 'src/app/shared';


export class ProjectDataModel {
    projectId: number;
    project: Project;
    workItemTypes: WorkItemType[] = [];
    workItemPriorities: WorkItemPriority[] = [];
    workItemStatuses: TaskStatus[];
    epics: ProjectEpicModel[] = [];
    releases: Release[] = [];
    workItemLabels: WorkItemLabel[] = [];

    constructor(projectId: number){
        this.projectId = projectId;
    }

}
