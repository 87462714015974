<ng-select
  [formControl]="control"
  [typeahead]="typing$"
  [items]="items"
  [bindLabel]="bindLabel || 'name'"
  [bindValue]="bindValue"
  [multiple]="true"
  [clearable]="false"
  [searchable]="true"
  [placeholder]="placeholder"
>
</ng-select>
