import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { UtilService } from 'src/app/core';
import { TooltipOverlayConfig } from 'src/app/site-management/_services/token';
import { PositionOverlay } from '../..';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() hideTooltip = false;
  @Input() url = '';
  @Input() name = '';
  @Input() size: 'very-small' | 'small' | 'medium' | 'large' | 'very-large' = 'medium';
  @Input() unassignedTitle = 'Unassigned';
  @ViewChild('avatar', { read: ViewContainerRef, static: true }) appAvatar: ViewContainerRef; // container component

  tooltipOverlayConfig: TooltipOverlayConfig = {
    position: [
      PositionOverlay.bottomLeft,
      PositionOverlay.topLeft,
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

  getName() {
    return UtilService.getAbbreviationName(this.name);
  }

}
