<ng-container *ngIf="!loading">
  <div class="ql-editor-html-view">
    <div class="ql-container ql-snow">
      <div #html
        class="ql-editor"
        [innerHTML]="(innerHTML ? innerHTML : placeholderHtml) | safeHtml"
        (click)="onClick($event, html)"
        quillMentionEvent>
      </div>
    </div>
  </div>
</ng-container>
<!-- Skeleton -->
<div @fade class="bg-white overflow-hidden" *ngIf="loading">
  <img [src]="skeletonUrl" alt="">
</div>
