import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { List2Res, Pageable, Task } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class TaskTemplateService {
  updateTaskTemplate$ = new Subject<Task>();

  constructor(
    private http: HttpClient,
  ) {}

  get(projectIds: number[], pageable: Pageable): Observable<List2Res<Task>> {
    const params = queryString.stringify({...pageable});
    return this.http.get<List2Res<Task>>(`${environment.apiUrl}/tasks/template?projectIds=${projectIds}&${params}`);
  }
}
