import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AbstractComponent, BoardEpic, Pageable, Task } from 'src/app/shared';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { ProjectBoardEpicService } from '../../_services';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { findIndex } from 'lodash';

@Component({
  selector: 'app-epic-list-dropdown',
  templateUrl: './epic-list-dropdown.component.html',
  styleUrls: ['./epic-list-dropdown.component.scss']
})
export class EpicListDropdownComponent extends AbstractComponent {
  @Input() task: Task;
  @Input() canViewDetailEpic = true;
  @Input() projectId: number;
  @Output() changeEpic =  new EventEmitter<BoardEpic>();

  epicTypeahead$ = new Subject<string>();
  epics: BoardEpic[] = [];
  selectedEpic: BoardEpic;
  isCreateEpic = false;
  pageable = new Pageable();
  epicSubscription: Subscription;

  constructor(
    private projectBoardEpicService: ProjectBoardEpicService,
    private router: Router,
    protected fb: UntypedFormBuilder
  ) {
    super();
  }

  init() {
    super.init();
    this.getProjectId();
    this.searchEpics();
    this.registerEvents();
  }

  registerEvents() {
    // this.epicTypeahead$
    // .pipe(debounceTime(500), takeUntil(this.destroyed$))
    // .subscribe((keyword) => {
    //   this.searchEpics(keyword);
    // });
  }

  handleKeywordChange(keyword: string) {
    //this.epicTypeahead$.next(keyword);
    this.searchEpics(keyword);
  }

  changes(changes: SimpleChanges) {
    if (changes?.task?.currentValue) {
      this.selectedEpic = changes.task.currentValue?.epic;
    }
  }

  searchEpics(keyword: string = '') {
    const payload = {
      projectId: this.projectId,
      keyword
    };

    this.epicSubscription?.unsubscribe();
    this.epicSubscription = this.projectBoardEpicService
      .getListMaster(payload, this.pageable)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: BoardEpic[]) => {
        this.epics = res || [];
        this.sortEpics();
      }, (error) => {
        this.epics = [];
      });
  }

  getProjectId() {
     this.projectId = this.projectId || this.task?.projectId || this.task?.project?.id;
  }

  sortEpics() {
    const indexSelectedItem = findIndex(this.epics, { id: this.selectedEpic?.id });
    if (indexSelectedItem !== -1) {
      this.epics.splice(indexSelectedItem, 1);
      this.epics.unshift(this.selectedEpic);
    }
  }

  selectEpic(epic?: BoardEpic) {
    this.selectedEpic = epic;
    this.changeEpic.emit(epic);
  }

  onFocus() {
    this.isCreateEpic = true;
  }

  addEpic(epic: BoardEpic) {
    this.isCreateEpic = false;
    this.epics.unshift(epic);
  }

  goToDetailEpic(epic: BoardEpic) {
    if (!epic) {
      return;
    }
    this.router.navigateByUrl(`${this.routerObject.taskMgmtTaskList.fullPath}/${epic.key}`);
  }
}
