import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Task } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class EpicDataService {

  private taskChange = new BehaviorSubject<{
    previousValue: Task,
    currentValue: Task,
  }>(null);
  taskChange$ = this.taskChange.asObservable().pipe(filter(e => e !== null));

  private epicDelete = new BehaviorSubject<number>(null);
  epicDelete$ = this.epicDelete.asObservable().pipe(filter(e => e !== null));

  constructor() { }

  emitTaskChange(changes: { previousValue: Task, currentValue: Task }) {
    this.taskChange.next(changes);
  }

  emitEpicDelete(epicId: number) {
    this.epicDelete.next(epicId);
  }
}
