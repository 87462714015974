<div class="upload-file-wrapper" *ngIf="hasUploadData">
  <div class="header-info">
    <span>Upload File</span>

    <button class="btn btn-icon ml-auto">
      <span
        class="material-icons"
        (click)="isCollapsed = !isCollapsed"
        [tooltip]="isCollapsed ? 'Maximize' : 'Minimize'"
      >
        {{ isCollapsed ? 'expand_more' : 'expand_less' }}
      </span>
    </button>

    <button
      class="btn btn-icon ml-2"
      [tooltip]="'Close'"
      (click)="onCancelAll()"
    >
      <span class="material-icons"> close </span>
    </button>
  </div>

  <div class="upload-file-list" [ngClass]="{ 'd-none': isCollapsed }">
    <ng-container *ngFor="let info of filesInfo">
      <div class="upload-file">
        <img
          class="file-icon"
          [src]="'assets/icons/documents/doc.svg'"
          alt=""
        />
        <div class="file-name text-ellipsis">
          <span>{{ info.fileName }}</span>
        </div>
        <div
          class="file-status d-flex justify-content-center"
          [ngClass]="getStatusCss(info)"
        >
          <ng-container *ngIf="info.status === status.SUCCESS">
            <img
              class="status-icon icon-md"
              [src]="'assets/icons/documents/check_green.svg'"
              alt=""
              [tooltip]="'completed'"
              [placement]="'right'"
            />
          </ng-container>

          <ng-container *ngIf="info.status === status.CANCEL">
            <mat-icon class="fa icon-md" svgIcon="cancelled"></mat-icon>
          </ng-container>

          <ng-container *ngIf="info.status === status.ERROR">
            <img
              class="status-icon icon-md"
              [src]="'assets/icons/documents/error.svg'"
              alt=""
            />
          </ng-container>

          <ng-container *ngIf="info.status === status.INPROGRESS">
            <!-- <img
              class="status-icon icon-md"
              [src]="'assets/icons/documents/pending_upload.svg'"
              alt=""
            /> -->
            <span class="status-icon icon-md">
              <circle-progress [percent]="info.percent" [radius]="15" [outerStrokeWidth]="3"
              [innerStrokeWidth]="0" [outerStrokeColor]="'#f1a404'" [innerStrokeColor]="'#FCEDCE'" [subtitleFontSize]="10"
              [subtitle]="info.percent + '%'" [animateTitle]="false" [animation]="false" [showTitle]="false"
              [showUnits]="false" [showBackground]="false" [startFromZero]="false" [showZeroOuterStroke]="false"></circle-progress>
            </span>
            <button class="btn btn-icon btn-cancel" (click)="onCancel(info)">
              <img
                class="icon-md"
                [src]="'assets/icons/documents/close.svg'"
                alt=""
              />
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
