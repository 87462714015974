<div class="tags">
  <div
    class="tag"
    [ngClass]="{ active: this.activeItem?.value === item.value }"
    *ngFor="let item of options"
    (click)="onClick(item)"
  >
    {{ item.label | translate }}
  </div>
</div>
