export interface ActiveSprint {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  status?: 'New' | 'Active' | 'Completed';
  deleted: boolean;
  duration: number;
  goal: string;
}

export interface Sprint extends ActiveSprint {
  countStatus: SprintTaskAnalysis[];
  projectId: number;
  isNextSprint?: boolean;
}

export interface SprintTaskAnalysis {
  id: number;
  name: string; // Task Status
  colorCode: string;
  defaultFlg: boolean;
  icon: string;
  deleted: boolean;
  count: number;
  storyPoint: number;
}

export interface BacklogSprintDataModel {
  projectId: number;
  sprints: Sprint[];
  completedSprints: Sprint[];
}

export interface BacklogDataModel {

}
