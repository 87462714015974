<div class="cell-text-wrapper">
  <ng-container *ngIf="isAvatar(); else noAvatar">
    <div class="d-flex align-items-center" *ngFor="let data of cellDropdown">
      <app-avatar
        [url]="data?.avatar"
        [name]="data.name"
      ></app-avatar>
      <span class="option-label px-2">{{data?.value || data?.name}}</span>
    </div>
  </ng-container>
  <ng-template #noAvatar>
    <span class="option-label d-block text-ellipsis" *ngIf="getTextDropdown() as value" [title]="value">{{value}}</span>
  </ng-template>
</div>
