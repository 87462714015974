<div class="time-picker-wrapper">
  <div class="header"><app-i18n [key]="title"></app-i18n></div>
  <div class="content">
    <div class="d-time flex-1">
      <ng-container *ngTemplateOutlet="timePicker; context: {items: hours}"></ng-container>
    </div>
    <div class="d-time flex-1">
      <ng-container *ngTemplateOutlet="timePicker; context: {items: minutes}"></ng-container>
    </div>
    <div class="d-time flex-1">
      <ng-container *ngTemplateOutlet="timePicker; context: {items: periodDate}"></ng-container>
    </div>
  </div>
</div>

<ng-template #timePicker let-items="items">
  <ng-container *ngFor="let item of items">
    <div #timeElement class="item mb-1" [ngClass]="{'selected': getSelectedTime(item)}" (click)="getTime(timeElement, item)">{{item?.value}}</div>
  </ng-container>
</ng-template>
