import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-task-progress',
  templateUrl: './task-progress.component.html',
  styleUrls: ['./task-progress.component.scss']
})
export class TaskProgressComponent implements OnInit, OnDestroy {
  @Input() reverse = false;
  @Input() control: FormControl;
  @Output() onSubmit = new EventEmitter<any>();
  destroyed$ = new Subject<void>();

  constructor() { }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.control?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.control.setValue(value > 100 ? 100 : value, { emitEvent: false });
    });
  }

  getProgressStyle() {
    const percent = this.control.value || 0;
    const round = percent <= 33 ? '0.875rem' : '0rem';
    return {'width': `calc(${percent}% + ${round})`};
  }

  onProgressChange() {
    this.onSubmit.emit();
  }
}
