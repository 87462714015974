export enum SortDirection {
  ascending = 'asc',
  descending = 'desc'
}

export enum CreatedAtSortType {
  asc = 'createdAt,asc',
  desc = 'createdAt,desc'
}

export enum PageSize {
  loadAll = 10000
}
