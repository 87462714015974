import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';

export interface GroupByItem<T = any, O = any> {
  name: T;
  value: O;
  removable: boolean;
}

@Component({
  selector: 'app-group-by',
  templateUrl: './group-by.component.html',
  styleUrls: ['./group-by.component.scss']
})
export class GroupByComponent implements OnInit {
  @Input() views = 2;
  @Input() selectedItems: GroupByItem[] = [];
  @Input() items: GroupByItem[] = [];

  @Output() selectedItemsChange = new EventEmitter<GroupByItem[]>();
  @Output() itemsChange = new EventEmitter<GroupByItem[]>();

  isShowDropDown = false;
  isClickInside = false;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('click')
  clicked() {
    this.isClickInside = true;
  }

  @HostListener('document:click')
  clickedOut() {
    if (!this.isClickInside) {
      this.isShowDropDown = false;
    }
    this.isClickInside = false;
  }

  toggleDropdown() {
    this.isShowDropDown = !this.isShowDropDown;
  }

  removeItem(item: GroupByItem) {
    if (!item.removable) {
      return;
    }

    this.items = [item, ...this.items];

    this.selectedItems = this.selectedItems.filter(
      (i) => i.value !== item.value
    );

    this.itemsChange.next(this.items);
    this.selectedItemsChange.next(this.selectedItems);
  }

  addItem(item: GroupByItem) {
    this.selectedItems = [...this.selectedItems, item];
    this.items = this.items.filter((i) => i.value !== item.value);

    this.itemsChange.next(this.items);
    this.selectedItemsChange.next(this.selectedItems);
  }
}
