import { Status } from '../_utils';

export interface JobTitle {
  id?: number;
  title: string;
  description?: string;
  createdUser?: string;
  createdAt?: string;
  totalCandidate?: number;
  status?: Status;
}

export interface JobTitleRequest {
  title?: string;
  description?: string;
  status?: string;
  newIdMoved?: number;
}

export interface JobTitleSearchParam {
  title?: string;
  status?: Status;
}

export interface CandidatesRequest {
  userIds: number[];
}

export interface CandidateName {
  id: number;
  fullName: string;
  avatar: string;
}
export interface JobTitleShort {
  id: number;
  title: string;
}
