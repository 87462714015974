import { Directive, Input } from '@angular/core';
import { UtilService } from 'src/app/core';
import { AbstractComponent } from 'src/app/shared/_components/_base-component';
import { Field, FieldValue, RowValue } from 'src/app/shared/_models';

@Directive()
export class DFieldCellBaseTableComponent extends AbstractComponent {
  @Input() row: RowValue;
  @Input() column: Field;

  constructor() {
    super();
  }

  init() {
    super.init();
  }

  updateDataCell(row: RowValue, column: Field) {
    this.row = row;
    this.column = column;
    this.getDataCell();
  }

  getDataCell() {
    if (!this.row || !this.column || !this.row?.columns?.length) {
      return;
    }

    const cell: FieldValue = this.row.columns.find(c => c.fieldId === this.column.id);
    if (!cell) {
      return '';
    }

    return UtilService.getCurrentFieldValue(this.column.type, cell);
  }
}
