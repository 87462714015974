import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  version = environment.version;

  constructor(private message: NzMessageService) {}

  ngOnInit(): void {
    this.checkVersion();
  }

  checkVersion() {
    if (environment.env === 'dev') {
      this.message.info(`XCORP v${this.version}`);
    }
  }
}
