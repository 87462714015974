import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PermissionCache, PermissionCacheRequest, Permisson, RolePermission } from 'src/app/shared/_models/permission.model';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class PermissionService {
  public loading$ = new Subject<boolean>();
  permissions: Permisson[] = [];
  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<Permisson[]> {
    this.loading$.next(true);
    return this.http.get<Permisson[]>(`${environment.apiUrl}/permissions/`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  getRolePermission(): Observable<RolePermission[]> {
    this.loading$.next(true);
    return this.http.get<RolePermission[]>(`${environment.apiUrl}/permissions/role-permission`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  edit(payload: RolePermission[]): Observable<RolePermission[]> {
    this.loading$.next(true);
    return this.http.put<RolePermission[]>(`${environment.apiUrl}/permissions/update`, payload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  getCache(payload: PermissionCacheRequest) {
    const params = queryString.stringify({...payload});
    return this.http.get<PermissionCache[]>(`${environment.apiUrl}/entity-caches?${params}`);
  }
}
