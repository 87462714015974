<div
  class="t-status dropdown-control"
  (click)="openStatusDropdown()"
  [title]="name"
>
  <img class="icon-md" [src]="icon || ''" />

  <ng-select
    #statusDropdown
    class="task-item-select"
    [(ngModel)]="status"
    [items]="statusOption"
    [searchable]="false"
    [clearable]="false"
    [multiple]="false"
    (change)="onChangeStatus()"
    [appendTo]="isSmallWidth ? '.t-status' : 'body'"
    [loading]="true"
    [compareWith]="compareWith"
  >
    <ng-template ng-option-tmp let-item="item">
      <div class="custom-option">
        <img class="option-icon" [src]="item.icon" />
        <span class="option-label">{{ item.name }}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
