import { UrlHelperService } from 'src/app/core';
import { FileAttachment } from 'src/app/shared/_models';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';

import { StorageService } from './storage.service';
import { catchError } from 'rxjs/operators';
import { AttachmentStatusEnum } from 'src/app/shared';


@Injectable({
    providedIn: 'root'
})
export class UploadQueueService {

    private queueSubject: BehaviorSubject<FileAttachment[]>;
    public get queue() {
        return this.queueSubject.asObservable();
    }
    private attachments: FileAttachment[] = [];
    private inProgressCount: number = 0;
    private isExecuting = false;

    private queueInterval: any;
    public concurrentUploadSize: number = 2;
    constructor(
        private http: HttpClient,
        private storageService: StorageService) {
        this.queueSubject = new BehaviorSubject(this.attachments) as BehaviorSubject<FileAttachment[]>;
    }

    public onItemUploaded(queueObj: FileAttachment, response: any, isLastItem: boolean): any {
        return { queueObj, response, isLastItem };
    }

    public onQueueStateChanged(isQueueFinished: boolean): any {
        return { isQueueFinished };
    }

    public start() {
        if (this.isExecuting) { return; }
        this.onQueueStateChanged(false);
        this.queueInterval = setInterval(() => {
            if (this.inProgressCount === this.concurrentUploadSize) { return; }

            const pendingItem = this.nextPendingItem();
            if (pendingItem) {
                this.isExecuting = true;
                this.inProgressCount++;
                this.upload(pendingItem);
            } else {
                // note that last one might be uploading
                this.isExecuting = false;
                clearInterval(this.queueInterval);
            }

        }, 1000);
    }

    /// Add files
    public add(files: File[]) {
        files.forEach((file: File) => this.addToQueue(file));
    }

    public clear() {
        this.attachments = [];
        this.queueSubject.next(this.attachments);
    }

    public removeQueueItem(queueItem: FileAttachment) {
        if (this.attachments.length === 0 || !queueItem) {
            return;
        }

        for (let i = this.attachments.length - 1; i >= 0; i--) {
            if (this.attachments[i].fileUpload === queueItem.fileUpload) {
                const item = this.attachments[i];
                if (item.uploadSubscription) {
                    item.uploadSubscription.unsubscribe();
                }

                this.attachments.splice(i, 1);
                this.queueSubject.next(this.attachments);
                return;
            }
        }
    }

    public retryQueueItem(queueItem: FileAttachment) {
        if (this.attachments.length === 0 || !queueItem) {
            return;
        }
        const item = this.attachments.find(i => i.status === AttachmentStatusEnum.FAILED);
        if (item) {
            item.status = AttachmentStatusEnum.PENDING;
            item.responseMessage = '';
            item.response = null;

            if (!this.isExecuting) {
                this.start();
            }
        }
    }

    private nextPendingItem(): FileAttachment {
        return this.attachments.find(item => item.status === AttachmentStatusEnum.PENDING);
    }

    private isAttachmentPending(attachment: FileAttachment) {
        return attachment && attachment.status === AttachmentStatusEnum.PENDING;
    }

    private addToQueue(file: File) {
        const addingItem = new FileAttachment(file);
        addingItem.fileName = file.name;
        addingItem.status = AttachmentStatusEnum.PENDING;
        this.attachments.push(addingItem);
        this.queueSubject.next(this.attachments);
    }


    private upload(queueItem: FileAttachment) {
        const formData = new FormData();
        formData.append('file', queueItem.fileUpload, queueItem.fileUpload.name);
        queueItem.uploadSubscription = this.http.post<FileAttachment>(UrlHelperService.resolveApiUrl('storage/uploadFile'),
            formData,
            {
                reportProgress: true,
                observe: 'events'
            }
        ).subscribe((event: any) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    queueItem.status = AttachmentStatusEnum.INPROGRESS;
                    queueItem.uploadProgress = Math.round(event.loaded / event.total * 100);
                    this.queueSubject.next(this.attachments);
                    break;
                case HttpEventType.Response:
                    this.uploadCompleted(queueItem, event);
                    break;
            }
        },
            (error: HttpErrorResponse) => {
                if (error.error instanceof Error) {
                    this.uploadFailed(queueItem, error);
                } else {
                    // api response error
                    this.uploadFailed(queueItem, error);
                }
            });

        return queueItem;
    }

    private uploadCompleted(queueItem: FileAttachment, response: HttpResponse<any>) {
        queueItem.response = response;
        queueItem.responseMessage = 'Uploaded successfully.';
        queueItem.url = response.body.url;
        queueItem.uploadProgress = 100;
        queueItem.status = AttachmentStatusEnum.SUCCESS;

        if (queueItem.uploadSubscription) {
            queueItem.uploadSubscription.unsubscribe();
        }
        this.queueSubject.next(this.attachments);
        const queueFinished = this.isQueueFinished();
        this.onItemUploaded(queueItem, response.body, queueFinished);
        this.onQueueStateChanged(queueFinished);
        this.inProgressCount--;
    }

    private uploadFailed(queueItem: FileAttachment, response: HttpErrorResponse) {
        let httpError = null;
        if (typeof (response) === 'string') {
            httpError = JSON.parse(response);
        }
        queueItem.uploadProgress = 0;
        queueItem.status = AttachmentStatusEnum.FAILED;
        queueItem.response = response;

        queueItem.responseMessage = `Upload failed. ${httpError.message}`;
        this.queueSubject.next(this.attachments);
        const queueFinished = this.isQueueFinished();
        this.onQueueStateChanged(queueFinished);
        this.inProgressCount--;
    }

    private errorManagement(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    private isQueueFinished(): boolean {
        return !(this.attachments.some(a => a.status === AttachmentStatusEnum.PENDING
            || a.status === AttachmentStatusEnum.INPROGRESS));
    }

}
