import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BoardEpic, EpicSearchParam, List2Res, Pageable, ProjectEpicModel } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';


@Injectable({
  providedIn: 'root'
})

export class ProjectBoardEpicService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  get(projectId: number, searchParam?: EpicSearchParam) {
    const params = queryString.stringify({...searchParam}) ;
    return this.http.get<List2Res<ProjectEpicModel>>(`${environment.apiUrl}/projects/${projectId}/board-epics/?${params}`);
  }

  getListMaster(payload: { projectId: number, keyword?: string }, pageable: Pageable): Observable<BoardEpic[]> {
    const params = queryString.stringify({keyword: payload.keyword, ...pageable});
    this.loading$.next(true);
    return this.http.get<BoardEpic[]>(`${environment.apiUrl}/projects/${payload.projectId}/board-epics/list-master?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }
}
