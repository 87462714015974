export interface CustomField {
  id: number;
  name: string;
  type: CustomFieldType;
  target: CustomFieldTarget;
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN  = 'BOOLEAN ',
}

export enum CustomFieldTarget {
  TASK  = 'TASK ',
}
