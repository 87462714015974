import { Directive, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Validator, AbstractControl } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[invalidIf]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: InvalidIfDirective, multi: true }
  ]
})
export class InvalidIfDirective implements Validator, OnChanges {

  @Input('invalidIf') invalidIf: boolean;
  private _onChange: () => void;

  constructor() { }

  validate(c: AbstractControl) {
    const value = c.value;
    if (value && this.invalidIf) {
      return { invalid: true };
    }
    return null;
  }

  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

  ngOnChanges(changes: SimpleChanges): void {
    if ('invalidIf' in changes) {
      if (this._onChange) {
        this._onChange();
      }
    }
  }
}
