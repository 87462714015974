<div class="multi-filter-wrapper">
  <div class="dropdown-wrapper dropdown-wrapper-1" dropdown (onHidden)="resetSelectedFilter()" [insideClick]="true">
    <div class="form-control dropdown-toggle-control" dropdownToggle aria-controls="dropdown-level-1">
      <span class="filter-by"><app-i18n [key]="'Filter by'"></app-i18n></span>
      <div class="selected-items">
        <ng-container *ngFor="let filter of filters">
          <ng-container *ngIf="!filter.hidden">
            <ng-container *ngFor="let selected of filter.selecteds; let index = index">
              <span class="item">
                <span class="label">
                  <span class="filter"><app-i18n [key]="filter.label"></app-i18n>:</span>
                  <span class="name">{{getOptionLabel(filter, selected)}}</span>
                </span>
                <span class="material-icons-outlined buton-item-clear" role="button"
                  (click)="stopEvent($event); removeSelectedItem(filter, index)">clear</span>
              </span>
            </ng-container>
          </ng-container>

        </ng-container>
      </div>
      <span class="btn-clear material-icons-outlined mr-2 pointer" [ngClass]="clearable ? 'visible' : 'invisible '"
        (click)="clearSelected($event)">
        clear
      </span>
      <mat-icon [svgIcon]="'arrow-drop-down'" class="icon-dropdown pointer"></mat-icon>
    </div>

    <div id="dropdown-level-1" *dropdownMenu class="dropdown-menu dropdown-level-1">
      <ng-container *ngIf="currentMode === popoverMode.one">
        <div class="header"><app-i18n [key]="'Filter by'"></app-i18n></div>
        <div class="content filter-list-content">
          <div class="item-wrapper" *ngFor="let filter of filters">

            <ng-container *ngIf="!filter.hidden">
              <div class="label clickable" (click)="stopEvent($event); changeSelectedFilter(filter)"><app-i18n
                  [key]="filter.label"></app-i18n>
              </div>
              <div class="selected-item" *ngFor="let selected of filter.selecteds; let index = index"
                (click)="stopEvent($event); removeSelectedItem(filter, index)">
                <span class="checkbox-icon material-icons">check_box</span>
                <span class="text">{{getOptionLabel(filter, selected)}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentMode === popoverMode.two">
        <div class="header">
          <span><app-i18n [key]="selectedFilter.label"></app-i18n></span>
          <button class="btn btn-flex back-btn" (click)="stopEvent($event); resetSelectedFilter()">
            <span class="material-icons-outlined">
              arrow_back_ios
            </span>
          </button>
        </div>
        <div class="content">
          <div class="search-dropdown-wrapper" *ngIf="!selectedFilter.selectTemplateRef">
            <div dropdown #dropdown="bs-dropdown" class="dropdown-wrapper">
              <div dropdownToggle class="dropdown-toggle" [ngClass]="{'loading': loading}"
                aria-controls="dropdown-level-2" (click)="dropdown.isOpen=true">
                <input #searchInput type="text" class="form-control search-input"
                  placeholder="{{'Select' | translate}} {{selectedFilter.label | translate}}"
                  (input)="onInputChange($event.target.value)">
              </div>

              <div id="dropdown-level-2" *dropdownMenu class="dropdown-menu">
                <div class="all-options">
                  <div class="option" *ngFor="let item of options"
                    (click)="stopEvent($event); selectItem(item); dropdown.isOpen = false">
                    <span>
                      <ng-container *ngIf="getIconLabel(selectedFilter, item)">
                        <img class="icon-label" [src]="getIconLabel(selectedFilter, item)" alt="">
                      </ng-container>
                      {{getOptionLabel(selectedFilter, item)}}
                    </span>
                  </div>
                </div>
                <div class="empty-option" *ngIf="options.length <=0"><app-i18n [key]="'No options'"></app-i18n></div>
              </div>
            </div>

            <div class="hint-text"><app-i18n [key]="'filterByHint'"></app-i18n>.</div>
          </div>

          <ng-container *ngIf="selectedFilter.selectTemplateRef">
            <ng-template [ngTemplateOutlet]="selectedFilter.selectTemplateRef" [ngTemplateOutletContext]="{}">
            </ng-template>
          </ng-container>

          <div class="item-wrapper all-selecteds" *ngIf="selectedFilter.selecteds.length > 0">
            <div class="label"><app-i18n [key]="'Selected'"></app-i18n></div>
            <div class="selected-item" *ngFor="let selected of selectedFilter.selecteds; let index = index"
              (click)="stopEvent($event); removeSelectedItem(selectedFilter, index)">
              <span class="checkbox-icon material-icons">check_box</span>
              <ng-container *ngIf="getIconLabel(selectedFilter, selected)">
                <img class="icon-label mr-1" [src]="getIconLabel(selectedFilter, selected)" alt="">
              </ng-container>
              <span class="text">
                {{getOptionLabel(selectedFilter, selected)}}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
