import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CreatePolicy } from './policy';
import * as queryString from 'query-string';
import { List2Res, Pageable, Policy } from 'src/app/shared';

@Injectable({ providedIn: 'root' })
export class PolicyService {
  constructor(private http: HttpClient) {}

  create(payload: CreatePolicy) {
    return this.http.post(`${environment.apiUrl}/hrm/policies`, payload);
  }

  edit(payload: CreatePolicy, id: number) {
    return this.http.put(`${environment.apiUrl}/hrm/policies/${id}`, payload);
  }

  search(pageable: Pageable) {
    const params = queryString.stringify(pageable);
    return this.http.get<List2Res<Policy>>(`${environment.apiUrl}/hrm/policies/search?${params}`);
  }

  totalPolicies() {
    return this.http.get<{ totalPolicies: number }>(`${environment.apiUrl}/hrm/policies/total-policies`);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/hrm/policies/${id}`);
  }
}
