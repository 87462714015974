import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';
import { DATE_UI_FORMAT } from 'src/app/shared';

@Pipe({ name: 'dateFormat' })
export class DateFormat implements PipeTransform {
  transform(value: string, format: string = DATE_UI_FORMAT): string {
    return UtilService.getDateUI(value, format);
  }
}
