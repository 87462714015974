import { NgModule } from '@angular/core';
import { EntityDataService } from '@ngrx/data';

import { LeaveRequestDataService } from '../../../site-management/leave-management/_services/leave-request-data.service';
import { RoleDataService } from '../../../site-management/role/_services/role-data.service';
import { ShiftDataService } from '../../../site-management/shift/_services/shift-data.service';
import { TypeOfLeaveDataService } from '../../../site-management/type-of-leave/_services/type-of-leave-data.service';
import { UserDataService } from '../../../site-management/user/_services/user-data.service';

@NgModule({
  imports: [],
  providers: [],
})
export class EntityStoreModule { // TOO: Consider to remove this module and below data services
  constructor(
    entityDataService: EntityDataService,
    shiftDataService: ShiftDataService,
    leaveRequestDataService: LeaveRequestDataService,
    typeOfLeaveDataService: TypeOfLeaveDataService,
    userDataService: UserDataService,
    roleDataService: RoleDataService,
  ) {
    entityDataService.registerServices({
      'shifts': shiftDataService,
      'leave-requests': leaveRequestDataService,
      'type-of-leaves': typeOfLeaveDataService,
      'users': userDataService,
      'roles': roleDataService,
    });
  }
}
