import { ProjectsService } from 'src/app/site-management/projects/_services/projects.service';
import { ProjectDataModel } from '../_models/project-data.model';
import { Inject, Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectBoardEpicService } from './project-board-epic.service';


@Injectable({
    providedIn: 'root'
})
export class ProjectDataService {
    projectData: ProjectDataModel;
    constructor(
      private projectService: ProjectsService,
      private projectBoardEpicService: ProjectBoardEpicService
    ) {
    }

    public async initializeData(projectId: number) {
        this.projectData = new ProjectDataModel(projectId);
        await this.populateData(projectId);
    }

    private async populateData(projectId) {
        this.projectData.project = await this.projectService.getByKey(projectId).toPromise()
            .then((proj) => {
                return proj;
            });

        if (this.projectData.project) {
            await this.LoadProjectRelatedData(projectId);
        }
    }

    private async LoadProjectRelatedData(projectId: number) {
        await forkJoin([
            this.projectService.getWorkItemTypes(projectId),
            this.projectService.getSprints(projectId),
            this.projectService.getWorkItemStatuses(projectId)
        ]).pipe(
            map(([types, sprints, statuses]) => {
                return { types, sprints, statuses };
            }))
            .toPromise().then(
                (data) => {
                    this.projectData.workItemTypes = data.types;
                    this.projectData.workItemStatuses = data.statuses;
                });


        await forkJoin([
            this.projectBoardEpicService.get(projectId),
            this.projectService.getWorkItemPriorities(projectId),
            this.projectService.getReleases(projectId),
            this.projectService.getWorkItemLabels(projectId)
        ]).pipe(
            map(([epics, priorities, releases, labels]) => {
                return { epics, priorities, releases, labels };
            }))
            .toPromise().then(
                (data) => {
                    this.projectData.epics = data.epics.content;
                    this.projectData.workItemPriorities = data.priorities;
                    this.projectData.releases = data.releases;
                    this.projectData.workItemLabels = data.labels;
                }
            );
    }
}
