<div class="calendar-month-wrapper" [ngClass]="classCss">
  <div class="month-name" *ngIf="showMonthLabel">{{getSortMonthUI() | translate}}</div>
  <div class="calendar-content">
    <div class="week-wrapper">
      <div class="date-header" *ngFor="let day of weekdays">
        <span class="date-name">{{day}}</span>
      </div>
    </div>
    <div class="week-wrapper" *ngFor="let weekData of calendar">
      <ng-container  *ngFor="let date of weekData.dates">
        <div
          class="date-wrapper"
          [ngClass]="{
            'selectable': selectable,
            'disabled': isDisabled(date),
            'selected': isSelected(date),
            'today': isToday(date)
          }"
          (click)="onSelectDate(date)"
          *ngIf="!templateRef">
          <div class="date-wrapper-content">
            <div class="date-number">
              <span class="date-text">{{getShortDateUI(date)}}</span>
            </div>
          </div>
        </div>

        <div class="date-wrapper" *ngIf="templateRef">
          <ng-template [ngTemplateOutlet]="templateRef"
            [ngTemplateOutletContext]="{
              date: date,
              shortDate:getShortDateUI(date),
              isDisabled: isDisabled(date),
              isSelected: isSelected(date),
              isToday: isToday(date)
            }">
          </ng-template>
        </div>
    </ng-container>
    </div>
  </div>
</div>
