import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'optionWithObservable'
})
export class OptionWithObservablePipe implements PipeTransform {
  transform(value: any): any {
    return value instanceof Observable ? value : of(value);
  }
}
