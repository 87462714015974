import { Group } from './group.model';
import { OkrWorkspaceRole } from './okr-workspace.model';
import { Team } from './team.model';
import { User } from './user.model';

export interface SpaceMemberRequest {
  userIds: number[];
  groupIds: number[];
  teamIds: number[];
  workspaceRoleId: number;
}
export class OkrWorkspaceMemberSearch {
  keyword?: string;
  groupIds?: number[];
  teamIds?: number[];
  roleId?: number[];

  constructor(data?: OkrWorkspaceMemberSearch) {
    this.keyword = data?.keyword || '';
    this.groupIds = data?.groupIds || [];
    this.teamIds = data?.teamIds || [];
    this.roleId = data?.roleId || [];
  }
}

export interface SpaceMemberUpsertData {
  users: User[];
  teams: Team[];
  groups: Group[];
  role: OkrWorkspaceRole;
}

export interface SpaceMemberUpdateRequest {
  memberId: number;
  roleId: number;
}

export class ExcludeWorkspace {
  excludeWorkspaceId: number;
  constructor(id: number) {
    this.excludeWorkspaceId = id;
  }
}
