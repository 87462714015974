import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/shared';
import { UserService } from '../user/_services/user.service';
import { ProfileOverlayComponent } from '../_shared/profile-overlay/profile-overlay.component';

@Injectable({ providedIn: 'root' })
export class ProfileOverlayService {
    overlayRef: OverlayRef;
    user$ = new BehaviorSubject<User>(null);

    get user(): User {
        return this.user$.value;
    }

    constructor(private overlay: Overlay, public userService: UserService) {}

    open(elementRef: ElementRef, user: User) {
        if (!user.id) {
            return;
        }

        this.getProfile(user.id);
        this.overlayRef?.dispose(); // clean profile overlay when open new overlay
        this.overlayRef = this.overlay.create(this.getConfig(elementRef)); // create overlay with config
        this.overlayRef.outsidePointerEvents().subscribe((res) => {
            const calendarEl = document.querySelector('.bs-datepicker');

            if (calendarEl?.contains(res.target as Node)) {
                return;
            }

            this.overlayRef.dispose();
        }); //  close profile when click outside
        this.overlayRef.attach(new ComponentPortal(ProfileOverlayComponent)); // add component to overlay
    }

    getProfile(userId: number) {
        this.userService.getByKey(userId).subscribe(
            (res) => {
                this.user$.next(res);
            },
            () => this.overlayRef.dispose()
        );
    }

    getConfig(elementRef: ElementRef): OverlayConfig {
        return {
            maxHeight: 400,
            height: 400,
            hasBackdrop: false,
            disposeOnNavigation: true,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'center',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'center'
                    },
                    {
                        originX: 'center',
                        originY: 'center',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    }
                ])
                .withFlexibleDimensions(true)
                .withViewportMargin(40)
                .withGrowAfterOpen(true)
                .withPush(false)
        };
    }
}
