export enum ColorOfProgressEpic {
  Done = '#77be79',
  Resolved = '#77be79',
  InProgress = '#f2a403',
  Todo = '#b6b6b6'
}

export enum TaskBookmarkColor {
  Unmarked = '#cddbe3',
  Marked = '#f1a404',
  TaskDetailUnmarked = '#42526e'
}

export enum ProgressImportJiraColor {
  Done = '#77be79',
  ToDo = '#b6b6b6'
}
