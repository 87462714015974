import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-editable-tag',
  templateUrl: './editable-tag.component.html',
  styleUrls: ['./editable-tag.component.scss']
})
export class EditableTagComponent {
  @Input() value = '';
  @Input() required = true;
  @Output() onSubmit = new EventEmitter<string>();

  onFocusout(event) {
    const text = event?.target?.innerText?.trim();
    if (text && text !== this.value) {
      this.onSubmit.emit(text);
    }
    else {
      event.target.innerText = this.value;
    }
  }

  onEnter(event, element: HTMLElement) {
    event.preventDefault();
    event.stopPropagation();
    element?.blur();
  }
}
