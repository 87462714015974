import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from 'src/app/site-management/_services';

export interface I18nMasterDataObject {
  code: string;
  displayValue: string;
  name: string;
}

@Component({
  selector: 'app-i18n-master-data',
  templateUrl: './i18n-master-data.component.html',
  styleUrls: ['./i18n-master-data.component.scss']
})
export class I18nMasterDataComponent implements OnInit {
  @Input() object: I18nMasterDataObject;
  constructor(
    private i18nService: I18nService,
  ) { }

  ngOnInit(): void {
  }

  getDisplayText() {
    return this.i18nService.getDisplayTextOfMasterData(this.object);
  }
}
