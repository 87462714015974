import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangeOrderRequest } from 'src/app/shared';
import { DynamicField, DynamicFieldParams, DynamicFieldPatchRequest, DynamicFieldRequest } from 'src/app/shared/_models/dynamic-field.model';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class DynamicFieldService {
  constructor(private http: HttpClient) {
  }

  getAll(searchParams?: DynamicFieldParams) {
    const params = queryString.stringify({...searchParams});
    return this.http.get<DynamicField[]>(`${environment.apiUrl}/dynamic-field/get-all?${params}`);
  }

  getChildByParentId(parentId: number) {
    return this.http.get<DynamicField[]>(`${environment.apiUrl}/dynamic-field/?parentId=${parentId}`);
  }

  delete(fieldId: number) {
    return this.http.request('delete', `${environment.apiUrl}/dynamic-field`, { body: fieldId });
  }

  add(payload: DynamicFieldRequest) {
    return this.http.post<DynamicField>(`${environment.apiUrl}/dynamic-field/`, payload);
  }

  update(payload: DynamicFieldRequest, fieldId: number) {
    return this.http.put<DynamicField>(`${environment.apiUrl}/dynamic-field/${fieldId}`, payload);
  }

  edit(payload: DynamicFieldRequest) {
    return this.http.put<DynamicField>(`${environment.apiUrl}/dynamic-field`, payload);
  }

  patch(fieldId: number, payload: DynamicFieldPatchRequest) {
    return this.http.patch<DynamicField>(`${environment.apiUrl}/dynamic-field/${fieldId}`, payload);
  }

  changeOrder(payload: ChangeOrderRequest) {
    return this.http.put<DynamicField>(`${environment.apiUrl}/dynamic-field/change-order`, payload);
  }
}
