<form [formGroup]="form">
  <ng-select
    [formControl]="getFormCtrl()"
    [items]="items"
    [clearable]="true"
    [searchable]="true"
    [bindLabel]="bindValue || 'name'"
    [multiple]="field.type === fieldType.multiSelect"
    (open)="openDropdown()">

    <ng-template  ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="mr-1 ng-value" *ngFor="let item of items" >
        <div class="ng-value-label d-flex align-items-center">
          <app-avatar
            [url]="item.avatar"
            [name]="item.name"
            *ngIf="showUserAvatar()"></app-avatar>
          <span class="option-label px-2">{{item?.value || item?.name}}</span>
          <span class="material-icons btn-clear ng-value-icon right" (click)="clear(item)"> clear </span>
        </div>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-value="item">
      <div [ngStyle]="{'order': item.rowIndex || 1}">
        <div class="custom-option d-flex">
          <app-avatar
            [url]="item.avatar"
            [name]="item.name"
            *ngIf="showUserAvatar()"></app-avatar>
          <div class="option-label">{{ item?.value || item?.name }}</div>
        </div>
      </div>
    </ng-template>
  </ng-select>
  <app-form-error [form]="form" [controlName]="'control'" *ngIf="isRequiredField()"></app-form-error>
</form>
