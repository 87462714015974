import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DATE_FORMAT, DATE_UI_FORMAT, FilterItem, SelectOption } from '../..';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
export const CustomLabel = 'Custom';

@Component({
  selector: 'app-period-filter',
  templateUrl: './period-filter.component.html',
  styleUrls: ['./period-filter.component.scss']
})
export class PeriodFilterComponent implements OnInit {
  @Input() filterItem: FilterItem<any>;
  @Output() filterItemChange = new EventEmitter();
  @Input() startKey = 'startDate';
  @Input() endKey = 'endDate';

  dateRange: Date[] = [];
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_UI_FORMAT,
    rangeInputFormat: DATE_UI_FORMAT
  };

  constructor() {}

  ngOnInit(): void {}

  onSelectOption(event: SelectOption, datePicker: HTMLInputElement) {
    if (event.name === CustomLabel) {
      datePicker.click();
    } else {
      this.filterItem.selecteds = [event];
      this.filterItemChange.emit(this.filterItem);
    }
  }

  onChangeDate(event: Date[]) {
    if (isEmpty(event)) {
      return;
    }

    const [startDate, endDate] = event;
    const selected = {
      name: CustomLabel,
      value: {
        [this.startKey]: moment(startDate).format(DATE_FORMAT),
        [this.endKey]: moment(endDate).format(DATE_FORMAT)
      }
    };
    this.filterItem.selecteds = [selected];
    this.filterItemChange.emit(this.filterItem);
  }
}
