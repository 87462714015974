import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContainerComponent } from './modal-container.component';

@NgModule({
    declarations: [ModalContainerComponent],
    exports: [ModalContainerComponent],
    imports: [CommonModule]
})
export class ModalContainerModule {}
