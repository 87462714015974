import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataServiceError, DefaultPersistenceResultHandler, EntityAction, EntityActionDataServiceError } from '@ngrx/data';
import { Action } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class CustomPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);
    return (data: any) => {
      const action = actionHandler(data);
      if (action) {
        if (data && data.pageable) {
          (action as any).payload.page = {
            numberOfElements: data.numberOfElements,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            pageable: data.pageable,
          };
        }
        if (data && data.content) {
          (action as any).payload.data = data.content;
        }
      }
      return action;
    };
  }

  handleError(originalAction: EntityAction): (error: DataServiceError | Error) => EntityAction<EntityActionDataServiceError> {
    const actionHandler = super.handleError(originalAction);
    return (error: DataServiceError | Error | HttpErrorResponse) => {
      const action = actionHandler(error);
      if (action && error) {
        (action as any).payload.data.error = error['error'];
      }
      return action;
    };
  }
}
