import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { DateTimePickerOverlayService } from 'src/app/site-management/_services/date-time-picker-overlay.service';
import { AbstractComponent } from '../_base-component';
import { FADE } from '../../_utils/animate';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DATE_FORMAT, DATE_TIME_UI_12_HOURS_FORMAT, FORMAT_12_HOURS } from '../..';
import { takeUntil } from 'rxjs/operators';
import { TimeFormat } from '../time-picker/models/time-picker.model';
import { TimePicker } from '../time-picker/time-picker';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [FADE]
})
export class DateTimeInputComponent  extends AbstractComponent {
  @Input() title?: string = 'Time';
  @Input() control: UntypedFormControl;

  timePickerValue: string;
  datePickerValue: Date;
  isDateChanged = false;
  isTimeChanged = false;
  isDisabled = true;
  bsConfig: Partial<BsDatepickerConfig> = { dateInputFormat: DATE_FORMAT, customTodayClass: 'bs-datepicker-today' };

 constructor(
  private dateTimePickerOverlayService: DateTimePickerOverlayService
 ) {
  super();
 }

 init() {
  this.datePickerValue = this.control?.value ? new Date(this.control.value) : null;
  this.timePickerValue = this.getSelectedTime();
  this.dateTimePickerOverlayService.overlayRef.outsidePointerEvents()
  .pipe(takeUntil(this.destroyed$))
  .subscribe(() => {
    this.updateControlValue();
    this.dateTimePickerOverlayService.hide();
  });
 }

 getSelectedTime() {
  const {hour, minute, period} = TimePicker.setupSelectedTime(this.control);
  return `${hour}:${minute} ${period}`;
 }


 onDateChange(date: Date) {
  if (date?.toISOString() !== this.datePickerValue?.toISOString()) {
    this.isDateChanged = true;
    this.datePickerValue = date;
    this.isDisabled = !this.canUpdateControl();
  }
 }

 onTimeChange(time: TimeFormat) {
    const {hour, minute, period} = time;
    this.isTimeChanged = true;
    this.timePickerValue = `${hour}:${minute} ${period}`;
    this.isDisabled = !this.canUpdateControl();
 }

 updateControlValue() {
  if (this.canUpdateControl()) {
    const dateTime = moment(this.datePickerValue).format(DATE_FORMAT) + ' ' + this.timePickerValue;
    const formatDateTime = DATE_FORMAT + ' ' + FORMAT_12_HOURS;
    if (moment(dateTime, formatDateTime, true).isValid()) {
      this.control.setValue(moment(dateTime, formatDateTime).format(DATE_TIME_UI_12_HOURS_FORMAT));
    }
  }
 }

 canUpdateControl() {
  return !this.control?.value && this.isDateChanged || this.control.value && (this.isDateChanged || this.isTimeChanged);
 }

 onSave() {
  if (this.isDisabled) {
    return;
  }
  this.updateControlValue();
  this.dateTimePickerOverlayService.hide();
 }

 onCancel() {
  this.dateTimePickerOverlayService.hide();
 }

 @HostListener('document:keydown.escape')
  closeOverlay() {
    this.dateTimePickerOverlayService.hide();
  }
}
