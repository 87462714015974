import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent implements OnInit {
  @Output() emojiSelect = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  select(event) {
    const emoji = event.emoji.id;
    this.emojiSelect.next(emoji);
  }
}
