import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CURRENT_LANGUAGE, appDefaultLang, LanguageCode } from 'src/app/shared';
import { I18nMasterDataObject } from 'src/app/shared/_components/_internationalization/i18n-master-data/i18n-master-data.component';

@Injectable({
  providedIn: 'root'
})

export class I18nService {
  private languageSource = new Subject<string>();

  languageChanged$ = this.languageSource.asObservable();

  constructor(
    private translateService: TranslateService,
  ) {}

  changeLanguage(language: string) {
    this.languageSource.next(language);
  }

  getDisplayTextOfMasterData(object: I18nMasterDataObject) {
    return object?.displayValue ?? (object?.code ? this.translateService.instant(object.code) : (object?.name ?? ''));
  }

  getCurrentLang(): LanguageCode {
    return localStorage.getItem(CURRENT_LANGUAGE) as LanguageCode || appDefaultLang;
  }
}
