import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  List2Res,
  Pageable,
  UserWorkingDay,
  UserWorkingDaySearchRequest,
} from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class WorkingDayService {

  constructor(
    private http: HttpClient,
  ) { }

  getWorkingDays(payload: UserWorkingDaySearchRequest, pageable: Pageable): Observable<List2Res<UserWorkingDay>> {
    const params = queryString.stringify({...payload, ...pageable});

    return this.http.get<List2Res<UserWorkingDay>>(`${environment.apiUrl}/working-days/?${params}`).pipe();
  }
}
