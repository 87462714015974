import { Pipe, PipeTransform } from '@angular/core';
import { SwitchDatePeriod } from '../_components/switch-date/switch-date.component';
import * as moment from 'moment';
import { UtilService } from 'src/app/core';

@Pipe({
  name: 'periodActive'
})
export class PeriodActivePipe implements PipeTransform {
  transform(value: SwitchDatePeriod, firstAndLast: any): any {
    switch (value) {
      case SwitchDatePeriod.month:
        const firstAndLastOfMonth = UtilService.getFirstDayAndLastDayOfMonth(new Date());
  
        return (
          moment(firstAndLastOfMonth.firstDay).isSame(firstAndLast.firstDay, 'month') &&
          moment(firstAndLastOfMonth.lastDay).isSame(firstAndLast.lastDay, 'month')
        );
      case SwitchDatePeriod.week:
        const firstAndLastOfWeek = UtilService.getFirstDayAndLastDayOfWeek(new Date());
        return (
          moment(firstAndLastOfWeek.firstDay.add(1, 'd')).isSame(firstAndLast.firstDay, 'week') &&
          moment(firstAndLastOfWeek.lastDay.add(1, 'd')).isSame(firstAndLast.lastDay, 'week')
        );
      case SwitchDatePeriod.year:
        const firstAndLastOfYear = UtilService.getFirstDayAndLastDayOfYear(new Date());
        return (
          moment(firstAndLastOfYear.firstDay).isSame(firstAndLast.firstDay, 'year') &&
          moment(firstAndLastOfYear.lastDay).isSame(firstAndLast.lastDay, 'year')
        );

      default:
        return (
          moment(new Date()).isSame(firstAndLast.firstDay, 'day') &&
          moment(new Date()).isSame(firstAndLast.lastDay, 'day')
        );
    }
  }
}
