import { Provider } from '@angular/core';
import { DefaultDataServiceConfig, EntityCollectionReducerMethodsFactory, PersistenceResultHandler } from '@ngrx/data';
import { environment } from 'src/environments/environment';
import { CustomPersistenceResultHandler } from '../store/entity/custom-persistent-result-handler';
import { EntityCollectionPageReducerMethodsFactory } from '../store/entity/custom-reducer-methods';

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.apiUrl
};

export const DefaultDataProvider: Provider = {
  provide: DefaultDataServiceConfig,
  useValue: defaultDataServiceConfig
};

export const ResultHandlerProvider: Provider = {
  provide: PersistenceResultHandler,
  useClass: CustomPersistenceResultHandler
};

export const EntityCollectionReducerProvider: Provider = {
  provide: EntityCollectionReducerMethodsFactory,
  useClass: EntityCollectionPageReducerMethodsFactory
};
