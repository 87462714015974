import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { PageInfo, ShiftProfile } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})

export class ShiftProfileService extends EntityCollectionServiceBase<ShiftProfile> {
  public page$: Observable<PageInfo> = new Observable();

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('shift-profiles', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }
}
