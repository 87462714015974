// Generated from src/assets/antlr/FieldExpression.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class FieldExpressionLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly QUOTE_TEXT = 17;
	public static readonly VARIABLE = 18;
	public static readonly NUMBER = 19;
	public static readonly PROPERTY_VAR = 20;
	public static readonly LPAREN = 21;
	public static readonly RPAREN = 22;
	public static readonly COMMA = 23;
	public static readonly PLUS = 24;
	public static readonly MINUS = 25;
	public static readonly MULTI = 26;
	public static readonly DIV = 27;
	public static readonly POW = 28;
	public static readonly GT = 29;
	public static readonly LT = 30;
	public static readonly GTE = 31;
	public static readonly LTE = 32;
	public static readonly EQ = 33;
	public static readonly NEQ = 34;
	public static readonly WS = 35;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", "T__7", "T__8", 
		"T__9", "T__10", "T__11", "T__12", "T__13", "T__14", "T__15", "QUOTE_TEXT", 
		"VARIABLE", "NUMBER", "PROPERTY_VAR", "POINT", "VALID_ID_START", "VALID_ID_CHAR", 
		"LPAREN", "RPAREN", "COMMA", "PLUS", "MINUS", "MULTI", "DIV", "POW", "GT", 
		"LT", "GTE", "LTE", "EQ", "NEQ", "WS",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'IF'", "'if'", "'sum'", "'SUM'", "'min'", "'MIN'", "'max'", 
		"'MAX'", "'avg'", "'AVG'", "'and'", "'AND'", "'or'", "'OR'", "'not'", 
		"'NOT'", undefined, undefined, undefined, undefined, "'('", "')'", "','", 
		"'+'", "'-'", "'*'", "'/'", "'^'", "'>'", "'<'", "'<='", "'>='", "'='", 
		"'!='",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, "QUOTE_TEXT", "VARIABLE", "NUMBER", "PROPERTY_VAR", 
		"LPAREN", "RPAREN", "COMMA", "PLUS", "MINUS", "MULTI", "DIV", "POW", "GT", 
		"LT", "GTE", "LTE", "EQ", "NEQ", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(FieldExpressionLexer._LITERAL_NAMES, FieldExpressionLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return FieldExpressionLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(FieldExpressionLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "FieldExpression.g4"; }

	// @Override
	public get ruleNames(): string[] { return FieldExpressionLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return FieldExpressionLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return FieldExpressionLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return FieldExpressionLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02%\xDF\b\x01\x04" +
		"\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04" +
		"\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r" +
		"\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12" +
		"\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17" +
		"\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C" +
		"\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t\"\x04" +
		"#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x03\x02\x03\x02\x03\x02\x03\x03" +
		"\x03\x03\x03\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03\x05" +
		"\x03\x05\x03\x06\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03\x07" +
		"\x03\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x03\n\x03\n\x03\n\x03" +
		"\n\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f\x03\f\x03\f\x03\r\x03\r\x03\r\x03" +
		"\r\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03\x10\x03" +
		"\x10\x03\x10\x03\x11\x03\x11\x03\x11\x03\x11\x03\x12\x03\x12\x07\x12\x8E" +
		"\n\x12\f\x12\x0E\x12\x91\v\x12\x03\x12\x03\x12\x03\x13\x03\x13\x07\x13" +
		"\x97\n\x13\f\x13\x0E\x13\x9A\v\x13\x03\x14\x06\x14\x9D\n\x14\r\x14\x0E" +
		"\x14\x9E\x03\x14\x03\x14\x06\x14\xA3\n\x14\r\x14\x0E\x14\xA4\x05\x14\xA7" +
		"\n\x14\x03\x15\x03\x15\x03\x15\x03\x15\x06\x15\xAD\n\x15\r\x15\x0E\x15" +
		"\xAE\x03\x16\x03\x16\x03\x17\x05\x17\xB4\n\x17\x03\x18\x03\x18\x05\x18" +
		"\xB8\n\x18\x03\x19\x03\x19\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03" +
		"\x1C\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03 \x03 \x03!\x03" +
		"!\x03\"\x03\"\x03#\x03#\x03#\x03$\x03$\x03$\x03%\x03%\x03&\x03&\x03&\x03" +
		"\'\x06\'\xDA\n\'\r\'\x0E\'\xDB\x03\'\x03\'\x02\x02\x02(\x03\x02\x03\x05" +
		"\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13" +
		"\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02" +
		"\x11!\x02\x12#\x02\x13%\x02\x14\'\x02\x15)\x02\x16+\x02\x02-\x02\x02/" +
		"\x02\x021\x02\x173\x02\x185\x02\x197\x02\x1A9\x02\x1B;\x02\x1C=\x02\x1D" +
		"?\x02\x1EA\x02\x1FC\x02 E\x02!G\x02\"I\x02#K\x02$M\x02%\x03\x02\x07\x03" +
		"\x02$$\x03\x022;\x04\x02C\\c|\x04\x022;aa\x05\x02\v\f\x0F\x0F\"\"\x02" +
		"\xE3\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02" +
		"\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02" +
		"\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02" +
		"\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02" +
		"\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02" +
		"\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02\x02\'" +
		"\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02" +
		"\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02\x029\x03\x02\x02\x02" +
		"\x02;\x03\x02\x02\x02\x02=\x03\x02\x02\x02\x02?\x03\x02\x02\x02\x02A\x03" +
		"\x02\x02\x02\x02C\x03\x02\x02\x02\x02E\x03\x02\x02\x02\x02G\x03\x02\x02" +
		"\x02\x02I\x03\x02\x02\x02\x02K\x03\x02\x02\x02\x02M\x03\x02\x02\x02\x03" +
		"O\x03\x02\x02\x02\x05R\x03\x02\x02\x02\x07U\x03\x02\x02\x02\tY\x03\x02" +
		"\x02\x02\v]\x03\x02\x02\x02\ra\x03\x02\x02\x02\x0Fe\x03\x02\x02\x02\x11" +
		"i\x03\x02\x02\x02\x13m\x03\x02\x02\x02\x15q\x03\x02\x02\x02\x17u\x03\x02" +
		"\x02\x02\x19y\x03\x02\x02\x02\x1B}\x03\x02\x02\x02\x1D\x80\x03\x02\x02" +
		"\x02\x1F\x83\x03\x02\x02\x02!\x87\x03\x02\x02\x02#\x8B\x03\x02\x02\x02" +
		"%\x94\x03\x02\x02\x02\'\x9C\x03\x02\x02\x02)\xA8\x03\x02\x02\x02+\xB0" +
		"\x03\x02\x02\x02-\xB3\x03\x02\x02\x02/\xB7\x03\x02\x02\x021\xB9\x03\x02" +
		"\x02\x023\xBB\x03\x02\x02\x025\xBD\x03\x02\x02\x027\xBF\x03\x02\x02\x02" +
		"9\xC1\x03\x02\x02\x02;\xC3\x03\x02\x02\x02=\xC5\x03\x02\x02\x02?\xC7\x03" +
		"\x02\x02\x02A\xC9\x03\x02\x02\x02C\xCB\x03\x02\x02\x02E\xCD\x03\x02\x02" +
		"\x02G\xD0\x03\x02\x02\x02I\xD3\x03\x02\x02\x02K\xD5\x03\x02\x02\x02M\xD9" +
		"\x03\x02\x02\x02OP\x07K\x02\x02PQ\x07H\x02\x02Q\x04\x03\x02\x02\x02RS" +
		"\x07k\x02\x02ST\x07h\x02\x02T\x06\x03\x02\x02\x02UV\x07u\x02\x02VW\x07" +
		"w\x02\x02WX\x07o\x02\x02X\b\x03\x02\x02\x02YZ\x07U\x02\x02Z[\x07W\x02" +
		"\x02[\\\x07O\x02\x02\\\n\x03\x02\x02\x02]^\x07o\x02\x02^_\x07k\x02\x02" +
		"_`\x07p\x02\x02`\f\x03\x02\x02\x02ab\x07O\x02\x02bc\x07K\x02\x02cd\x07" +
		"P\x02\x02d\x0E\x03\x02\x02\x02ef\x07o\x02\x02fg\x07c\x02\x02gh\x07z\x02" +
		"\x02h\x10\x03\x02\x02\x02ij\x07O\x02\x02jk\x07C\x02\x02kl\x07Z\x02\x02" +
		"l\x12\x03\x02\x02\x02mn\x07c\x02\x02no\x07x\x02\x02op\x07i\x02\x02p\x14" +
		"\x03\x02\x02\x02qr\x07C\x02\x02rs\x07X\x02\x02st\x07I\x02\x02t\x16\x03" +
		"\x02\x02\x02uv\x07c\x02\x02vw\x07p\x02\x02wx\x07f\x02\x02x\x18\x03\x02" +
		"\x02\x02yz\x07C\x02\x02z{\x07P\x02\x02{|\x07F\x02\x02|\x1A\x03\x02\x02" +
		"\x02}~\x07q\x02\x02~\x7F\x07t\x02\x02\x7F\x1C\x03\x02\x02\x02\x80\x81" +
		"\x07Q\x02\x02\x81\x82\x07T\x02\x02\x82\x1E\x03\x02\x02\x02\x83\x84\x07" +
		"p\x02\x02\x84\x85\x07q\x02\x02\x85\x86\x07v\x02\x02\x86 \x03\x02\x02\x02" +
		"\x87\x88\x07P\x02\x02\x88\x89\x07Q\x02\x02\x89\x8A\x07V\x02\x02\x8A\"" +
		"\x03\x02\x02\x02\x8B\x8F\x07$\x02\x02\x8C\x8E\n\x02\x02\x02\x8D\x8C\x03" +
		"\x02\x02\x02\x8E\x91\x03\x02\x02\x02\x8F\x8D\x03\x02\x02\x02\x8F\x90\x03" +
		"\x02\x02\x02\x90\x92\x03\x02\x02\x02\x91\x8F\x03\x02\x02\x02\x92\x93\x07" +
		"$\x02\x02\x93$\x03\x02\x02\x02\x94\x98\x05-\x17\x02\x95\x97\x05/\x18\x02" +
		"\x96\x95\x03\x02\x02\x02\x97\x9A\x03\x02\x02\x02\x98\x96\x03\x02\x02\x02" +
		"\x98\x99\x03\x02\x02\x02\x99&\x03\x02\x02\x02\x9A\x98\x03\x02\x02\x02" +
		"\x9B\x9D\t\x03\x02\x02\x9C\x9B\x03\x02\x02\x02\x9D\x9E\x03\x02\x02\x02" +
		"\x9E\x9C\x03\x02\x02\x02\x9E\x9F\x03\x02\x02\x02\x9F\xA6\x03\x02\x02\x02" +
		"\xA0\xA2\x05+\x16\x02\xA1\xA3\t\x03\x02\x02\xA2\xA1\x03\x02\x02\x02\xA3" +
		"\xA4\x03\x02\x02\x02\xA4\xA2\x03\x02\x02\x02\xA4\xA5\x03\x02\x02\x02\xA5" +
		"\xA7\x03\x02\x02\x02\xA6\xA0\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA7" +
		"(\x03\x02\x02\x02\xA8\xAC\x05%\x13\x02\xA9\xAA\x05+\x16\x02\xAA\xAB\x05" +
		"%\x13\x02\xAB\xAD\x03\x02\x02\x02\xAC\xA9\x03\x02\x02\x02\xAD\xAE\x03" +
		"\x02\x02\x02\xAE\xAC\x03\x02\x02\x02\xAE\xAF\x03\x02\x02\x02\xAF*\x03" +
		"\x02\x02\x02\xB0\xB1\x070\x02\x02\xB1,\x03\x02\x02\x02\xB2\xB4\t\x04\x02" +
		"\x02\xB3\xB2\x03\x02\x02\x02\xB4.\x03\x02\x02\x02\xB5\xB8\x05-\x17\x02" +
		"\xB6\xB8\t\x05\x02\x02\xB7\xB5\x03\x02\x02\x02\xB7\xB6\x03\x02\x02\x02" +
		"\xB80\x03\x02\x02\x02\xB9\xBA\x07*\x02\x02\xBA2\x03\x02\x02\x02\xBB\xBC" +
		"\x07+\x02\x02\xBC4\x03\x02\x02\x02\xBD\xBE\x07.\x02\x02\xBE6\x03\x02\x02" +
		"\x02\xBF\xC0\x07-\x02\x02\xC08\x03\x02\x02\x02\xC1\xC2\x07/\x02\x02\xC2" +
		":\x03\x02\x02\x02\xC3\xC4\x07,\x02\x02\xC4<\x03\x02\x02\x02\xC5\xC6\x07" +
		"1\x02\x02\xC6>\x03\x02\x02\x02\xC7\xC8\x07`\x02\x02\xC8@\x03\x02\x02\x02" +
		"\xC9\xCA\x07@\x02\x02\xCAB\x03\x02\x02\x02\xCB\xCC\x07>\x02\x02\xCCD\x03" +
		"\x02\x02\x02\xCD\xCE\x07>\x02\x02\xCE\xCF\x07?\x02\x02\xCFF\x03\x02\x02" +
		"\x02\xD0\xD1\x07@\x02\x02\xD1\xD2\x07?\x02\x02\xD2H\x03\x02\x02\x02\xD3" +
		"\xD4\x07?\x02\x02\xD4J\x03\x02\x02\x02\xD5\xD6\x07#\x02\x02\xD6\xD7\x07" +
		"?\x02\x02\xD7L\x03\x02\x02\x02\xD8\xDA\t\x06\x02\x02\xD9\xD8\x03\x02\x02" +
		"\x02\xDA\xDB\x03\x02\x02\x02\xDB\xD9\x03\x02\x02\x02\xDB\xDC\x03\x02\x02" +
		"\x02\xDC\xDD\x03\x02\x02\x02\xDD\xDE\b\'\x02\x02\xDEN\x03\x02\x02\x02" +
		"\f\x02\x8F\x98\x9E\xA4\xA6\xAE\xB3\xB7\xDB\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!FieldExpressionLexer.__ATN) {
			FieldExpressionLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(FieldExpressionLexer._serializedATN));
		}

		return FieldExpressionLexer.__ATN;
	}

}

