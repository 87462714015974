import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
  ) { }

  setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateOgImage(url: string) {
    this.meta.updateTag({ property: 'og:image', content: url });
  }

  updateOgDescription(description: string) {
    this.meta.updateTag({ property: 'og:description', content: description });
  }
}
