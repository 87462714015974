<div class="flex justify-content-between">
    <div
        *ngFor="let item of colors"
        class="p-2 rounded pointer box-color text-center"
        (click)="onSelect(item)"
        [ngClass]="{ 'border border-primary': selectColor | selectColor: item }"
        [style.backgroundColor]="item.colorCode"
    >
        <span>{{ label || item.label || 'Text' }}</span>
    </div>
</div>
