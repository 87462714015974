<app-modal-container #modal>
  <ng-container header>
    <div class="d-flex-center jc_sb">
      <app-i18n [key]="'Advance Filter'"></app-i18n>
      <div class="btn-close d-none" (click)="onCancel()">
        <span class="material-icons-outlined icon-md"> close </span>
      </div>
    </div>
  </ng-container>

  <ng-container body>
    <div class="advance-filter-content" [formGroup]="form">
      <div class="form-group">
        <label><app-i18n [key]="'Filter Name'"></app-i18n></label>
        <input class="form-control custom" formControlName="name" />
        <app-form-error [form]="form" [controlName]="'name'"></app-form-error>
      </div>

      <div class="form-group">
        <label><app-i18n [key]="'Description'"></app-i18n></label>
        <textarea class="form-control" formControlName="description" rows="2"></textarea>
        <app-form-error [form]="form" [controlName]="'description'"></app-form-error>
      </div>
    
      <div class="form-group">
        <ng-container *ngTemplateOutlet="conditionGroup; context: {row: advanceFilters}"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <button type="button" class="btn btn-white mr-3" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="button"
      class="btn btn-primary mr-3"
      appRestrictMultiSubmit
      [disabled]="form.pristine && !changed"
      (multiClick)="onSubmit(true)">
      <app-i18n [key]="'Save'"></app-i18n>
    </button>
    <button type="submit" class="btn btn-primary" appRestrictMultiSubmit (multiClick)="onSubmit()"><app-i18n [key]="'Search'"></app-i18n></button>
  </ng-container>
</app-modal-container>

<ng-template #conditionGroup let-row="row" let-parent="parent">
  <div class="condition-group-wrapper">
    <div class="condition-group-header" [ngClass]="{'new': row?.child}">
      <div class="operator-wrapper">
        <span class="operator-item"
          [ngClass]="{'active': row.logicOperator === LogicOperator.AND}"
          (click)="row.logicOperator = LogicOperator.AND">AND</span>
        <span class="operator-item"
          [ngClass]="{'active': row.logicOperator === LogicOperator.OR}"
          (click)="row.logicOperator = LogicOperator.OR">OR</span>
        <span class="operator-item"
          *ngIf="isPossibleOperator(row, LogicOperator.NOT)"
          [ngClass]="{'active': row.logicOperator === LogicOperator.NOT}"
          (click)="row.logicOperator = LogicOperator.NOT">NOT</span>
      </div>
  
      <div class="condition-action" *ngIf="canAddFilter(row)">
        <div class="condition-action-web">
          <!-- <span class="pointer" (click)="addNewFilterRow(row, true)">+ <app-i18n [key]="'Add Custom Item'"></app-i18n></span> -->
          <span class="pointer" (click)="addNewFilterRow(row)">+ <app-i18n [key]="'Add Item'"></app-i18n></span>
          <span class="pointer" (click)="addNewConditionGroup(row)">+ <app-i18n [key]="'Add Condition Group'"></app-i18n></span>
        </div>
        <div class="condition-action-mobile">
          <ng-container *ngTemplateOutlet="toggleTemplate; context: {row: row}"></ng-container>
        </div>
      </div>
    </div>
    <div *ngFor="let conditionRow of row.expr; let index = index">
      <div *ngIf="conditionRow?.operator" class="filter-row" [ngClass]="{'first': index === 0, 'last': index === row.expr.length - 1 && !row?.child}">
        <div class="filter-row-wrapper">
          <div class="field-compare">
            <ng-select [(ngModel)]="conditionRow.lhs"
              [placeholder]="'None' | translate"
              [items]="compareFields"
              [ngClass]="{'ng-touched': conditionRow.touched}"
              [searchable]="true"
              [clearable]="true"
              [multiple]="false"
              [editableSearchTerm]="true"
              [appendTo]="'body'"
              [required]="true"
              (change)="updateFieldCompare(conditionRow)"
              (search)="onTypingCompareField($event)"
              (open)="getCompareField()"
            ></ng-select>
          </div>
          <div class="field-compare mx-3">
            <ng-select [(ngModel)]="conditionRow.operator"
              [placeholder]="'None' | translate"
              [items]="operatorFields[conditionRow.lhs]"
              [ngClass]="{'ng-touched': conditionRow.touched}"
              [searchable]="true"
              [clearable]="false"
              [multiple]="false"
              [appendTo]="'body'"
              [required]="true"
              (open)="loadOperatorField(conditionRow)"
              (change)="onDataChanged()"
            ></ng-select>
          </div>
          <ng-container *ngIf="!isDatePickerField(conditionRow.lhs)">
            <div class="field-compare">
              <ng-select [(ngModel)]="conditionRow.rhs"
                [placeholder]="'None' | translate"
                [items]="valueFields[conditionRow.lhs]"
                [ngClass]="{'ng-touched': conditionRow.touched}"
                [searchable]="true"
                [clearable]="true"
                [multiple]="isMultiField(conditionRow.operator)"
                [appendTo]="'body'"
                [required]="true"
                (open)="loadValueField(conditionRow)"
                (change)="onDataChanged()"
              ></ng-select>
            </div>
          </ng-container>
          <div class="field-compare" *ngIf="isDatePickerField(conditionRow.lhs)">
            <input placeholder="NULL" type="text" class="form-control"
              bsDatepicker placement="bottom left" [(ngModel)]="conditionRow.rhs"
              [bsConfig]="bsConfig" (bsValueChange)="dateUpdated(conditionRow, $event)">
          </div>
        </div>
        <button class="btn btn-icon btn-delete" (click)="removeFilterRow(row, conditionRow, parent)">
          <mat-icon [svgIcon]="'trash'" class="icon-md ml-3 mr-2 delete-filter-icon"></mat-icon>
        </button>
      </div>
      <div *ngIf="!conditionRow?.operator" class="filter-row" [ngClass]="{'first': index === 0, 'last': index === row.expr.length - 1 && !row?.child}">
        <div class="filter-row-wrapper">
          <div class="xql-field">
            <app-xql-editor (search)="conditionRow.xql = $event" [enableSearch]="false"></app-xql-editor>
          </div>
        </div>
        <button class="btn btn-icon btn-delete" (click)="removeFilterRow(row, conditionRow, parent)">
          <mat-icon [svgIcon]="'trash'" class="icon-md ml-3 mr-2 delete-filter-icon"></mat-icon>
        </button>
      </div>
    </div>
    
    <ng-container *ngIf="row.child">
      <div class="filter-row new" *ngFor="let group of row.child; let index = index" [ngClass]="{'first-child': index === 0}">
        <ng-container *ngTemplateOutlet="conditionGroup; context: {row: group, parent: row}"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #toggleTemplate let-row="row">
  <div class="btn-group btn-burger-dot" dropdown placement="bottom left">
    <button id="button-alignment" dropdownToggle type="button"
      class="btn btn-icon btn-filter-action btn-light"
      aria-controls="dropdown-alignment">
      <span class="material-icons">more_horiz</span>
    </button>
    <ul id="dropdown-alignment" *dropdownMenu
      class="dropdown-menu dropdown-menu-left" role="menu"
      aria-labelledby="button-alignment">
      <li role="menuitem" (click)="addNewFilterRow(row, true)">
        <a class="dropdown-item flex ai_c">
          <mat-icon [svgIcon]="'task-management-create'" class="icon-md mr-2"></mat-icon>
          <app-i18n [key]="'Add Custom Item'"></app-i18n>
        </a>
      </li>

      <li role="menuitem" (click)="addNewFilterRow(row)">
        <a class="dropdown-item flex ai_c">
          <mat-icon [svgIcon]="'task-management-create'" class="icon-md mr-2"></mat-icon>
          <app-i18n [key]="'Add Item'"></app-i18n>
        </a>
      </li>

      <li role="menuitem" (click)="addNewConditionGroup(row)">
        <a class="dropdown-item flex ai_c">
          <mat-icon [svgIcon]="'task-management-create'" class="icon-md mr-2"></mat-icon>
          <app-i18n [key]="'Add Condition Group'"></app-i18n>
        </a>
      </li>
    </ul>
  </div>
</ng-template>
