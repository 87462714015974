import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AbstractComponent } from '../_base-component';
import { TimePickerControlComponent } from '../time-picker-control/time-picker-control.component';
import { parseTimeString } from '../time-picker-control/time-picker-control.utils';

@Component({
  selector: 'app-time-picker-input',
  templateUrl: './time-picker-input.component.html',
  styleUrls: ['./time-picker-input.component.scss']
})
export class TimePickerInputComponent extends AbstractComponent {
  @ViewChild('timePicker', {static: true}) timePickerComponent: TimePickerControlComponent;

  @Input() formControl: UntypedFormControl;
  @Input() defaultValue: string;

  constructor() {
    super();
  }

  init() {
    this.setSelectedTime();
  }

  setSelectedTime() {
    if (!this.formControl.value && this.defaultValue) {
      this.timePickerComponent.selectedTime = parseTimeString(this.defaultValue);
    }
  }
}
