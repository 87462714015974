import { Component } from '@angular/core';
import { FieldOption } from 'src/app/shared/_models';
import { DFieldCellBaseTableComponent } from '../d-field-cell-base-table';
import { FieldOptionType } from 'src/app/shared/_utils';

@Component({
  selector: 'app-d-field-cell-dropdown-table',
  templateUrl: './d-field-cell-dropdown-table.component.html',
  styleUrls: ['./d-field-cell-dropdown-table.component.scss']
})
export class DFieldCellDropdownTableComponent extends DFieldCellBaseTableComponent{

  cellDropdown: FieldOption[] = [];

  constructor() {
    super();
  }


  init() {
    super.init();
    this.cellDropdown = this.getDataCell() as FieldOption[];
  }

  getTextDropdown() {
    return this.cellDropdown?.map(e => e?.name || e.value)?.join(', ');
  }

  isAvatar() {
    return this.column.optionType === FieldOptionType.user;
  }
}
