import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { UserBase } from '../../_models';

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDropdownComponent implements OnInit {
  @Input() control: UntypedFormControl | any;
  @Input() items: UserBase[];
  @Input() typeahead: Subject<string>;
  @Input() searchable = true;
  @Input() multiple = true;
  @Input() limitShow = 1000;
  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

}
