import { Injectable, EventEmitter } from '@angular/core';
import { Task } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})

export class TaskReminderService {
  showCustomReminder: EventEmitter<Task> = new EventEmitter<Task>();

  onShowCustomReminder(task: Task) {
    this.showCustomReminder.emit(task);
  }
}