import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SelectColor {
    label?: string;
    colorCode: string;
}

@Component({
    selector: 'app-select-color',
    templateUrl: './select-color.component.html',
    styleUrls: ['./select-color.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectColorComponent,
            multi: true
        }
    ]
})
export class SelectColorComponent implements ControlValueAccessor {
    @Input() colors: SelectColor[];
    @Input() label?: string | null;

    selectColor: SelectColor;

    touched = false;

    disabled = false;

    constructor() {}

    onChange = (color: SelectColor) => {};

    onTouched = () => {};

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(color: SelectColor): void {
        this.selectColor = color;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    onSelect(color: SelectColor) {
        this.markAsTouched();

        if (!this.disabled) {
            this.selectColor = color;
            this.onChange(this.selectColor);
        }
    }
}
