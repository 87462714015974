import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Attachment } from '../../_models';
import { EditorFormPayload } from '../editor-form/editor-form.component';
import { EditorUserMention } from '../editor/editor.component';
import { AwsImg } from './editor-html-view/editor-html-view.component';

export interface EditorContentEditableSave {
  payload: EditorFormPayload;
  callback: () => void;
}

@Component({
  selector: 'app-editor-content-editable',
  templateUrl: './editor-content-editable.component.html',
  styleUrls: ['./editor-content-editable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorContentEditableComponent implements OnInit {
  @Input() description: string;
  @Input() placeholder = `<span class="text-muted">${this.descriptionText}</span>`;
  @Input() getUserPromise: (searchTerm: string) => Promise<EditorUserMention[]>;
  @Input() upload$: (file: File) => Observable<Attachment>;
  @Output() save: EventEmitter<EditorContentEditableSave> = new EventEmitter();
  
  get descriptionText() : string {
    return this.translateService.instant('Add a description...');
  }
  

  showEditor = false;
  parsedHtml = '';
  awsImgs: AwsImg[] = [];
  constructor(
    private modalService: BsModalService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

  }

  onCancel() {
    this.showEditor = false;
  }

  onSave(payload: EditorFormPayload) {
    let htmlString = payload.htmlString;
    this.awsImgs.forEach(e => {
      htmlString = htmlString.replace(e.objectUrl, e.url);
    });

    this.save.emit({
      payload: {
        htmlString,
        attachments: payload.attachments.map(e => {
          return {
            name: e.name,
            url: e.url,
            type: 'Description',
          };
        }),
        selectedUserMention: payload.selectedUserMention,
      },
      callback: () => {
        this.parsedHtml = '';
        this.awsImgs = [];
        this.showEditor = false;
      }
    });
  }

  onClickComment(event: any, html: HTMLElement) {
    if (event?.target?.nodeName === 'IMG') {
      return;
    } else {
      this.showEditor = true;
    }
  }

  onContentParsed(html: string, awsImgs: AwsImg[]) {
    this.parsedHtml = html;
    this.awsImgs = awsImgs;
  }
}
