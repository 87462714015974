import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'alphabetize' })
export class AlphabetizePipe implements PipeTransform {
  transform(value: number): string {
    return this.alphabetize(value - 1);
  }

  alphabetize(num: number) {
    let ordA = 'a'.charCodeAt(0);
    let ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = "";
    while (num >= 0) {
      s += String.fromCharCode(num % len + ordA);
      num = Math.floor(num / len) - 1;
    }
    return s.toUpperCase();
  }
}
