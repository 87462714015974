<ng-container *ngIf="hideUpload; else other">
  <div class="app-editor-wrapper" #quillEditorHTML>
    <quill-editor
      #quillEditor
      [formControl]="control"
      [placeholder]="placeholder | translate"
      [modules]="modules"
      (onEditorCreated)="getEditorInstance($event)"
      [disabled]="disabled"
    ></quill-editor>
</div>
</ng-container>

<ng-template #other>
  <div class="app-editor-wrapper" #quillEditorHTML appFileDropZone
    (onFileDropped)="onFileChanged($event)">

  <quill-editor
    #quillEditor
    [formControl]="control"
    [placeholder]="placeholder | translate"
    [modules]="modules"
    (onEditorCreated)="getEditorInstance($event)"
    [disabled]="disabled"
  ></quill-editor>

    <input class="d-none" type="file" (change)="onFileChanged($event.target.files)" #fileInput
      (click)="fileInput.value = null" multiple>
  </div>
</ng-template>
