import { Component } from '@angular/core';
import { DFieldCellBaseTableComponent } from '../d-field-cell-base-table';

@Component({
  selector: 'app-d-field-cell-text-table',
  templateUrl: './d-field-cell-text-table.component.html',
  styleUrls: ['./d-field-cell-text-table.component.scss']
})
export class DFieldCellTextTableComponent extends DFieldCellBaseTableComponent{

  cellText: string;

  constructor() {
    super();
  }

  init() {
    super.init();
    this.cellText = this.getDataCell() as string;
  }
}
