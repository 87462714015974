export class BaseAttachment {
  fileId: number;
  name: string;
  url: string;
  previewUrl: string;
  thumbnailUrl: string;
  objectUrl?: string;
  type: AttachmentType;
  createdAt?: string;
  previewUrlLoading?: boolean;
}

export type AttachmentType = 'Comment' | 'Description' | 'Other';
