import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import * as moment from 'moment';
import { DATE_FORMAT } from '../..';
@Component({
  selector: 'app-calendar-month',
  templateUrl: './calendar-month.component.html',
  styleUrls: ['./calendar-month.component.scss']
})
export class CalendarMonthComponent implements OnInit {
  @Input() templateRef: TemplateRef<any>;
  @Input() firstDateOfMonth: string = moment().format(DATE_FORMAT);
  @Input() showMonthLabel = true;
  @Input() selectedDate: string[] = []; // Format: YYYY-MM-DD
  @Input() selectable = true;
  @Input() showSixWeeks = true;
  @Input() classCss = ''; // Ex: 'month-view'
  @Output() selectDate: EventEmitter<string> = new EventEmitter();

  calendar: { week: number, dates: string[] }[] = [];
  weekdays: string[] = [];
  today = moment().format(DATE_FORMAT);

  constructor() {
    this.weekdays = moment.weekdays().map(e => {
      return e.substring(0, 2);
    });
  }

  ngOnInit(): void {
    this.buildCalendar();
  }

  buildCalendar() {
    const startDay = moment(this.firstDateOfMonth).startOf('month').startOf('week');
    const endDay = moment(this.firstDateOfMonth).endOf('month').endOf('week');
    const date = startDay.clone().subtract(1, 'day');

    while (date.isBefore(endDay, 'day')){
      this.calendar.push({
        week: date.week(),
        dates: Array(7).fill(0).map(() => date.add(1, 'day').clone().format(DATE_FORMAT)),
      });
    }

    if (this.calendar.length < 6 && this.showSixWeeks) {
      this.calendar.push({
        week: date.week(),
        dates: Array(7).fill(0).map(() => date.add(1, 'day').clone().format(DATE_FORMAT)),
      });
    }
  }

  getSortMonthUI() {
    return moment(this.firstDateOfMonth).format('MMMM');
  }

  getShortDateUI(date: string) {
    return moment(date).format('DD');
  }

  isDisabled(date: string) {
    return moment(date).format('MM') !== moment(this.firstDateOfMonth).format('MM');
  }

  isSelected(date: string) {
    return this.selectedDate.includes(date);
  }

  isToday(date: string) {
    return this.today === date;
  }

  onSelectDate(date: string) {
    if (this.isDisabled(date) || !this.selectable) {
      return;
    }

    this.selectDate.emit(date);
  }

}
