<div class="item-wrapper all-selecteds">
  <div class="label">{{ 'Options' | translate }}</div>
  <div
    class="selected-item hover-item"
    *ngFor="let i of filterItem.items; let index = index"
    (click)="onSelectOption(i, dateRangePicker)"
  >
    <span class="text">{{ i.name | translate }}</span>
  </div>
</div>

<div class="filter-custom-range">
  <input
    type="text"
    placeholder="Daterangepicker"
    #dateRangePicker
    class="form-control"
    bsDaterangepicker
    [placement]="'bottom left'"
    [(ngModel)]="dateRange"
    (bsValueChange)="onChangeDate($event)"
    [bsConfig]="bsConfig"
  />
</div>
