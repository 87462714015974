export enum ReminderRepeatPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum StopType {
  NEVER = 'NEVER',
  ON = 'ON',
  AFTER = 'AFTER',
  ON_STATUS = 'ON_STATUS'
}

export enum WeekDayStatus {
  REMIND = 'REMIND',
  NO_REMIND = 'NO_REMIND'
}

export enum ReminderPeriod {
  MINUTE_30 = '30_minutes',
  HOUR_1 = '1_hours',
  HOUR_2 = '2_hours',
  DAY_1 = '1_days',
  DAY_2 = '2_days',
  CUSTOM = 'CUSTOM'
}
