import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';

@Pipe({
  name: 'taskTimeFormat'
})

// Input: History -> Time value [hour, minute]
// Output: 12 Hours format: Eg: '02:22 AM'
export class TaskTimeFormatPipe implements PipeTransform {
  transform(value: number[]): string {
    const hour = value[0];
    const minute = value[1];
    const time = `${hour}:${minute}:00`;
    return UtilService.convertFullTimeTo12Hours(time);
  }
}
