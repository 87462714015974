import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from 'src/app/core';
import { Field, FieldValue, RowValue } from 'src/app/shared';

@Pipe({ name: 'valueCellTable' })
export class ValueCellTablePipe implements PipeTransform {
  transform(data: {row: RowValue, col: Field}) {
    const cell: FieldValue = data.row.columns.find(c => c.fieldId === data.col.id);

    if (!cell) {
      return '';
    }
    const value = UtilService.getCurrentFieldValue(data.col.type, cell);
    return UtilService.getFieldValueText(data.col, value) || '';
  }
}
