import { Permisson, RolePermission } from 'src/app/shared';
import { UtilService } from 'src/app/core';

const ROLE_PERMISSIONS_KEY = 'expercRolePermissions';
const PERMISSIONS_KEY = 'expercPermissions';

export const getPermissions = () => UtilService.getStorage(PERMISSIONS_KEY);
export const setPermissions = (permissions: Permisson[]) => UtilService.setStorage(PERMISSIONS_KEY, permissions);

export const getRolePermissions = () => UtilService.getStorage(ROLE_PERMISSIONS_KEY);
export const setRolePermissions = (rolePermissions: RolePermission[]) => UtilService.setStorage(ROLE_PERMISSIONS_KEY, rolePermissions);
