import { createFeature, createReducer, on } from '@ngrx/store';
import { ProjectState } from './project.models';
import { ProjectActions } from './project.actions';
import { featureKey } from './project.selectors';

const initialState: ProjectState = {
  projects: [],
  members: [],
  statuses: [],
  priorities: [],
  releases: [],
  epics: [],
  sprints: [],
  labels: [],
  storyLines: [],
  platforms: [],
};

export const projectReducer = createReducer(
  initialState,
  on(ProjectActions.resetProjectState, (state) => {
    // reset when change current project
    return {
      ...JSON.parse(JSON.stringify(initialState)),
      projects: state.projects, // keep project list
    };
  }),
  on(ProjectActions.setProjects, (state, { projects }) => {
    return {
      ...state,
      projects
    };
  }),
  on(ProjectActions.setProjectMembers, (state, { members }) => {
    return {
      ...state,
      members
    };
  }),
  on(ProjectActions.setStatuses, (state, { statuses }) => {
    return {
      ...state,
      statuses
    };
  }),
  on(ProjectActions.setPriorities, (state, { priorities }) => {
    return {
      ...state,
      priorities
    };
  }),
  on(ProjectActions.setReleases, (state, { releases }) => {
    return {
      ...state,
      releases
    };
  }),
  on(ProjectActions.setEpics, (state, { epics }) => {
    return {
      ...state,
      epics
    };
  }),
  on(ProjectActions.setSprints, (state, { sprints }) => {
    return {
      ...state,
      sprints
    };
  }),
  on(ProjectActions.setLabels, (state, { labels }) => {
    return {
      ...state,
      labels
    };
  }),
  on(ProjectActions.setStorylines, (state, { storyLines }) => {
    return {
      ...state,
      storyLines
    };
  }),
  on(ProjectActions.setPlatforms, (state, { platforms }) => {
    return {
      ...state,
      platforms
    };
  }),
);

export const projectFeature = createFeature({
  name: featureKey,
  reducer: projectReducer
});
