import { SelectOption } from '../..';
import { ReminderRepeatPeriod } from '../_enums';

export const reminderRepeatPeriods: SelectOption[] = [
  {
    name: 'repeatDay',
    value: ReminderRepeatPeriod.DAY,
  },
  {
    name: 'repeatWeek',
    value: ReminderRepeatPeriod.WEEK,
  },
  {
    name: 'repeatMonth',
    value: ReminderRepeatPeriod.MONTH,
  },
  {
    name: 'repeatYear',
    value: ReminderRepeatPeriod.YEAR
  }
];
