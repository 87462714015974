import { EpicSearchParam } from './../../../../shared/_models/epic.model';
import { DataFilterService } from './../../../_services/data-filter.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EpicDataFilterService extends DataFilterService<EpicSearchParam> {

    get CurrentSearchParam() {
        if (this.searchParam && this.searchParam.projectId !== 0) {
            const cloneParam = new EpicSearchParam();
            Object.assign(cloneParam, this.searchParam);
            return cloneParam;
        }
        return null;
    }

    constructor() {
      super();
    }
}
