import { Company } from './company.model';
import { Shift } from './shift.model';
import { User } from './user.model';

export interface ShiftDay {
  id?: number;
  dayCode?: string;
  shift?: Shift;
  shiftId?: number;
  status: string;
}
// export interface WorkDayShift {
//   id?: number;
//   dayCode: string;
//   shiftDays: ShiftDay[];
//   isActiveDay?: boolean;
// }

export interface UserWorkingDay {
  user: User;
  shiftDays: ShiftDay[];
}

export interface UserWorkingDaySearchRequest {
  userIds: number[];
}

