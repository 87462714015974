import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, switchMap, map } from 'rxjs/operators';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

import { UrlHelperService } from 'src/app/core';
import { PageInfo, ProjectEpicModel, Task } from 'src/app/shared';
import { EpicSearchParam } from './../../../../shared/_models/epic.model';
import { EpicDataFilterService } from './epic-data-filter.service';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';
import { EpicClone, EpicClonePayload } from '../model';

@Injectable({
  providedIn: 'root'
})
export class EpicService extends EntityCollectionServiceBase<ProjectEpicModel>{
  public page$: Observable<PageInfo> = new Observable();
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    epicDataFilterService: EpicDataFilterService
  ) {
    super('epics', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getTasksByEpic(epicId: number, searchParam: EpicSearchParam = null): Observable<Task[]> {
    let query = `keyword=&page=0&size=10000`;
    if (searchParam) {
      query = searchParam.toQueryString();
    }

    const requestUrl = UrlHelperService.resolveApiUrl(`projects/${searchParam.projectId}/board-epics/${epicId}/tasks/?${query}`);
    return this.http.get<Task[]>(requestUrl);
  }


  createEpic(epic: ProjectEpicModel): Observable<ProjectEpicModel> {
    this.setLoading(true);
    // TODO: Not implemented API
    return this.http.post<ProjectEpicModel>(UrlHelperService.resolveApiUrl(`projects/${epic.projectId}/board-epics/`),
      epic)
      .pipe(
        finalize(() => this.setLoading(false)),
      );
  }
  updateEpic(epic: ProjectEpicModel): Observable<ProjectEpicModel> {
    this.setLoading(true);
    // TODO: Not implemented API
    return this.http.put<ProjectEpicModel>(UrlHelperService.resolveApiUrl(`projects/${epic.projectId}/board-epics/${epic.id}/`),
      epic)
      .pipe(
        finalize(() => this.setLoading(false))
      );
  }

  deleteEpic(projectId: number, epicId: number): Observable<any> {
    this.setLoading(true);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        projectId,
        epicId
      },
    };

    const requestUrl = UrlHelperService.resolveApiUrl(`projects/${projectId}/board-epics/${epicId}`);
    return this.http.delete<any>(requestUrl, options)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getDetailEpic(projectId: number, epicId: number) {
    return this.http.get<ProjectEpicModel>(`${environment.apiUrl}/projects/${projectId}/board-epics/${epicId}`);
  }

  cloneEpics(payload: EpicClonePayload) {
    return this.http.post<EpicClone>(`${environment.apiUrl}/tasks/clone-epic`, payload);
  }
}
