<div [ngClass]="style?.main">
  <div [ngClass]="style?.footer">
  <div class="datatable-footer-inner">
      <div class="page-count" *ngIf="pageInfo?.totalElements > 0">{{ pageInfo?.totalElements }} total</div>

        <datatable-pager
            class="datatable-pager"
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="pageInfo?.pageable?.pageNumber + 1"
            [size]="pageInfo?.pageable?.pageSize"
            [count]="pageInfo?.totalElements"
            [hidden]="!(pageInfo?.totalElements / pageInfo?.pageable?.pageSize > 1)"
            (change)="onPageChange($event)"
        >
        </datatable-pager>

        <ng-select
            class="px-2 ml-auto"
            [items]="PAGE_OPTION"
            [appendTo]="'body'"
            [(ngModel)]="pageSize"
            (change)="onPageSizeChange($event)"
            [clearable]="false"
        >
        </ng-select>
    </div>
  </div>
</div>
