import { isDevMode } from "@angular/core";
import { StoreDevtoolsOptions } from "@ngrx/store-devtools";

export const StoreDevConfig: StoreDevtoolsOptions = {
  maxAge: 25,
  trace: true,
  traceLimit: 75,
  autoPause: true
}

