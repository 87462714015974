export class LicenseResponse {
  id: number;
  name: string;
  limitedUser: number;
  limitedStorage: number;
  rate: number;
  trialLimitDays: number;
  limitedProject: number;
  leaveTracking: boolean;
  leaveTrackingLimitUser: number;
  dailyReport: boolean;
  hr: boolean;
  okr: boolean;
  taskManagement: boolean;
  allowProjectType: 'Scrum' | 'Kanban';
  taskManagementLimitUser: number;
  testManagement: boolean;
  timesheet: boolean;
  documentManagement: true;
}

export class LicensePeriodResponse {
  id: number;
  period: number;
  discount: number;
}

