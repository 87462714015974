import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppIcon } from '../../_models/app/icon.model';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss']
})
export class IconPickerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() icons: AppIcon[];
  @Input() onlyIcon = false;
  @Input() iconOfDynamic = false;

  iconIndex!: number;
  parentIcon!: string;
  constructor() { }

  ngOnInit(): void {
    this.checkIconSelected();
  }

  onSelectIcon(icon: string, colorCode: string) {
    this.form.get('icon').setValue(icon);
    this.form.get('colorCode').setValue(colorCode);
  }

  onSelectIconIndex(icon: string, index: number) {
    this.parentIcon = icon;
    this.iconIndex = index;
    // Save selection
    this.onSelectIcon(icon, this.icons[index].colorCode);
  }

  checkIconSelected() {
    this.icons.forEach((icon, index) => {
      // Check selected icon on parent
      if (this.form.get('icon').value === icon.icon) {
        this.parentIcon = icon.icon;
        this.iconIndex = index;
        return;
      }
      else {
        icon?.child?.forEach(child => {
          if (this.form.get('icon').value === child.icon) {
            this.parentIcon = icon.icon;
            this.iconIndex = index;
            return;
          }
        });
      }
    });
  }
}
