import {
  Directive, ElementRef,
  Renderer2
} from '@angular/core';
import { User } from 'src/app/shared/_models';
import { ProfileOverlayService } from 'src/app/site-management/_services';

@Directive({
  selector: '[quillMentionEvent]'
})
export class QuillMentionEventDirective {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private profileOverlayService: ProfileOverlayService,
  ) { }

  ngAfterViewInit() {
    if (!this.elementRef) {
      return;
    }
    const mentionSpans = this.elementRef.nativeElement.querySelectorAll('span.mention');
    mentionSpans.forEach(span => {
      this.renderer.listen(span, 'click', () => {
        const id = span.getAttribute('data-id');
        const fullName = span.getAttribute('data-value');
        const mentionData = {id, fullName}

        if (mentionData?.id) {
          this.profileOverlayService.open(span, mentionData as User);
        }
      });
    });
  }
}
