import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { UtilService } from 'src/app/core';
import { bsConfig, DATE_UI_FORMAT } from 'src/app/shared';

@Component({
  selector: 'app-cell-date-editable',
  templateUrl: './cell-date-editable.component.html',
  styleUrls: ['./cell-date-editable.component.scss']
})
export class CellDateEditableComponent implements OnInit, OnChanges {
  @Input() date = '';
  @Output() dateChange: EventEmitter<string> = new EventEmitter<string>();

  selectedDate: Date = null;
  bsConfig = bsConfig;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dueDate?.currentValue) {
      this.selectedDate = moment(this.date).toDate();
    }
  }

  getDateString() {
    if(!this.date) {
      return;
    }
    return UtilService.getDateFormat(this.date, DATE_UI_FORMAT);
  }

  onDateChange(date: Date) {
    if (!date) {
      return;
    }
    const dateFormatted = UtilService.getDateFormat(date);
    if (dateFormatted !== this.date) {
      this.date = dateFormatted;
      this.dateChange.emit(this.date);
    }
  }
}
