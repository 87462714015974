import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChangeOrderFieldRequest, DynamicFieldSearch, DynamicFieldTableRequest, Field, FieldDetail, FieldPatchRequest,
  FieldRequest, FieldValue, FieldValueRequest, LinkFieldRequest, VariableResponse
} from 'src/app/shared/_models/dynamic-field.model';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';
import { serialize } from 'object-to-formdata';
import { List2Res, Pageable } from 'src/app/shared';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  updateFieldValue$ = new Subject<FieldValue[]>();

  constructor(private http: HttpClient) {
  }

  getAllBySection(sectionsId: number) {
    return this.http.get<Field[]>(`${environment.apiUrl}/fields/search/${sectionsId}`);
  }

  getAllByDFieldId(tabId: number, groupId: number) {
    const query = queryString.stringify({ tabId, groupId });

    return this.http.get(`${environment.apiUrl}/fields/details?${query}`)
    .pipe(map((res: FieldDetail[]) => {
      return res.map(e => {
        return {
          ...e.field,
          value: e.value,
        };
      }) as Field[];
    }));
  }

  delete(fieldId: number) {
    return this.http.request('delete', `${environment.apiUrl}/fields/${fieldId}`);
  }

  add(payload: FieldRequest) {
    return this.http.post<Field>(`${environment.apiUrl}/fields/`, payload);
  }

  edit(fieldId: number, payload: FieldRequest) {
    return this.http.put<Field>(`${environment.apiUrl}/fields/${fieldId}`, payload);
  }

  patch(fieldId: number, payload: FieldPatchRequest) {
    return this.http.patch<Field>(`${environment.apiUrl}/fields/${fieldId}`, payload);
  }

  updateFieldValue(requests: FieldValueRequest[]){
    const formData = serialize(
      {requests}, {indices: true, dotsForObjectNotation: true}
    );
    return this.http.put<{responses: FieldValue[]}>(`${environment.apiUrl}/field-value`, formData)
      .pipe(
        tap((res) => {
          this.updateFieldValue$.next(res.responses);
        }),
      );
  }

  getFieldValue(searchRequest: DynamicFieldTableRequest, pageable: Pageable) {
    const query = queryString.stringify({...searchRequest, ...pageable});
    return this.http.get<List2Res<Field>>(`${environment.apiUrl}/field-value?${query}`);
  }

  changeOrderField(payload: ChangeOrderFieldRequest) {
    return this.http.put<Field>(`${environment.apiUrl}/fields/change-order`, payload);
  }

  getOptions(fieldId: number, payload: {keyword: string}, pageable: Pageable) {
    const query = queryString.stringify({ ...payload, ...pageable });

    return this.http.get<List2Res<any>>(`${environment.apiUrl}/fields/${fieldId}/options?${query}`);
  }

  linkFieldToSection(id: number, payload: LinkFieldRequest) {
    return this.http.put<Field>(`${environment.apiUrl}/fields/${id}/link`, payload);
  }

  getLinkedSection(id: number) {
    return this.http.get<LinkFieldRequest>(`${environment.apiUrl}/fields/${id}/link`);
  }

  getFields(searchRequest: DynamicFieldSearch, pageable: Pageable) {
    const params = queryString.stringify({...searchRequest, ...pageable});
    return this.http.get<List2Res<Field>>(`${environment.apiUrl}/fields/?${params}`);
  }

  getGenerateVariable(name: string) {
    return this.http.get<VariableResponse>(`${environment.apiUrl}/fields/generate-variable?name=${name}`);
  }

  getArchivedField() {
    return this.http.get<Field[]>(`${environment.apiUrl}/fields/view-archived`);
  }
}
