import { Pipe, PipeTransform } from '@angular/core';
import { ProgressGroupItem } from '../_components/progress-group/progress-group.component';
import { map } from 'lodash';

@Pipe({
  name: 'progressTooltip'
})

export class ProgressTooltipPipe implements PipeTransform {
  transform(items: ProgressGroupItem[]): any {
    return map(items, (e) => {
      if (e.count) {
        return `${e.count} ${e.label}`;
      } 
    }).filter(e => e).join(' ● ');
  }
}