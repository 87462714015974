import { Injectable, Provider } from '@angular/core';
import {
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

@Injectable()
export class MyHammerConfigs extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

export const HammerProvider: Provider = {
  provide: HAMMER_GESTURE_CONFIG,
  useClass: MyHammerConfigs
};
