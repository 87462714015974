import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { List2Res, Pageable, ProjectPriority } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProjectPriorityService {
    public loading$ = new Subject<boolean>();

    constructor(private http: HttpClient) {}

    get(projectId: number, pageable: Pageable): Observable<List2Res<ProjectPriority>> {
        const params = `page=${pageable.page || ''}&size=${pageable.size || ''}&sort=${pageable.sort || ''}`;
        this.loading$.next(true);
        return this.http
            .get<List2Res<ProjectPriority>>(`${environment.apiUrl}/projects/${projectId}/project-priority/?${params}`)
            .pipe(finalize(() => this.loading$.next(false)));
    }

    add(projectId: number, body: { name: string; icon: string, code: string }) {
      return this.http.post(`${environment.apiUrl}/projects/${projectId}/project-priority/`, body);
    }

    edit(projectId: number, priorityId: number, body: { name: string; icon: string, code: string }) {
        return this.http.put(`${environment.apiUrl}/projects/${projectId}/project-priority/${priorityId}`, body);
    }

    changeOrder(
        projectId: number,
        body: {
            priorityId: number;
            prePriorityId: number;
            nextPriorityId: number;
        }
    ) {
        return this.http.put(`${environment.apiUrl}/projects/${projectId}/project-priority/change-order`, body);
    }
}
