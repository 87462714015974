<div class="app-context-menu-wrapper h-100">
  <ng-content></ng-content>
  <div #menu class="context-menu-template custom-dropdown-menu dropdown-menu show"
    [ngStyle]="{'left.px': xPosition, 'top.px': yPosition}"
    *ngIf="isOpen && template"
    clickOutside (onClickOutside)="onClose()">
    <ng-container>
      <ng-container *ngTemplateOutlet="template; context: {state : initialState}"></ng-container>
    </ng-container>
  </div>
</div>
