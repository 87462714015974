import { Group, Role, Team, UserShort, CVShort } from './index';

export interface HiringBoard {
  id: number;
  boardStatuses: HiringBoardStatus[];
  hrManager: HiringBoardMember;
}

export interface HiringBoardPayload {
  fullName?: string;
  key?: string;
  keyword?: string;
  phone?: string;
  jobTitleIds?: number[];
  gender?: string;
  rating?: number;
  archived?: boolean;
  fromDate?: string;
  toDate?: string;
}

export interface HiringBoardStatus {
  id: number;
  name: string;
  colorCode: string;
  icon: string;
  defaultFlg: boolean;
  deleted: boolean;
  order: string;
  isEdit?: boolean;
  numCV: number;
  boardColumn: HiringBoardColumnShort;
  statusCategory: string;
}

export interface HiringBoardStatusShort {
  id: number;
  name: string;
}

export interface HiringBoardColumnShort {
  id: number;
  name: string;
  statuses?: HiringBoardStatus[]
}

export interface HiringBoardMember {
  id: number;
  user: UserShort;
  group: Group;
  team: Team;
  role: Role;
}

export interface CVWithColumn {
  column: HiringBoardColumnShort;
  cvs: CVShort[];
}

export interface HiringBoardMemberPostRequest {
  userIds: number[];
  groupIds: number[];
  teamIds: number[];
  roleId: number;
}

export interface HiringBoardMemberDeleteRequest {
  memberIds: number[];
}

export interface HiringBoardColumn {
  id: number;
  name: string;
  colorCode: string;
  deleted: boolean;
  isSaving?: boolean;
  isOpenAdd?: boolean;
  statuses?: HiringBoardStatus[];
}

export interface OrderColumn {
  columnId: number;
  preColumnId?: number;
  nextColumnId?: number;
}
