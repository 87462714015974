export interface BoardEpic {
  id: number;
  name: string;
  description: string;
  colorCode: string;
  deleted: boolean;
  key: string;
}

export interface BoardEpicSearchRequest {
  keyword?: string;
  projectId: number;
}
