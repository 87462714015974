import {
  Directive,
  ElementRef,
  HostListener,
  NgZone,
  Renderer2
} from '@angular/core';

@Directive({ selector: '[hover-dropdown]' })
export class HoverDropdownDirective {
  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone
  ) {}
  headerHeight = 50;

  @HostListener('mouseenter')
  onMouseEnter() {
    const child = this.element.nativeElement.querySelector('.children-hover');

    if (child) {
      this.zone.runOutsideAngular(() => {
        const children = child?.getBoundingClientRect();
        const parent = this.element.nativeElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        this.renderer.setStyle(child, 'left', `${parent.right - 10}px`);
        // dropdown height > windown height
        if (children.height > windowHeight - this.headerHeight) {
          this.renderer.setStyle(child, 'top', `${this.headerHeight}px`);
          this.renderer.setStyle(child, 'bottom', '0px');
          this.renderer.setStyle(child, 'height', `${windowHeight - this.headerHeight}px`);
          this.renderer.setStyle(child, 'overflow-y', 'auto');
          return;
        }

        // list overflow bottom
        if (parent?.top + children.height > windowHeight) {
          if (parent?.bottom - children.height >= this.headerHeight) {
            this.renderer.setStyle(child, 'bottom', `${Math.max(windowHeight - parent.bottom, 0)}px`);
          }
          else {
            this.renderer.setStyle(child, 'bottom', '0px');
          }
          this.renderer.setStyle(child, 'top', 'unset');
          return;
        }

        // default
        this.renderer.setStyle(child, 'top', `${Math.max(parent?.top,this.headerHeight)}px`);
        this.renderer.setStyle(child, 'bottom', 'unset');
      });
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    const child = this.element.nativeElement.querySelector('.children-hover');

    if (child) {
      this.renderer.setStyle(child, 'bottom', 'unset');
      this.renderer.setStyle(child, 'top', 'unset');
    }
  }
}
