import { BoardStatus } from '..';

export interface BoardColumn {
  id: number;
  name: string;
  colorCode: string;
  deleted: boolean;
  statuses: BoardStatus[];
  isSaving?: boolean;
  isOpenAdd?: boolean;
}

export interface BoardColumnUpsertRequest {
  name: string;
  colorCode?: string;
}
