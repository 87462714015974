import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Field, FieldValue, FieldValueRequest } from '../../../_models/dynamic-field.model';
import { DATE_FORMAT, FieldType } from '../../../_utils';

export interface FieldDataChange {
  field: Field;
  value?: any;
  payload?: FieldValueRequest;
}

@Component({
  selector: 'app-d-field',
  templateUrl: './d-field.component.html',
  styleUrls: ['./d-field.component.scss']
})
export class DFieldComponent implements OnInit, OnChanges {
  @Input() fields: Field[] = [];
  @Input() fieldValues: FieldValue[] = [];
  @Input() hideSystem = false;
  @Output() fieldChange = new EventEmitter<FieldDataChange>();
  @Output() fieldsChange = new EventEmitter<FieldDataChange[]>();
  fieldType = FieldType;
  dataChanges = {};

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldValues?.currentValue?.length > 0) {
      this.fieldValues.forEach(fieldValue => {
        const index = this.fields.findIndex(e => e.id === fieldValue.fieldId);
        if (index !== -1) {
          this.fields[index] = {...this.fields[index], value: fieldValue};
        }
      });
    }
  }

  isInputField(f: Field) {
    return [FieldType.number, FieldType.text].includes(f.type);
  }

  isDateField(f: Field) {
    return [FieldType.datePicker].includes(f.type);
  }

  isDropdownField(f: Field) {
    return [FieldType.select, FieldType.multiSelect].includes(f.type);
  }

  isTableField(f: Field) {
    return [FieldType.table].includes(f.type);
  }

  isFileField(f: Field) {
    return [FieldType.file].includes(f.type);
  }

  onFieldChange(event: FieldDataChange) {
    const payload = this.getFieldValueRequest(event.field.type, event.value);
    this.fieldChange.emit({...event, payload});

    this.dataChanges[event.field.id] = {...event, payload};

    const changes = [];
    Object.keys(this.dataChanges).forEach(key => {
      changes.push({...this.dataChanges[key]});
    });
    if (changes.length > 0) {
      this.fieldsChange.emit(changes);
    }
  }

  getFieldValueRequest(type: FieldType, value: any): FieldValueRequest {
    if (type === FieldType.text) {
      return {
        text: value as string
      };
    }
    if (type === FieldType.number) {
      return {
        number: +value || 0,
      };
    }
    if (type === FieldType.select) {
      return {
        optionIds: value ? [value.id] : [],
      };
    }
    if (type === FieldType.multiSelect) {
      return {
        optionIds: value ? value?.map(e => e.id) : [],
      };
    }
    if (type === FieldType.datePicker) {
      return {
        date: value ? moment(value).format(DATE_FORMAT) : '',
      };
    }
    if (type === FieldType.table) {
      return {
        rows: value || [],
      };
    }
    if (type === FieldType.file) {
      return value;
    }
    return {};
  }

  isShowFieldName(field: Field) {
    return field.type !== FieldType.table;
  }
}
