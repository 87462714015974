import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-epic-colors-dropdown-list',
  templateUrl: './epic-colors-dropdown-list.component.html',
  styleUrls: ['./epic-colors-dropdown-list.component.scss']
})
export class EpicColorsDropdownListComponent {
  @Input() control: UntypedFormControl;
  @Input() insideClick = false;

  selectColor(color: string) {
    this.control.setValue(color);
  }
}
