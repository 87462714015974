import { JobTitleShort } from '..';
import { FragOkrDashBoard, OkrRiskLevel } from '../_utils';

export interface ReportData {
  id: number;
  name: string;
  riskLevels: RiskLevelsReport[];
}

export interface RiskLevelsReport {
  riskLevel: OkrRiskLevel;
  count: number;
}

export interface MetricsReportData extends ExtendsData {
  id: number;
  name: string;
  stats: MetricReportStatus;
  lastMonthStats: MetricReportStatus;
  totalValue: number;
}

export interface ExtendsData {
  percent: number;
  change: number;
}

export interface MetricReportStatus {
  percent: number;
}

export interface CompanyReportData {
  date: string;
  stats: CompanyReportStatus[];
}

export interface CompanyReportStatus {
  total: number;
  riskLevel: OkrRiskLevel;
  count: number;
}

export interface HandledData extends ExtendsData {
  label: string;
  colorCode: string;
  icon: string;
  totalValue: number;
  riskLevel: OkrRiskLevel;
}


export interface HandleOkrReport{
  date: string;
  month: number;
  stats: HandledStatus[];
}

export interface HandledStatus extends ExtendsData {
  label: string;
  colorCode: string;
  icon: string;
  riskLevel: OkrRiskLevel;
  total: number;
}

export interface OkrContributorsReport{
  id: number;
  name: string;
  percent: number;
  total: number;
  avatar: string;
  jobTitles: JobTitleShort[];
}
