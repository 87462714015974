import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(
    private http: HttpClient,
  ) { }

  downloadAttachment(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${environment.apiUrl}/attachment/download?url=${url}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
