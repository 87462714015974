import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core';
import { Field, FieldRequest, FieldValue, FieldValueRequest, RowValue } from 'src/app/shared/_models';
import { UpsertComponent } from '../../../../_base-component/upsert.component';
import { FieldDataChange } from '../../d-field.component';

@Component({
  selector: 'app-d-field-table-row-upsert',
  templateUrl: './d-field-table-row-upsert.component.html',
  styleUrls: ['./d-field-table-row-upsert.component.scss']
})
export class DFieldTableRowUpsertComponent extends UpsertComponent{
  @Input() field: Field;
  @Input() row: RowValue;

  fields: Field[] = [];
  fieldsChange: FieldDataChange[] = [];
  colsRequest: FieldValueRequest[] = [];

  init(): void {
    if (this.row) {
      this.object = this.field;
    }

    this.fields = this.field.columns.map(e => {
      return {
        ...e,
        isVisible: true,
        value: this.row?.columns?.find(c => c.fieldId === e.id) || {id: null},
      } as any;
    });
  }

  onFieldsChange(event: FieldDataChange[]) {
    this.fieldsChange = event;
  }

  add() {
    const newRow = {
      id: null,
      columns: this.fields.map(field => {
        const fieldChange = this.fieldsChange.find(fChange => field.id === fChange?.field?.id);
        return {
          fieldId: field.id,
          ...fieldChange?.payload
        };
      })
    };

    this.objectResponse.next(newRow);
  }

  edit() {
    this.fieldsChange.forEach(change => {
      const cell = this.row.columns.find(col => col.fieldId === change.field.id);
      if (cell) {
        Object.keys(change.payload).forEach((key) => {
          cell[key] = change.payload[key];
        });
      } else {
        const newCell = {id: null, fieldId: change.field.id};
        Object.keys(change.payload).forEach((key) => {
          newCell[key] = change.payload[key];
        });
        this.row.columns.push(newCell);
      }
    });
    this.objectResponse.next(this.row);
  }
}
