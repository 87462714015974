import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appFileDropZone]'
})
export class FileDropZoneDirective {
  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') public background = '#FFFFFF';
  // @HostBinding('style.opacity') public opacity = '1';
  @HostBinding('class.drop-zone-drag-over') public isDragging = false;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragging = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragging = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.isDragging = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }

}
