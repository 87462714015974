import { Component, OnInit } from '@angular/core';
import { startWith, debounceTime } from 'rxjs/operators';
import { BenefitRule } from 'src/app/shared';
import { BenefitRuleService } from 'src/app/site-management/benefit-rule/benefit-rule.service';
import { SearchControlComponent } from '../base/search-base.component';
import * as queryString from 'query-string';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-benefit-rule',
  templateUrl: './search-benefit-rule.component.html',
  styleUrls: ['./search-benefit-rule.component.scss']
})
export class SearchBenefitRuleComponent extends SearchControlComponent<BenefitRule>  implements OnInit {

  placeholder =  this.translateService.instant('Add Benefit/Rule')

  constructor(
    private benefitRuleService: BenefitRuleService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.typing$.pipe(startWith(''), debounceTime(500)).subscribe((res) => {
      this.searchDto['key'] = res;
      this.fetch();
    });
  }

  fetch(): void {
    this.sub?.unsubscribe();
    this.sub = this.benefitRuleService.search(this.searchDto).subscribe((res) => {
      this.items = res.content;
    });
  }

}
