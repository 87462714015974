import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ContextMenuService {
  show$ = new Subject<{
    pointerEvent: PointerEvent,
    template: TemplateRef<any>,
    config?: {
      options?: any,
      initialState?: any,
    }
  }>();

  hide$ = new Subject<boolean>();

  constructor() { }

  show(pointerEvent: PointerEvent, template: TemplateRef<any>, config?: { options?: any, initialState?: any }) {
    pointerEvent.preventDefault();
    pointerEvent.stopPropagation();
    this.show$.next({ pointerEvent, template, config });
  }

  hide() {
    this.hide$.next(null);
  }
}
