import { Component, Renderer2, EventEmitter, Output, Input, ElementRef  } from '@angular/core';
import { AbstractComponent } from '../_base-component';
import { forEach } from 'lodash';
import { UntypedFormControl } from '@angular/forms';
import { TimeFormat, TimeObject } from './models/time-picker.model';
import { TimePicker, timeLimitPerDay12Hours, timeLimitPerHour } from './time-picker';
import { UnitOfTime } from '../../_utils';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent extends AbstractComponent {
  @Input() title: string = 'Time';
  @Input() control: UntypedFormControl;
  @Output() timeChangeValue = new EventEmitter();

  hours = TimePicker.generateNumberSequence(timeLimitPerDay12Hours, UnitOfTime.hours);
  minutes = TimePicker.generateNumberSequence(timeLimitPerHour, UnitOfTime.minutes);
  periodDate = TimePicker.periodDate(UnitOfTime.periods);
  selectedTime: TimeFormat;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    super();
  }

  init() {
    this.updateTimeValue();
  }

  afterViewInit() {
    this.scrollList();
  }

  getSelectedTime(item: TimeObject) {
    if (!item) {
      return;
    }

    switch (item.type) {
      case UnitOfTime.hours:
        return item.value === this.selectedTime.hour;
      case UnitOfTime.minutes:
        return item.value === this.selectedTime.minute;
      case UnitOfTime.periods:
        return item.value === this.selectedTime.period;
      default:
        return false;
    }
  }

  setSelectedTime(item: TimeObject) {
    if (item.type === UnitOfTime.hours) {
      this.selectedTime.hour = item.value;
      return;
    }

    if (item.type === UnitOfTime.minutes) {
      this.selectedTime.minute = item.value;
      return;
    }

    if (item.type === UnitOfTime.periods) {
      this.selectedTime.period = item.value;
      return;
    }
  }

  updateTimeValue() {
    this.selectedTime = TimePicker.setupSelectedTime(this.control);
  }

  scrollToTop(element: HTMLDivElement) {
    if (!element) {
      return;
    }

    const parentElement = this.renderer.parentNode(element);
    const scrollDistance = element.offsetTop - parentElement.offsetTop;
    this.renderer.setProperty(parentElement, 'scrollTop', scrollDistance);
  }

  scrollList() {
    const nodeList = this.el.nativeElement.querySelectorAll('.selected');
    forEach(nodeList, (node) => {
      this.scrollToTop(node);
      if (node?.parentElement) {
        node.parentElement.style.scrollBehavior = 'smooth';
      }
    });
  }

  getTime(element: HTMLDivElement, item: TimeObject) {
    if (!item) {
      return;
    }

    this.setSelectedTime(item);
    this.scrollToTop(element);
    this.emitValue();
  }

  emitValue() {
    this.timeChangeValue.emit(this.selectedTime);
  }
}
