import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LicenseService } from 'src/app/pages/landing-page/_services/license.service';
import { PRICING_DATA_KEY, PRICING_PERIODS_KEY } from '../..';
import { LicensePeriodResponse, LicenseResponse } from '../../_models';
import { pricingModuleDesc } from '../../_utils';

export class PriceInfo extends LicensePeriodResponse {
  value: number;
  label: string;
  discount: number;
  discountText: string;
}

export enum PricingInfoType {
  pricingPage = 'pricingPage',
  signupPage = 'signupPage',
  changePlanPage = 'changePlanPage',
}
@Component({
  selector: 'app-pricing-info',
  templateUrl: './pricing-info.component.html',
  styleUrls: ['./pricing-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PricingInfoComponent implements OnInit {
  @Input() type: PricingInfoType = PricingInfoType.pricingPage;
  @Input() selectedModule: LicenseResponse;
  @Input() selectedPrice: PriceInfo;
  @Input() selectedPriceId: number;
  @Input() applyDate = '';
  @Output() selectPlan = new EventEmitter<{ license: LicenseResponse; licensePeriod: PriceInfo }>();
  @Output() selectPrice = new EventEmitter<PriceInfo>();

  prices: PriceInfo[] = [];
  modules: LicenseResponse[] = [];
  PricingInfoType = PricingInfoType;
  constructor(
    private licenseService: LicenseService,
  ) { }

  ngOnInit(): void {
    this.modules = JSON.parse(localStorage.getItem(PRICING_DATA_KEY) || null) || [];
    this.prices = JSON.parse(localStorage.getItem(PRICING_PERIODS_KEY) || null) || [];

    this.licenseService.getLicense().subscribe(
      (data) => {
        this.modules = data;
        localStorage.setItem(PRICING_DATA_KEY, JSON.stringify(data));
      },
      (error) => {
        this.modules = [];
      }
    );

    this.licenseService.getLicensePeriod().subscribe(
      (data) => {
        this.prices = data.map(e => {
          return {
            ...e,
            value: e.period,
            label: e.period + (e.period > 1 ? ' months' : ' month'),
            discount: e.discount,
            discountText: e.discount > 0 ? `-${e.discount}%` : ''
          };
        });
        if (this.selectedPriceId) {
          this.selectedPrice = this.prices.find(e => e.id === this.selectedPriceId);
        }
        if (!this.selectedPrice) {
          this.selectedPrice = this.prices.find(e => e.value === 1);
        }
        localStorage.setItem(PRICING_PERIODS_KEY, JSON.stringify(this.prices));
      },
      (error) => {
        this.prices = [];
      }
    );
  }

  getModuleDesc(name: string) {
    if (name === pricingModuleDesc.free.name) {
      return pricingModuleDesc.free.desc;
    }

    if (name === pricingModuleDesc.taskManagement.name) {
      return pricingModuleDesc.taskManagement.desc;
    }

    if (name === pricingModuleDesc.companyManagement.name) {
      return pricingModuleDesc.companyManagement.desc;
    }

    if (name === pricingModuleDesc.professional.name) {
      return pricingModuleDesc.professional.desc;
    }

    return '';
  }

  getRate(rate: number) {
    if (rate === 0) {
      return 'FREE';
    }

    return `$${(rate - rate * (this.selectedPrice?.discount || 0) / 100).toFixed(2)}`;
  }

  onSelectModule(license: LicenseResponse) {
    this.selectedModule = license;
    this.selectPlan.emit({ license, licensePeriod: this.selectedPrice });
  }

  onPriceChange() {
    this.selectPrice.emit(this.selectedPrice);
  }

  onClickModuleItem(license: LicenseResponse) {
    if (this.type === 'signupPage') {
      this.onSelectModule(license);
    }
  }

  compareWith(item, selected) {
    return item?.id === selected?.id;
  }

}
