import {
  DATE_FORMAT,
  DocumentTemplate,
  FilterBy,
  FilterItem,
  Group,
  NumberFormat,
  SelectOption,
  Team,
  User,
  UserShort
} from 'src/app/shared';
import { Status } from '../../gantt/model';
import * as moment from 'moment';
import { PayrollSchedule } from './pay-schedule.model';
import { ElementRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JobTitle } from '../../../shared/_models/job-title.model';

export class PayrollSalaryProfile {
  id: number;
  name: string;
  description: string;
  code: string;
  affectedEmployees: number;
  createdAt: string;
  documentTemplate: DocumentTemplate;
}

export class PayrollSalaryProfileRequest {
  name?: string;
  description?: string;
  code?: string;
  templateId?: number;
  affectedEmployees?: number;
}

export interface PayrollSection {
  id: number;
  name: string;
  fields: PayrollField[];
}

export interface PayrollSectionRequest {
  name: string;
  salaryProfileId: number;
}

export interface PayrollField {
  id: number;
  name: string;
  label: string;
  type: PayrollFieldType;
  expression: string;
  description: string;
  variable: string;
  numberFormat: NumberFormat;
  bold: boolean;
  hide: boolean;
  align: PayrollFieldAlign;
}

export enum PayrollFieldAlign {
  LEFT= 'LEFT',
  RIGHT= 'RIGHT',
  CENTER= 'CENTER',
}

export class PayrollFieldAlignItems {
  static get options(): SelectOption<PayrollFieldAlign>[] {
    return Object.keys(PayrollFieldAlign).map((key) => {
      return {
        name: String(PayrollFieldAlign[key]).toLowerCase(),
        value: PayrollFieldAlign[key]
      };
    });
  }
}

export interface PayrollFieldRequest {
  label: string;
  type: PayrollFieldType;
  expression: string;
  description: string;
}

export enum PayrollFieldType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATEPICKER = 'DATEPICKER',
  FX = 'FX'
}

export enum PayrollFieldTypeLabel {
  NUMBER = 'Number',
  TEXT = 'Text',
  DATEPICKER = 'Date picker',
  FX = 'Fx'
}

export class PayrollFieldTypeOptions {
  static get options(): SelectOption<PayrollFieldType>[] {
    return Object.keys(PayrollFieldType).map((key) => {
      return {
        name: PayrollFieldTypeLabel[key],
        value: PayrollFieldType[key]
      };
    });
  }
}

export enum PayrollStatusName {
  New = 'New',
  Partial_Paid = 'Partial Paid',
  Approved = 'Approved',
  Waiting_For_Approval = 'Waiting For Approval',
  Pending = 'Pending',
  Paid = 'Paid'
}

export enum   PayrollStatusKey {
  New = 'New',
  Partial_Paid = 'Partial_Paid',
  Waiting_For_Approval = 'Waiting_For_Approval',
  Approved = 'Approved',
  Pending = 'Pending',
  Paid = 'Paid'
}

export enum PayrollStatusColor {
  New = '#f1a404',
  Partial_Paid = '#207cf3',
  Waiting_For_Approval = '#00c4ff',
  Approved = '#77be79',
  Pending = '#ec4d57',
  Paid = '#207cf3'
}

export interface PayrollStatusOption {
  name: PayrollStatusName;
  value: PayrollStatusKey;
  color: PayrollStatusColor;
}

export class PayrollStatus {
  static get options(): PayrollStatusOption[] {
    return Object.keys(PayrollStatusKey).map((key) => {
      return {
        name: PayrollStatusName[key],
        value: PayrollStatusKey[key],
        color: PayrollStatusColor[key]
      };
    });
  }
}

export interface PayrollModel {
  id: number;
  name: string;
  start: string;
  end: string;
  secondStart: string;
  secondEnd: string;
  userAppovers: UserShort[];
  status: PayrollStatusKey;
  paydate: string;
  salaryProfile: PayrollSalaryProfile;
  payrollSalaryProfile: PayrollSalaryProfile;
  schedule: PayrollSchedule;
  approvers?: any[];
  employees?: any[];
  groups?: any[];
  teams?: any[];
}

export class PayrollModelRequest {
  keyword: string;
  salaryProfileIds: number[];
  payrollScheduleIds: number[];
  approverIds: number[];
  statuses: PayrollStatusKey[];
  timeRequest: PayrollTimeRequest[];

  constructor(obj?: PayrollModelRequest) {
    this.keyword = obj?.keyword || '';
    this.salaryProfileIds = obj?.salaryProfileIds || [];
    this.payrollScheduleIds = obj?.payrollScheduleIds || [];
    this.approverIds = obj?.approverIds || [];
    this.statuses = obj?.statuses || [];
    this.timeRequest = obj?.timeRequest || [];
  }
}

export interface PayrollTimeRequest {
  fromDate: string;
  toDate: string;
}

export class PayRollFilterBy extends FilterBy {
  salaryProfile?: FilterItem<PayrollSalaryProfile>;
  paySchedule?: FilterItem<PayrollSchedule>;
  approver?: FilterItem<User>;
}

// detail

export interface PayrollUserShort extends UserShort {
  jobTitles: { id: number, title: string }[];
}

export interface PayrollDetail {
  paid: boolean;
  user: PayrollUserShort;
  sections: { [key: string]: PayrollDetailSection };
}

export interface PayrollDetailSection {
  id: number;
  name: string;
  fields: PayrollDetailField[];
}

export interface PayrollDetailField {
  id: number;
  name: string;
  label: string;
  value: string | number;
  type: PayrollFieldType;

  // FE
  numberFormat?: NumberFormat;
  bold?: boolean;
  hide?: boolean;
  align?: PayrollFieldAlign;
  isNumber?: boolean;
  isDate?: boolean;
}

export interface PayrollTableHeader {
  [key: string]: {
    columns: { label: string; id : number; }[];
    isLast: boolean;
  }
}

export const SECTION_FIELD_WIDTH = 150;

export interface PayrollPatchRequest {
  type: PayrollPatchType;
  value: PayrollStatusKey;
}

export enum PayrollPatchType {
  Status = 'Status'
}

export interface PayrollChangeFieldRequest {
  userId: number;
  fieldId: number;
  value: string;
}

export interface PayrollSelectedField {
  userId: number;
  field: PayrollDetailField;
}

export interface PayloadEmployees {
  userIds: number[];
}

export interface SalaryProfileVariable {
  tables: {
    [key: string]: string[];
  };
  variables: SystemVariable[];
};

export interface SystemVariable {
  id: number;
  name: string;
  description?: string;
}


export interface PayrollDetailPayload {
  keyword: string;
  groupIds: number[];
  teamIds: number[];
  employeeIds: number[];
  paid: boolean;
}

export interface PayrollInfo {
  id: number;
  status: PayrollStatusKey;
  name: string;
  approves: UserShort[];
  employees: UserShort[];
  groups: Group[];
  teams: Team[];
}

export interface PayrollInfoPayload {
  id: number;
  status: PayrollStatusKey;
  name: string;
  approvesId: number[],
  employeesId: number[],
  groupId: number[],
  teamId: number[],
}

export const START_OF_MONTH = 1;

export interface InputExpression {
  element: ElementRef;
  control: AbstractControl;
}

export interface PaymentPayload {
  userId: number,
  paid: boolean
}

export interface PaymentAllPayload {
  toggleUsers: boolean;
}

export interface PayrollPeriod {
  month: string;
  fromDate:  string;
  toDate: string;
}

export interface PayslipPayload {
  userIds: number[];
  payrollId: number;
}

export interface PaymentRespone {
  toggleAll: boolean;
}

export interface VariableResponse {
  variable: string;
}

export const NUMBER_DATE_OF_MONTH = 31;
