<ng-select
  [formControl]="control"
  [items]="items"
  [searchable]="searchable"
  [multiple]="multiple"
  [typeahead]="typeahead"
  [disabled]="disabled"
  class="app-user-dropdown"
>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div
      class="ng-value"
      *ngFor="let item of items.slice(0, limitShow)"
      stopEvent
    >
      <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true"
        ><span class="material-icons-outlined icon-ssm">clear</span></span
      >
      <span class="mx-1 custom-option">
        <app-avatar
          [url]="item?.avatar"
          [name]="item?.fullName"
          size="small"
        ></app-avatar>
        <span class="option-label">{{ item?.fullName }}</span>
      </span>
    </div>
    <div class="ng-value px-1" *ngIf="items?.length > limitShow">
      <div class="m-auto">+{{ items?.length - limitShow }}</div>
    </div>
  </ng-template>
  <ng-template ng-label-tmp ng-option-tmp  let-item="item">
    <span class="custom-option">
      <app-avatar
      [url]="item?.avatar"
      [name]="item?.fullName"
    ></app-avatar>
      <span class="option-label">{{ item?.fullName }}</span>
    </span>
  </ng-template>
</ng-select>
