<div class="emoji-comment-wrapper">
  <div class="emoji-list">
    <ng-container *ngFor="let emoji of emojiLists">
      <div class="emoji-item" *ngIf="getTotalEmoji(emoji) > 0">
        <div class="emoji-item-wrapper"
          (mouseenter)="showUsersDropdown(dropdownTrigger, emojiComment[emoji].users)"
          (mouseleave)="hideUsersDropdown()"
          (click)="addEmoji(emoji)"
          #dropdownTrigger
          [ngClass]="{'active': isMyEmoji(emoji)}">
          <app-emoji-preview [selectedEmoji]="emoji"></app-emoji-preview>
          <span class="ml-1">{{getTotalEmoji(emoji)}}</span>
        </div>
      </div>
    </ng-container>
    <div class="add-emoji"
      cdkOverlayOrigin
      #pickerTrigger="cdkOverlayOrigin"
      (click)="showEmojiPicker = !showEmojiPicker"
      *ngIf="emojiLists.length <= MAXIMUM_EMOJI_AMOUNT">
      <mat-icon [svgIcon]="'add-emoji'" class="icon-md"></mat-icon>
    </div>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="pickerTrigger"
    [cdkConnectedOverlayOpen]="showEmojiPicker"
    (overlayOutsideClick)="showEmojiPicker = false">
    <app-emoji-picker (emojiSelect)="addEmoji($event)"></app-emoji-picker>
  </ng-template>
</div> 
