export interface BoardLabel {
  id: number;
  name: string;
  colorCode: string;
  deleted: boolean;
}

export interface BoardLabelSearchRequest {
  keyword?: string;
  projectId: number;
}
