import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { pickerColors } from '../..';

@Component({
  selector: 'app-color-picker-form',
  templateUrl: './color-picker-form.component.html',
  styleUrls: ['./color-picker-form.component.scss']
})
export class ColorPickerFormComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() size = 18;
  @Input() colors: string[] = pickerColors;
  colorCode = '';

  constructor() { }

  ngOnInit(): void {
    this.colorCode = this.control.value || '';
  }

  onColorChange(colorCode: string) {
    this.colorCode = colorCode;
    this.control.setValue(colorCode);
  }

}
