// Generated from src/assets/antlr/FieldExpression.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { FieldExpressionListener } from "./FieldExpressionListener";
import { FieldExpressionVisitor } from "./FieldExpressionVisitor";


export class FieldExpressionParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly QUOTE_TEXT = 17;
	public static readonly VARIABLE = 18;
	public static readonly NUMBER = 19;
	public static readonly PROPERTY_VAR = 20;
	public static readonly LPAREN = 21;
	public static readonly RPAREN = 22;
	public static readonly COMMA = 23;
	public static readonly PLUS = 24;
	public static readonly MINUS = 25;
	public static readonly MULTI = 26;
	public static readonly DIV = 27;
	public static readonly POW = 28;
	public static readonly GT = 29;
	public static readonly LT = 30;
	public static readonly GTE = 31;
	public static readonly LTE = 32;
	public static readonly EQ = 33;
	public static readonly NEQ = 34;
	public static readonly WS = 35;
	public static readonly RULE_expression = 0;
	public static readonly RULE_function = 1;
	public static readonly RULE_logic_operator = 2;
	public static readonly RULE_and = 3;
	public static readonly RULE_or = 4;
	public static readonly RULE_not = 5;
	public static readonly RULE_compare_operator = 6;
	public static readonly RULE_operand = 7;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"expression", "function", "logic_operator", "and", "or", "not", "compare_operator", 
		"operand",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'IF'", "'if'", "'sum'", "'SUM'", "'min'", "'MIN'", "'max'", 
		"'MAX'", "'avg'", "'AVG'", "'and'", "'AND'", "'or'", "'OR'", "'not'", 
		"'NOT'", undefined, undefined, undefined, undefined, "'('", "')'", "','", 
		"'+'", "'-'", "'*'", "'/'", "'^'", "'>'", "'<'", "'<='", "'>='", "'='", 
		"'!='",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, "QUOTE_TEXT", "VARIABLE", "NUMBER", "PROPERTY_VAR", 
		"LPAREN", "RPAREN", "COMMA", "PLUS", "MINUS", "MULTI", "DIV", "POW", "GT", 
		"LT", "GTE", "LTE", "EQ", "NEQ", "WS",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(FieldExpressionParser._LITERAL_NAMES, FieldExpressionParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return FieldExpressionParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "FieldExpression.g4"; }

	// @Override
	public get ruleNames(): string[] { return FieldExpressionParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return FieldExpressionParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(FieldExpressionParser._ATN, this);
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 0;
		this.enterRecursionRule(_localctx, 0, FieldExpressionParser.RULE_expression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 62;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 5, this._ctx) ) {
			case 1:
				{
				_localctx = new PARENContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 18;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === FieldExpressionParser.PLUS || _la === FieldExpressionParser.MINUS) {
					{
					this.state = 17;
					(_localctx as PARENContext)._sign = this._input.LT(1);
					_la = this._input.LA(1);
					if (!(_la === FieldExpressionParser.PLUS || _la === FieldExpressionParser.MINUS)) {
						(_localctx as PARENContext)._sign = this._errHandler.recoverInline(this);
					} else {
						if (this._input.LA(1) === Token.EOF) {
							this.matchedEOF = true;
						}

						this._errHandler.reportMatch(this);
						this.consume();
					}
					}
				}

				this.state = 20;
				this.match(FieldExpressionParser.LPAREN);
				this.state = 21;
				this.expression(0);
				this.state = 22;
				this.match(FieldExpressionParser.RPAREN);
				}
				break;

			case 2:
				{
				_localctx = new AggrContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 24;
				this.function();
				this.state = 25;
				this.match(FieldExpressionParser.LPAREN);
				this.state = 34;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if ((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << FieldExpressionParser.T__0) | (1 << FieldExpressionParser.T__1) | (1 << FieldExpressionParser.T__2) | (1 << FieldExpressionParser.T__3) | (1 << FieldExpressionParser.T__4) | (1 << FieldExpressionParser.T__5) | (1 << FieldExpressionParser.T__6) | (1 << FieldExpressionParser.T__7) | (1 << FieldExpressionParser.T__8) | (1 << FieldExpressionParser.T__9) | (1 << FieldExpressionParser.QUOTE_TEXT) | (1 << FieldExpressionParser.VARIABLE) | (1 << FieldExpressionParser.NUMBER) | (1 << FieldExpressionParser.PROPERTY_VAR) | (1 << FieldExpressionParser.LPAREN) | (1 << FieldExpressionParser.PLUS) | (1 << FieldExpressionParser.MINUS))) !== 0)) {
					{
					this.state = 26;
					this.expression(0);
					this.state = 31;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					while (_la === FieldExpressionParser.COMMA) {
						{
						{
						this.state = 27;
						this.match(FieldExpressionParser.COMMA);
						this.state = 28;
						this.expression(0);
						}
						}
						this.state = 33;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					}
					}
				}

				this.state = 36;
				this.match(FieldExpressionParser.RPAREN);
				}
				break;

			case 3:
				{
				_localctx = new ConditionContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 38;
				_la = this._input.LA(1);
				if (!(_la === FieldExpressionParser.T__0 || _la === FieldExpressionParser.T__1)) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				this.state = 39;
				this.match(FieldExpressionParser.LPAREN);
				this.state = 40;
				(_localctx as ConditionContext)._cond = this.expression(0);
				this.state = 41;
				this.match(FieldExpressionParser.COMMA);
				this.state = 42;
				(_localctx as ConditionContext)._trueCond = this.expression(0);
				this.state = 43;
				this.match(FieldExpressionParser.COMMA);
				this.state = 44;
				(_localctx as ConditionContext)._falseCond = this.expression(0);
				this.state = 45;
				this.match(FieldExpressionParser.RPAREN);
				}
				break;

			case 4:
				{
				_localctx = new TextContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 47;
				this.match(FieldExpressionParser.QUOTE_TEXT);
				this.state = 52;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
				while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						{
						{
						this.state = 48;
						this.match(FieldExpressionParser.PLUS);
						this.state = 49;
						this.match(FieldExpressionParser.QUOTE_TEXT);
						}
						}
					}
					this.state = 54;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
				}
				}
				break;

			case 5:
				{
				_localctx = new ATOMContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 58;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === FieldExpressionParser.PLUS || _la === FieldExpressionParser.MINUS) {
					{
					{
					this.state = 55;
					_la = this._input.LA(1);
					if (!(_la === FieldExpressionParser.PLUS || _la === FieldExpressionParser.MINUS)) {
					this._errHandler.recoverInline(this);
					} else {
						if (this._input.LA(1) === Token.EOF) {
							this.matchedEOF = true;
						}

						this._errHandler.reportMatch(this);
						this.consume();
					}
					}
					}
					this.state = 60;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 61;
				this.operand();
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 83;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 81;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 6, this._ctx) ) {
					case 1:
						{
						_localctx = new PowContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as PowContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, FieldExpressionParser.RULE_expression);
						this.state = 64;
						if (!(this.precpred(this._ctx, 10))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 10)");
						}
						this.state = 65;
						this.match(FieldExpressionParser.POW);
						this.state = 66;
						(_localctx as PowContext)._right = this.expression(11);
						}
						break;

					case 2:
						{
						_localctx = new Multi_DivContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Multi_DivContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, FieldExpressionParser.RULE_expression);
						this.state = 67;
						if (!(this.precpred(this._ctx, 9))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 9)");
						}
						this.state = 68;
						(_localctx as Multi_DivContext)._op = this._input.LT(1);
						_la = this._input.LA(1);
						if (!(_la === FieldExpressionParser.MULTI || _la === FieldExpressionParser.DIV)) {
							(_localctx as Multi_DivContext)._op = this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 69;
						(_localctx as Multi_DivContext)._right = this.expression(10);
						}
						break;

					case 3:
						{
						_localctx = new Plus_MinusContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Plus_MinusContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, FieldExpressionParser.RULE_expression);
						this.state = 70;
						if (!(this.precpred(this._ctx, 8))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 8)");
						}
						this.state = 71;
						(_localctx as Plus_MinusContext)._op = this._input.LT(1);
						_la = this._input.LA(1);
						if (!(_la === FieldExpressionParser.PLUS || _la === FieldExpressionParser.MINUS)) {
							(_localctx as Plus_MinusContext)._op = this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 72;
						(_localctx as Plus_MinusContext)._right = this.expression(9);
						}
						break;

					case 4:
						{
						_localctx = new CompareContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as CompareContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, FieldExpressionParser.RULE_expression);
						this.state = 73;
						if (!(this.precpred(this._ctx, 5))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 5)");
						}
						this.state = 74;
						(_localctx as CompareContext)._op = this.compare_operator();
						this.state = 75;
						(_localctx as CompareContext)._right = this.expression(6);
						}
						break;

					case 5:
						{
						_localctx = new LogicalContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as LogicalContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, FieldExpressionParser.RULE_expression);
						this.state = 77;
						if (!(this.precpred(this._ctx, 4))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
						}
						this.state = 78;
						(_localctx as LogicalContext)._op = this.logic_operator();
						this.state = 79;
						(_localctx as LogicalContext)._right = this.expression(5);
						}
						break;
					}
					}
				}
				this.state = 85;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 7, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public function(): FunctionContext {
		let _localctx: FunctionContext = new FunctionContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, FieldExpressionParser.RULE_function);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 86;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << FieldExpressionParser.T__2) | (1 << FieldExpressionParser.T__3) | (1 << FieldExpressionParser.T__4) | (1 << FieldExpressionParser.T__5) | (1 << FieldExpressionParser.T__6) | (1 << FieldExpressionParser.T__7) | (1 << FieldExpressionParser.T__8) | (1 << FieldExpressionParser.T__9))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public logic_operator(): Logic_operatorContext {
		let _localctx: Logic_operatorContext = new Logic_operatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, FieldExpressionParser.RULE_logic_operator);
		try {
			this.state = 91;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case FieldExpressionParser.T__10:
			case FieldExpressionParser.T__11:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 88;
				this.and();
				}
				break;
			case FieldExpressionParser.T__12:
			case FieldExpressionParser.T__13:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 89;
				this.or();
				}
				break;
			case FieldExpressionParser.T__14:
			case FieldExpressionParser.T__15:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 90;
				this.not();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public and(): AndContext {
		let _localctx: AndContext = new AndContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, FieldExpressionParser.RULE_and);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 93;
			_la = this._input.LA(1);
			if (!(_la === FieldExpressionParser.T__10 || _la === FieldExpressionParser.T__11)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public or(): OrContext {
		let _localctx: OrContext = new OrContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, FieldExpressionParser.RULE_or);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 95;
			_la = this._input.LA(1);
			if (!(_la === FieldExpressionParser.T__12 || _la === FieldExpressionParser.T__13)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public not(): NotContext {
		let _localctx: NotContext = new NotContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, FieldExpressionParser.RULE_not);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 97;
			_la = this._input.LA(1);
			if (!(_la === FieldExpressionParser.T__14 || _la === FieldExpressionParser.T__15)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public compare_operator(): Compare_operatorContext {
		let _localctx: Compare_operatorContext = new Compare_operatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, FieldExpressionParser.RULE_compare_operator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 99;
			_la = this._input.LA(1);
			if (!(((((_la - 29)) & ~0x1F) === 0 && ((1 << (_la - 29)) & ((1 << (FieldExpressionParser.GT - 29)) | (1 << (FieldExpressionParser.LT - 29)) | (1 << (FieldExpressionParser.GTE - 29)) | (1 << (FieldExpressionParser.LTE - 29)) | (1 << (FieldExpressionParser.EQ - 29)) | (1 << (FieldExpressionParser.NEQ - 29)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public operand(): OperandContext {
		let _localctx: OperandContext = new OperandContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, FieldExpressionParser.RULE_operand);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 101;
			_localctx._type = this._input.LT(1);
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << FieldExpressionParser.VARIABLE) | (1 << FieldExpressionParser.NUMBER) | (1 << FieldExpressionParser.PROPERTY_VAR))) !== 0))) {
				_localctx._type = this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 0:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 10);

		case 1:
			return this.precpred(this._ctx, 9);

		case 2:
			return this.precpred(this._ctx, 8);

		case 3:
			return this.precpred(this._ctx, 5);

		case 4:
			return this.precpred(this._ctx, 4);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03%j\x04\x02\t\x02" +
		"\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07\t\x07" +
		"\x04\b\t\b\x04\t\t\t\x03\x02\x03\x02\x05\x02\x15\n\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02 \n\x02" +
		"\f\x02\x0E\x02#\v\x02\x05\x02%\n\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03" +
		"\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03" +
		"\x02\x07\x025\n\x02\f\x02\x0E\x028\v\x02\x03\x02\x07\x02;\n\x02\f\x02" +
		"\x0E\x02>\v\x02\x03\x02\x05\x02A\n\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02T\n\x02\f\x02\x0E\x02W\v\x02\x03" +
		"\x03\x03\x03\x03\x04\x03\x04\x03\x04\x05\x04^\n\x04\x03\x05\x03\x05\x03" +
		"\x06\x03\x06\x03\x07\x03\x07\x03\b\x03\b\x03\t\x03\t\x03\t\x02\x02\x03" +
		"\x02\n\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x02\v" +
		"\x03\x02\x1A\x1B\x03\x02\x03\x04\x03\x02\x1C\x1D\x03\x02\x05\f\x03\x02" +
		"\r\x0E\x03\x02\x0F\x10\x03\x02\x11\x12\x03\x02\x1F$\x03\x02\x14\x16\x02" +
		"q\x02@\x03\x02\x02\x02\x04X\x03\x02\x02\x02\x06]\x03\x02\x02\x02\b_\x03" +
		"\x02\x02\x02\na\x03\x02\x02\x02\fc\x03\x02\x02\x02\x0Ee\x03\x02\x02\x02" +
		"\x10g\x03\x02\x02\x02\x12\x14\b\x02\x01\x02\x13\x15\t\x02\x02\x02\x14" +
		"\x13\x03\x02\x02\x02\x14\x15\x03\x02\x02\x02\x15\x16\x03\x02\x02\x02\x16" +
		"\x17\x07\x17\x02\x02\x17\x18\x05\x02\x02\x02\x18\x19\x07\x18\x02\x02\x19" +
		"A\x03\x02\x02\x02\x1A\x1B\x05\x04\x03\x02\x1B$\x07\x17\x02\x02\x1C!\x05" +
		"\x02\x02\x02\x1D\x1E\x07\x19\x02\x02\x1E \x05\x02\x02\x02\x1F\x1D\x03" +
		"\x02\x02\x02 #\x03\x02\x02\x02!\x1F\x03\x02\x02\x02!\"\x03\x02\x02\x02" +
		"\"%\x03\x02\x02\x02#!\x03\x02\x02\x02$\x1C\x03\x02\x02\x02$%\x03\x02\x02" +
		"\x02%&\x03\x02\x02\x02&\'\x07\x18\x02\x02\'A\x03\x02\x02\x02()\t\x03\x02" +
		"\x02)*\x07\x17\x02\x02*+\x05\x02\x02\x02+,\x07\x19\x02\x02,-\x05\x02\x02" +
		"\x02-.\x07\x19\x02\x02./\x05\x02\x02\x02/0\x07\x18\x02\x020A\x03\x02\x02" +
		"\x0216\x07\x13\x02\x0223\x07\x1A\x02\x0235\x07\x13\x02\x0242\x03\x02\x02" +
		"\x0258\x03\x02\x02\x0264\x03\x02\x02\x0267\x03\x02\x02\x027A\x03\x02\x02" +
		"\x0286\x03\x02\x02\x029;\t\x02\x02\x02:9\x03\x02\x02\x02;>\x03\x02\x02" +
		"\x02<:\x03\x02\x02\x02<=\x03\x02\x02\x02=?\x03\x02\x02\x02><\x03\x02\x02" +
		"\x02?A\x05\x10\t\x02@\x12\x03\x02\x02\x02@\x1A\x03\x02\x02\x02@(\x03\x02" +
		"\x02\x02@1\x03\x02\x02\x02@<\x03\x02\x02\x02AU\x03\x02\x02\x02BC\f\f\x02" +
		"\x02CD\x07\x1E\x02\x02DT\x05\x02\x02\rEF\f\v\x02\x02FG\t\x04\x02\x02G" +
		"T\x05\x02\x02\fHI\f\n\x02\x02IJ\t\x02\x02\x02JT\x05\x02\x02\vKL\f\x07" +
		"\x02\x02LM\x05\x0E\b\x02MN\x05\x02\x02\bNT\x03\x02\x02\x02OP\f\x06\x02" +
		"\x02PQ\x05\x06\x04\x02QR\x05\x02\x02\x07RT\x03\x02\x02\x02SB\x03\x02\x02" +
		"\x02SE\x03\x02\x02\x02SH\x03\x02\x02\x02SK\x03\x02\x02\x02SO\x03\x02\x02" +
		"\x02TW\x03\x02\x02\x02US\x03\x02\x02\x02UV\x03\x02\x02\x02V\x03\x03\x02" +
		"\x02\x02WU\x03\x02\x02\x02XY\t\x05\x02\x02Y\x05\x03\x02\x02\x02Z^\x05" +
		"\b\x05\x02[^\x05\n\x06\x02\\^\x05\f\x07\x02]Z\x03\x02\x02\x02][\x03\x02" +
		"\x02\x02]\\\x03\x02\x02\x02^\x07\x03\x02\x02\x02_`\t\x06\x02\x02`\t\x03" +
		"\x02\x02\x02ab\t\x07\x02\x02b\v\x03\x02\x02\x02cd\t\b\x02\x02d\r\x03\x02" +
		"\x02\x02ef\t\t\x02\x02f\x0F\x03\x02\x02\x02gh\t\n\x02\x02h\x11\x03\x02" +
		"\x02\x02\v\x14!$6<@SU]";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!FieldExpressionParser.__ATN) {
			FieldExpressionParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(FieldExpressionParser._serializedATN));
		}

		return FieldExpressionParser.__ATN;
	}

}

export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class PowContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public POW(): TerminalNode { return this.getToken(FieldExpressionParser.POW, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterPow) {
			listener.enterPow(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitPow) {
			listener.exitPow(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitPow) {
			return visitor.visitPow(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Multi_DivContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _op!: Token;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public MULTI(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.MULTI, 0); }
	public DIV(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.DIV, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterMulti_Div) {
			listener.enterMulti_Div(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitMulti_Div) {
			listener.exitMulti_Div(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitMulti_Div) {
			return visitor.visitMulti_Div(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Plus_MinusContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _op!: Token;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public PLUS(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.PLUS, 0); }
	public MINUS(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.MINUS, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterPlus_Minus) {
			listener.enterPlus_Minus(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitPlus_Minus) {
			listener.exitPlus_Minus(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitPlus_Minus) {
			return visitor.visitPlus_Minus(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class PARENContext extends ExpressionContext {
	public _sign!: Token;
	public LPAREN(): TerminalNode { return this.getToken(FieldExpressionParser.LPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public RPAREN(): TerminalNode { return this.getToken(FieldExpressionParser.RPAREN, 0); }
	public PLUS(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.PLUS, 0); }
	public MINUS(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.MINUS, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterPAREN) {
			listener.enterPAREN(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitPAREN) {
			listener.exitPAREN(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitPAREN) {
			return visitor.visitPAREN(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class AggrContext extends ExpressionContext {
	public function(): FunctionContext {
		return this.getRuleContext(0, FunctionContext);
	}
	public LPAREN(): TerminalNode { return this.getToken(FieldExpressionParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(FieldExpressionParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(FieldExpressionParser.COMMA);
		} else {
			return this.getToken(FieldExpressionParser.COMMA, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterAggr) {
			listener.enterAggr(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitAggr) {
			listener.exitAggr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitAggr) {
			return visitor.visitAggr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class CompareContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _op!: Compare_operatorContext;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public compare_operator(): Compare_operatorContext {
		return this.getRuleContext(0, Compare_operatorContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterCompare) {
			listener.enterCompare(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitCompare) {
			listener.exitCompare(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitCompare) {
			return visitor.visitCompare(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class LogicalContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _op!: Logic_operatorContext;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public logic_operator(): Logic_operatorContext {
		return this.getRuleContext(0, Logic_operatorContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterLogical) {
			listener.enterLogical(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitLogical) {
			listener.exitLogical(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitLogical) {
			return visitor.visitLogical(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ConditionContext extends ExpressionContext {
	public _cond!: ExpressionContext;
	public _trueCond!: ExpressionContext;
	public _falseCond!: ExpressionContext;
	public LPAREN(): TerminalNode { return this.getToken(FieldExpressionParser.LPAREN, 0); }
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(FieldExpressionParser.COMMA);
		} else {
			return this.getToken(FieldExpressionParser.COMMA, i);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(FieldExpressionParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterCondition) {
			listener.enterCondition(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitCondition) {
			listener.exitCondition(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitCondition) {
			return visitor.visitCondition(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class TextContext extends ExpressionContext {
	public QUOTE_TEXT(): TerminalNode[];
	public QUOTE_TEXT(i: number): TerminalNode;
	public QUOTE_TEXT(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(FieldExpressionParser.QUOTE_TEXT);
		} else {
			return this.getToken(FieldExpressionParser.QUOTE_TEXT, i);
		}
	}
	public PLUS(): TerminalNode[];
	public PLUS(i: number): TerminalNode;
	public PLUS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(FieldExpressionParser.PLUS);
		} else {
			return this.getToken(FieldExpressionParser.PLUS, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterText) {
			listener.enterText(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitText) {
			listener.exitText(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitText) {
			return visitor.visitText(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ATOMContext extends ExpressionContext {
	public operand(): OperandContext {
		return this.getRuleContext(0, OperandContext);
	}
	public PLUS(): TerminalNode[];
	public PLUS(i: number): TerminalNode;
	public PLUS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(FieldExpressionParser.PLUS);
		} else {
			return this.getToken(FieldExpressionParser.PLUS, i);
		}
	}
	public MINUS(): TerminalNode[];
	public MINUS(i: number): TerminalNode;
	public MINUS(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(FieldExpressionParser.MINUS);
		} else {
			return this.getToken(FieldExpressionParser.MINUS, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterATOM) {
			listener.enterATOM(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitATOM) {
			listener.exitATOM(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitATOM) {
			return visitor.visitATOM(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FunctionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_function; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterFunction) {
			listener.enterFunction(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitFunction) {
			listener.exitFunction(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitFunction) {
			return visitor.visitFunction(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Logic_operatorContext extends ParserRuleContext {
	public and(): AndContext | undefined {
		return this.tryGetRuleContext(0, AndContext);
	}
	public or(): OrContext | undefined {
		return this.tryGetRuleContext(0, OrContext);
	}
	public not(): NotContext | undefined {
		return this.tryGetRuleContext(0, NotContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_logic_operator; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterLogic_operator) {
			listener.enterLogic_operator(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitLogic_operator) {
			listener.exitLogic_operator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitLogic_operator) {
			return visitor.visitLogic_operator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AndContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_and; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterAnd) {
			listener.enterAnd(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitAnd) {
			listener.exitAnd(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitAnd) {
			return visitor.visitAnd(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OrContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_or; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterOr) {
			listener.enterOr(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitOr) {
			listener.exitOr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitOr) {
			return visitor.visitOr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class NotContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_not; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterNot) {
			listener.enterNot(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitNot) {
			listener.exitNot(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitNot) {
			return visitor.visitNot(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Compare_operatorContext extends ParserRuleContext {
	public GT(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.GT, 0); }
	public LT(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.LT, 0); }
	public GTE(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.GTE, 0); }
	public LTE(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.LTE, 0); }
	public EQ(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.EQ, 0); }
	public NEQ(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.NEQ, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_compare_operator; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterCompare_operator) {
			listener.enterCompare_operator(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitCompare_operator) {
			listener.exitCompare_operator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitCompare_operator) {
			return visitor.visitCompare_operator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OperandContext extends ParserRuleContext {
	public _type!: Token;
	public VARIABLE(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.VARIABLE, 0); }
	public PROPERTY_VAR(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.PROPERTY_VAR, 0); }
	public NUMBER(): TerminalNode | undefined { return this.tryGetToken(FieldExpressionParser.NUMBER, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return FieldExpressionParser.RULE_operand; }
	// @Override
	public enterRule(listener: FieldExpressionListener): void {
		if (listener.enterOperand) {
			listener.enterOperand(this);
		}
	}
	// @Override
	public exitRule(listener: FieldExpressionListener): void {
		if (listener.exitOperand) {
			listener.exitOperand(this);
		}
	}
	// @Override
	public accept<Result>(visitor: FieldExpressionVisitor<Result>): Result {
		if (visitor.visitOperand) {
			return visitor.visitOperand(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


