import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { List2Res } from 'src/app/shared';
import { NotificationRequest, NotificationShort } from 'src/app/shared/_models/notification.model';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  constructor(
    private http: HttpClient,
  ) {}

  getNotifications(userId: number, searchParams: any) {
    const params = queryString.stringify(searchParams);
    return this.http.get<List2Res<NotificationShort>>(`${environment.apiUrl}/notifications?${params}`);
  }

  deleteNotification(notificationId: number) {
    return this.http.request<NotificationShort>('delete', `${environment.apiUrl}/notifications/${notificationId}`);
  }

  markAsRead(notificationId: number, payload: NotificationRequest) {
    return this.http.patch<NotificationShort>(`${environment.apiUrl}/notifications/${notificationId}`, payload);
  }

  markAllAsRead(userId: number) {
    return this.http.post(`${environment.apiUrl}/users/${userId}/mark-notifications-read`, '');
  }
}
