<div class="share-target-wrapper" [formGroup]="form">
  <div class="item share-target mr-2">
    <ng-select
      class="w-100"
      formControlName="target"
      [placeholder]="'addOthers' | translate"
      [items]="shareTargets"
      [searchable]="true"
      [clearable]="true"
      [multiple]="true"
      [typeahead]="userTypeahead$">

      <ng-template ng-option-tmp let-item="item">
        <div class="custom-option">
          <app-avatar [url]="item.objectAvatar" [name]="item?.objectName" *ngIf="item?.objectType === SharingType.user"></app-avatar>
          <img class="option-icon" src="../../../../assets/icons/documents/ic_team_blue.png" alt="" *ngIf="item?.objectType === SharingType.team">
          <img class="option-icon" src="../../../../assets/icons/documents/ic_group_blue.png" alt="" *ngIf="item?.objectType === SharingType.group">

          <span class="option-label">{{item?.objectName}}</span>
        </div>
      </ng-template>

      <ng-template ng-label-tmp let-item="item">
        <div class="custom-option">
          <div class="label-wrapper px-1">
            <app-avatar [url]="item.objectAvatar" [name]="item?.objectName" size="small" *ngIf="item?.objectType === SharingType.user"></app-avatar>
            <img class="option-icon" src="../../../../assets/icons/documents/ic_team_blue.png" alt="" *ngIf="item?.objectType === SharingType.team">
            <img class="option-icon" src="../../../../assets/icons/documents/ic_group_blue.png" alt="" *ngIf="item?.objectType === SharingType.group">
  
            <span class="option-label">{{item?.objectName}}</span>
          </div>
           <div class="button-item-clear pointer px-1" (click)="removeItem(item)">
             <span class="material-icons-outlined" role="button"> clear </span>
           </div>
        </div>
      </ng-template>
    </ng-select>
  </div>

  <ng-select class="item share-type mx-2"
    formControlName="permission"
    [items]="sharePermissions"
    [searchable]="false"
    [clearable]="false">
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      <app-i18n [key]="item"></app-i18n>
    </ng-template>
  </ng-select>

  <div class="item action">
    <button type="submit"
      class="btn btn-icon btn-primary text-white btn-add"
      [tooltip]="'add' | translate"
      [placement]="'top'"
      appRestrictMultiSubmit
      (multiClick)="onAdd()">
      <span class="material-icons-outlined p-1"> add </span>
    </button>
  </div>
</div>
