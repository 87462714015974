import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { UtilService } from 'src/app/core';
import { DATE_FORMAT, DATE_MONTH_YEAR_UI_FORMAT, DATE_UI_FORMAT } from 'src/app/shared/_utils';

export enum SwitchDatePeriod {
  day = 'day',
  month = 'month',
  week = 'week',
  year = 'year',
}

export interface DateChangeData {
  startDate: string;
  endDate: string;
}

@Component({
  selector: 'app-switch-date',
  templateUrl: './switch-date.component.html',
  styleUrls: ['./switch-date.component.scss']
})
export class SwitchDateComponent implements OnInit {
  @Input() type: string = SwitchDatePeriod.day;
  @Input() date: string = moment().format(DATE_FORMAT);
  @Input() showThisPeriodBtn = true;
  @Output() dateChange = new EventEmitter<DateChangeData>();

  today = moment().format(DATE_FORMAT);
  periodDay = SwitchDatePeriod.day;
  startDate = '';
  endDate = '';

  constructor() { }

  ngOnInit(): void { }

  formatDateUI(date: string) {
    switch (this.type) {
      case SwitchDatePeriod.day:
        return date ? moment(date, DATE_FORMAT).format(DATE_UI_FORMAT) : '';
      case SwitchDatePeriod.month:
        return date ? moment(date, DATE_FORMAT).format(DATE_MONTH_YEAR_UI_FORMAT) : '';
      default:
        return 'NA';
    }
  }

  changeToThisPeriod() {
    this.date = moment().format(DATE_FORMAT);
    this.changeDate();
  }

  changeDate(offset: number = 0) {

    if (this.type === SwitchDatePeriod.day) {
      const date = moment(this.date, DATE_FORMAT).add(offset, 'day').format(DATE_FORMAT);
      this.startDate = date;
      this.endDate = date;

      this.dateChange.emit({startDate: this.startDate, endDate: this.endDate});
      return;
    }

    if (this.type === SwitchDatePeriod.month) {
      const firstAndlast = UtilService.getFirstDayAndLastDayOfMonth(new Date(this.date), offset);
      this.startDate = firstAndlast.firstDay.format(DATE_FORMAT);
      this.endDate = firstAndlast.lastDay.format(DATE_FORMAT);

      this.dateChange.emit({startDate: this.startDate, endDate: this.endDate});

    }
  }

  getThisPriodLabel() {
    switch (this.type) {
      case SwitchDatePeriod.day:
        return 'Today';
      case SwitchDatePeriod.month:
        return 'This Month';
      default:
        return 'NA';
    }
  }

  isToday() {
    return (
      this.type === SwitchDatePeriod.day && moment(this.date).isSame(this.today)
    );
  }

  splitDate(date: string){
    return date.split(',').map(e=>e.trim());
  }
}
