import { UtilService } from 'src/app/core';
import {
  PayrollDetail,
  PayrollDetailField,
  PayrollFieldAlign,
  PayrollFieldType,
  PayrollSection
} from '../../_models/payroll.model';
import { filter } from 'lodash';
import { NumberFormat, NumberFormatConfig } from 'src/app/shared';

export class PayrollUtils {
  static setupPayroll(rows: PayrollDetail[], sections: PayrollSection[]) {
    rows.forEach((row) => {
      Object.values(row.sections).forEach((section) => {
        const _section = sections.find((e) => e.id === section.id);
        section.fields?.forEach((field) => {
          const _field = _section?.fields?.find((e) => e.id === field.id);
          field.numberFormat = _field?.numberFormat;
          field.bold = _field?.bold;
          field.hide = _field?.hide;
          const align = PayrollUtils.getItemAlign(field);
          field.align = _field?.align || align;
          field.value = UtilService.isNumber(field?.value)
            ? parseFloat(String(field.value))
            : field.value;
        });
        section.fields = filter(
          row.sections[section.id].fields,
          (e) => !e.hide
        );
      });
    });
  }

  static getItemAlign(field: PayrollDetailField) {
    if (UtilService.isNumber(field?.value)) {
      field.isNumber = true;
      return PayrollFieldAlign.RIGHT;
    }
    if (PayrollUtils.isDatePicker(field)) {
      field.isDate = true;
      return PayrollFieldAlign.CENTER;
    }
    return PayrollFieldAlign.LEFT;
  }

  static isDatePicker(field: PayrollDetailField) {
    return (
      field.type === PayrollFieldType.DATEPICKER ||
      (field.type === PayrollFieldType.FX &&
        UtilService.isValidDate(String(field.value)))
    );
  }

  static getNumberOption(pattern: NumberFormat) {
    return NumberFormatConfig.find((e) => e.pattern === pattern);
  }
}
