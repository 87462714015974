<div class="color-wrapper">
  <div *ngFor="let color of colors"
    class="color-item"
    [ngStyle]="{
      'background-color': color,
      'width' : size + 'px',
      'height': size + 'px',
      'min-width' : size + 'px',
      'min-height': size + 'px', 
      'font-size': (size - 10) + 'px'}"
    (click)="changeColor(color)">
    <span class="material-icons check-icon" *ngIf="selectedColor === color">
      check
    </span>
  </div>
</div>