import { CVPatchType, Gender } from '../_utils';
import { BaseAttachment } from './attachment.model';
import { JobTitleShort, UserShort, HiringBoardStatus, HiringBoardStatusShort, Attachment } from './index';

export interface CV {
  id: number;
  avatar: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  jobTitle: JobTitleShort;
  gender: string;
  rating: number;
  recommendBy: UserShort;
  status: HiringBoardStatus;
  attachments?: Attachment[];
  totalComments?: number;
  totalAttachments?: number;
  key: string;
  description?: string;
}

export interface CVRequest {
  avatar?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  jobTitleId?: number;
  gender?: Gender;
  rating?: number;
  statusId?: number;
  archived?: boolean;
  userRecommendId?: number;
  description?: string;
}

export interface CVShort {
  id: number;
  avatar: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  jobTitle: JobTitleShort;
  gender: string;
  rating: number;
  recommendBy: UserShort;
  status: HiringBoardStatusShort;
  totalComments?: number;
  totalAttachments?: number;
  key: string;
  description?: string;
}

export interface CVUpsertFile {
  file?: File;
  type?: CVAttachmentType;
  id?: number;
  typeOfObject?: string;
  previewUrl?: string;
}

export type CVAttachmentType = 'Comment' | 'Description' | 'Other';

export interface CVAttachmentRequest {
  attachments: File[];
  type?: CVAttachmentType;
  id?: number;
  typeOfObject?: string;
}

export class CVAttachment extends BaseAttachment { }

export interface CVPatch {
  type: CVPatchType;
  value: string | Date;
}


export interface CVSearch {
  fullName?: string;
  key?: string;
  keyword?: string;
  phone?: string;
  jobTitleIds?: number[];
  gender?: Gender;
  rating?: number;
  archived?: boolean;
  fromDate?: string;
  toDate?: string;
}
