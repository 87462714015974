import { Injectable } from '@angular/core';
import { DynamicField, DynamicFieldType, URL_DYNAMIC_PAGE } from 'src/app/shared';
import { BreadcrumbItemStorage, BreadcrumbItem } from 'src/app/shared/_models/dynamic-field-breadcrumb.model';
import { DynamicFieldDataService } from './dynamic-field-data.service';

@Injectable({
  providedIn: 'root'
})

export class DynamicBreadcrumbDataService {
  breadcrumbItem: BreadcrumbItem = {} as BreadcrumbItem;
  breadcrumbItemStorage: BreadcrumbItemStorage = {} as BreadcrumbItemStorage;

  constructor(
    private dynamicFieldDataService: DynamicFieldDataService,
  ) {}

  storeBreadcrumb() {
    const dynamicFieldTrees = this.dynamicFieldDataService.fieldTrees?.filter(e => !e.hash && e.isVisible) || [];
    this.breadcrumbItemStorage.parents = dynamicFieldTrees.map(fieldTree => this.buildBreadcrumbItem(fieldTree));
    this.breadcrumbItemStorage.childs = this.getChildBreadcrumbItemOfTrees(dynamicFieldTrees);
  }

  getChildBreadcrumbItemOfTrees(fieldTrees: DynamicField[]) {
    const childBreadcrumbItems = [];

    const traverse = (trees: DynamicField[]) => {
      trees?.forEach(tree => {
        childBreadcrumbItems.push(this.getChildBreadcrumbItems(tree?.childs));
        if (tree?.childs?.length && tree.type !== DynamicFieldType.Sections) {
          traverse(tree.childs);
        }
      });
    };

    traverse(fieldTrees);
    return childBreadcrumbItems;
  }

  getRouterLink(tree: DynamicField) {
    return `${URL_DYNAMIC_PAGE}/${tree?.id}`;
  }


  getBreadcrumb(currentBreadcrumb: DynamicField) {
    if (!currentBreadcrumb) {
      return;
    }

    const breadcrumb: BreadcrumbItem = {} as BreadcrumbItem;
    breadcrumb.parent = this.buildBreadcrumbItem(currentBreadcrumb?.parent);
    breadcrumb.current = this.buildBreadcrumbItem(currentBreadcrumb);
    this.breadcrumbItem = breadcrumb;
  }

  buildBreadcrumbItem(breadcrumb: DynamicField) {
    if (!breadcrumb) {
      return null;
    }

    return {
      text: breadcrumb?.name || '',
      routerLink: this.getRouterLink(breadcrumb),
    };
  }

  getChildBreadcrumbItems(breadcrumbs: DynamicField[]) {
    return breadcrumbs?.map(breadcrumb => this.buildBreadcrumbItem(breadcrumb)) || [];
  }

  setChildBreadcrumbSelected(breadcrumb: DynamicField) {
    if (!breadcrumb) {
      return;
    }

    this.breadcrumbItem.child = this.buildBreadcrumbItem(breadcrumb);
  }
}
