import { Injectable } from "@angular/core";
import * as humanizeDuration from 'humanize-duration';

const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  delimiter: " ",
  spacer: "",
  largest: 2,
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
  units: ["h", "m"],
  round: true
});

export const validWorkLogPattern = /[\d]+(\.[\d]{1,2})?[dhms]\s*/g
export const validWorkLogParternForTest = /^([\d]+(\.[\d]{1,2})?[dhms]?\s*)+$/g

export enum LogWorkUnits {
  d = 'd',
  h = 'h',
  m = 'm',
  s = 's'
}

@Injectable({
  providedIn: 'root'
})
export class TimeDurationService {
  constructor() {
  }

  /**
   * Return the humanize duration
   * @param duration unit should be in miniseconds
   */
  getHumanizeDuration(duration: number, options?: humanizeDuration.Options): string {
    if (!duration) {
      return ''
    }
    return shortEnglishHumanizer(duration, options)
  }

  getTimeInMiliseconds(durationStr: string): number {
    durationStr = <string>durationStr.replace(/\s/g, '')
    let matched = durationStr.match(validWorkLogPattern)
    let result: number = 0
    for (let str of matched) {
      let temp = this.convertToMiliseconds(
        parseFloat(str),
        <LogWorkUnits>str[str.length - 1]
      )
      result += temp
    }
    return result;
  }

  convertToMiliseconds(amount: number, unit: LogWorkUnits): number {
    switch (unit) {
      case 'd':
        return amount * 86400 * 1000;
      case 'h':
        return amount * 3600 * 1000;
      case 'm':
        return amount * 60 * 1000;
      case 's':
        return amount * 1000;
      default:
        return 0;
    }
  }
}
