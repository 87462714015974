import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NumberFormat } from 'src/app/shared';
import { PayrollUtils } from 'src/app/site-management/salary/payroll/models/payrollUtils';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnChanges {
  @Input() value: number;
  @Input() format: NumberFormat;
  @Input() class = '';
  negativeColor = '';
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.isNavigateColor();
  }

  isNavigateColor() {
    if (this.value < 0) {
      this.negativeColor = PayrollUtils.getNumberOption(this.format)?.option?.negativeColor;
    }
    else {
      this.negativeColor = '';
    }
  }

}
