<app-color-picker
  [colors]="colors"
  [selectedColor]="colorCode"
  [size]="size"
  (colorChange)="onColorChange($event)">
</app-color-picker>
<!-- <input class="form-control custom"
  [value]="colorCode"
  [colorPicker]="colorCode"
  (input)="onColorChange($event.target.value)"
  (colorPickerChange)="onColorChange($event)"
  [style.color]="colorCode"/> -->