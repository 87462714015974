import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { BacklogSearchParam } from 'src/app/shared';


@Injectable({
    providedIn: 'root'
})
export class BacklogDataFilterService {

    private searchParam: BacklogSearchParam;

    get CurrentSearchParam() {
        if (this.searchParam && this.searchParam.projectId !== 0) {
            const cloneParam = new BacklogSearchParam(this.searchParam.projectId);
            Object.assign(cloneParam, this.searchParam);
            return cloneParam;
        }
        return null;
    }
    filterSubject: BehaviorSubject<BacklogSearchParam>;
    constructor() {
        this.filterSubject = new BehaviorSubject<BacklogSearchParam>(this.searchParam);
    }

    init(param: BacklogSearchParam) {
        this.searchParam = param;
    }
    setSearchParam(param: BacklogSearchParam) {
        this.searchParam = param;
        this.filterSubject.next(this.searchParam);
    }
}
