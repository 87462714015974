import { OkrContributorType } from '../_enums';

export const PercentContributorReport = [0, 20, 40, 60, 80, 100];
export const PERCENT_DISTANCE = 20;
export const METRIC_SIZE = 4;

export const OkrContributors = [
  {
    name: 'Personal',
    value: OkrContributorType.PERSONAL,
  },
  {
    name: 'Company',
    value: OkrContributorType.COMPANY,
  },
  {
    name: 'Group',
    value: OkrContributorType.GROUP,
  },
  {
    name: 'Team',
    value: OkrContributorType.TEAM,
  },
];
