import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilService } from 'src/app/core';
import { FileValue } from 'src/app/shared/_models';
import { DATE_TIME_UI_HOUR_BEFORE_DATE_FORMAT } from 'src/app/shared/_utils';
import { FilePreview, FilePreviewComponent } from '../../../file-preview/file-preview.component';
import { DFieldBaseComponent } from '../d-field-base.component';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-d-field-file',
  templateUrl: './d-field-file.component.html',
  styleUrls: ['./d-field-file.component.scss']
})
export class DFieldFileComponent extends DFieldBaseComponent {
  uploadFile: File[] = [];
  constructor(
    protected modalService: BsModalService,
    protected fb: UntypedFormBuilder,
  ) {
    super(fb);
  }

  getUrlExtension(url: string = '') {
    return UtilService.getUrlExtension(url);
  }

  getFileSize(size: number) {
    return size && !isNaN(size) ? UtilService.formatBytes(size) : '';
  }

  getLastModified(date: string) {
    return UtilService.getDateUI(date, DATE_TIME_UI_HOUR_BEFORE_DATE_FORMAT);
  }

  selectFile(files: File[]) {
    this.uploadFile.push(...files);
    this.emitNewvalue();
  }

  removeOldFile(index: number) {
    this.field.value.file.splice(index, 1);
    this.emitNewvalue();
  }

  removeUploadFile(index: number) {
    this.uploadFile.splice(index, 1);
    this.emitNewvalue();
  }

  emitNewvalue() {
    this.emitValue({
      attachments: this.uploadFile,
      keepIds: this.field.value?.file?.map(e => e.fileId) || [],
    });
  }

  onPreviewFile(file: FileValue, index: number) {
    const files: FilePreview[] = this.field.value?.file?.map(e => {
        return {
          id: e.fileId,
          name: e.name,
          url: e.url,
          objectUrl: null,
          previewUrl: e.previewUrl,
        };
      });
    this.modalService.show(FilePreviewComponent, {
      backdrop: 'static',
      class: 'modal-preview',
      initialState: {
        files,
        currentIndex: index || 0
      }
    });
  }

}
