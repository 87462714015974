import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as queryString from 'query-string';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
  BoardRelease, BoardReleaseAddTaskRequest, BoardReleaseCompleteRequest, BoardReleaseCreateRequest,
  BoardReleaseDetail, BoardReleaseListResponse, BoardReleasePlanningSummary,
  BoardReleaseSearchRequest, BoardReleaseUpdateRequest, ChangeOrderRequest, EffortUpsertRequest, List2Res, Pageable,
  ReleasePlanningResponse, SprintRequest, Task, TaskSearchRequest
} from 'src/app/shared';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ProjectBoardReleaseService {
  public loading$ = new Subject<boolean>();
  public showStoryline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
  ) { }

  get(payload: BoardReleaseSearchRequest, pageable: Pageable): Observable<List2Res<BoardReleaseListResponse>> {
    const params = queryString.stringify({ ...payload, ...pageable });
    this.loading$.next(true);
    return this.http.get<List2Res<BoardReleaseListResponse>>(
      `${environment.apiUrl}/projects/${payload.projectId}/board-release/?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  add(projectId: number, payload: BoardReleaseCreateRequest) {
    this.loading$.next(true);
    return this.http.post<BoardRelease>(`${environment.apiUrl}/projects/${projectId}/board-release/`, payload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  update(projectId: number, payload: BoardReleaseUpdateRequest) {
    this.loading$.next(true);
    return this.http.put<BoardRelease>(`${environment.apiUrl}/projects/${projectId}/board-release/${payload.id}`, payload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  getById(projectId: number, id: number) {
    this.loading$.next(true);
    return this.http.get<BoardReleaseDetail>(`${environment.apiUrl}/projects/${projectId}/board-release/${id}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  addTask(projectId: number, releaseId: number, payload: BoardReleaseAddTaskRequest) {
    this.loading$.next(true);
    const clonePayload: BoardReleaseAddTaskRequest = { ...payload };
    clonePayload.startDateTime = clonePayload.startDateTime ? clonePayload.startDateTime + 'T00:00:00.000Z' : null;
    clonePayload.endDateTime = clonePayload.endDateTime ? clonePayload.endDateTime + 'T23:59:59.000Z' : null;

    return this.http.post<Task>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/addTask`, clonePayload).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  removeTask(projectId: number, releaseId: number, taskId: number) {
    this.loading$.next(true);
    return this.http.delete<any>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/removeTask/${taskId}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  getReleasePlanning(projectId: number, releaseId: number, payload: TaskSearchRequest = {}) {
    const params = queryString.stringify({ ...payload });

    this.loading$.next(true);
    return this.http.get<BoardReleasePlanningSummary>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/planning?${params}`)
    .pipe(finalize(() => this.loading$.next(false)));
  }

  getStorylinePlanning(projectId: number, releaseId: number, payload: TaskSearchRequest = {}) {
    const params = queryString.stringify({ ...payload });

    this.loading$.next(true);
    return this.http.get<BoardReleasePlanningSummary>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/storylinePlanning?${params}`)
    .pipe(finalize(() => this.loading$.next(false)));
  }

  updateEffort(projectId: number, releaseId: number, effortId: number, payload: EffortUpsertRequest) {
    this.loading$.next(true);
    return this.http.put<ReleasePlanningResponse>(`${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/edit-effort/${effortId}`, payload)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  addEffort(projectId: number, releaseId: number, payload: EffortUpsertRequest[]) {
    this.loading$.next(true);
    return this.http.post<ReleasePlanningResponse[]>(
      `${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/add-effort`, payload)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  removeEffort(projectId: number, releaseId: number, effortId: number) {
    this.loading$.next(true);
    return this.http.delete<any>(
      `${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/remove-effort/${effortId}`)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  completeRelease(projectId: number, releaseId: number, payload: BoardReleaseCompleteRequest) {
    this.loading$.next(true);
    return this.http.patch<BoardRelease>(
      `${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/release`, payload)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  importSprint(projectId: number, releaseId: number, payload: SprintRequest) {
    this.loading$.next(true);
    return this.http.put<any>(
      `${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/import-sprint`, payload)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  changeOrder(projectId: number, releaseId: number, payload: ChangeOrderRequest) {
    return this.http.put<any>(
      `${environment.apiUrl}/projects/${projectId}/board-release/${releaseId}/change-order`,
      payload
    );
  }
}
