import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as queryString from 'query-string';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { List2Res, Pageable, TaskInStoryline } from 'src/app/shared';
import { BoardStoryline, BoardStorylineSearchByAvailablePlatforms, BoardStorylineSearchRequest, BoardStorylineTaskSearchRequest } from 'src/app/shared/_models/board-storyline.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ProjectBoardStorylineService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  search(projectId: number, searchParam: BoardStorylineSearchRequest, pageable: Pageable = {}) {
    const params = queryString.stringify({...searchParam, ...pageable});

    this.loading$.next(true);
    return this.http.get<List2Res<BoardStoryline>>(`${environment.apiUrl}/projects/${projectId}/board-storylines/?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  delete(projectId: number, storylineId: number) {
    this.loading$.next(true);
    return this.http.request<any>('delete', `${environment.apiUrl}/projects/${projectId}/board-storylines/${storylineId}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  getTasks(projectId: number, storylineId: number, searchParam: BoardStorylineTaskSearchRequest = {}) {
    const params = queryString.stringify(searchParam);

    this.loading$.next(true);
    return this.http.get<TaskInStoryline[]>(
      `${environment.apiUrl}/projects/${projectId}/board-storylines/${storylineId}/tasks?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  searchByAvailablePlatforms(projectId: number, searchParam: BoardStorylineSearchByAvailablePlatforms, pageable: Pageable = {}) {
    const params = queryString.stringify({...searchParam, ...pageable});

    this.loading$.next(true);
    return this.http.get<List2Res<BoardStoryline>>(
      `${environment.apiUrl}/projects/${projectId}/board-storylines/searchByAvailablePlatforms?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }
}
