import { ActiveSprint } from "./sprint.model";
import { Task } from "./task.model";

export interface ActiveBoard {
  activeSprint: ActiveSprint;
  statusTasks: StatusTask[];
}

export interface StatusTask {
  column: BoardColumnMaster;
  tasks: Task[];
}

export interface BoardColumnMaster {
  id: number;
  name: string;
  colorCode: string;
}
