import { Component, SimpleChanges } from '@angular/core';
import { FieldType } from 'src/app/shared/_utils';
import { DFieldCellBaseTableComponent } from './d-field-cell-base-table';

@Component({
  selector: 'app-d-field-cell-table',
  templateUrl: './d-field-cell-table.component.html',
  styleUrls: ['./d-field-cell-table.component.scss']
})
export class DFieldCellTableComponent extends DFieldCellBaseTableComponent {
  constructor() {
    super();
  }

  changes(changes: SimpleChanges) {
    if (changes?.row || changes?.column) {
      this.updateDataCell(changes.row?.currentValue, changes.column?.currentValue);
    }
  }

  isText() {
    return [FieldType.text, FieldType.number].some(e => e === this.column.type);
  }

  isFile() {
    return this.column.type === FieldType.file;
  }

  isDatePicker() {
    return this.column.type === FieldType.datePicker;
  }

  isDropdown() {
    return [FieldType.select, FieldType.multiSelect].some(e => e === this.column.type);
  }
}
