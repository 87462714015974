import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { PAGE_SIZE } from '../../_utils';
import { AbstractComponent } from '../_base-component';

export interface PagingStyle {
  main: string;
  footer: string;
}

export enum PageSizeTheme {
  DEFAULT_THEME = 'default',
  MATERIAL = 'material',
}

@Component({
    selector: 'ngx-pagesize',
    templateUrl: './ngx-pagesize.component.html',
    styleUrls: ['./ngx-pagesize.component.scss']
})
export class NgxPageSizeComponent extends AbstractComponent {
    @Input() pageInfo: any;
    @Input() pageSize = PAGE_SIZE[0];
    @Input() theme: PageSizeTheme = PageSizeTheme.DEFAULT_THEME;
    @Input() style: PagingStyle = {main: '', footer: ''};

    @Output() pageChange = new EventEmitter<number>();
    @Output() pageSizeChange = new EventEmitter<number>();
    @Output() pageInfoChange = new EventEmitter<number>();

    PAGE_OPTION = PAGE_SIZE;
    pagingStyles = {
      main: 'ngx-datatable material mt-1',
      footer: 'datatable-footer'
    };

    constructor() {
      super();
      this.checkWindowSize();
    }

    init() {
      super.init();
      this.setPaginationStyle();
    }

    onPageChange(event: { page: number }) {
        this.pageChange.emit(event.page - 1);
    }

    onPageSizeChange(event: number) {
        this.pageSizeChange.emit(event);
    }

    observeCheckWindowSize() {
      this.setPaginationStyle();
    }

    setPaginationStyle() {
      if (this.theme === PageSizeTheme.MATERIAL || !this.isWebLayout) {
        this.style = {...this.pagingStyles};
        return;
      }
    }
}
