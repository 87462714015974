<div class="time-picker-wrapper">
  <div class="content">
    <!-- hours -->
    <div class="time-column">
      <div class="active"></div>
      <div #hourContainer class="d-time flex-1 mr-1">
        <ng-container *ngTemplateOutlet="timePicker; context: {items: hours}"></ng-container>
        <div class="last-item"></div>
      </div>
    </div>

    <!-- minutes -->
    <div class="time-column">
      <div class="active"></div>
      <div #minutesContainer class="d-time flex-1 mr-1">
        <ng-container *ngTemplateOutlet="timePicker; context: {items: minutes}"></ng-container>
        <div class="last-item"></div>
      </div>
    </div>

    <!-- meridiem -->
    <div class="time-column">
      <div class="active"></div>
      <div #meridiemContainer class="d-time flex-1">
        <ng-container *ngTemplateOutlet="meridiemPicker; context: {items: meridiem}"></ng-container>
      </div>
    </div>
  </div>
  <div class="action d-flex">
    <button type="button" class="btn btn-cancel flex-1" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-submit flex-1 text-primary" appRestrictMultiSubmit (multiClick)="onSubmit()"> <app-i18n [key]="'OK'"></app-i18n></button>
  </div>
</div>

<!-- hour, minutes template -->
<ng-template #timePicker let-items="items">
  <ng-template ngForScrollCustomize [items]="items" [selectedTime]="selectedTime" let-item
    (timeChanged)="onSelectedTime($event)">
    <div #timeElement class="item mb-1" [ngClass]="{ 'selected': selectedTime | getSelectedTime : item }"
      (click)="onSelectedTime(item, timeElement)">{{ item?.value }}</div>
  </ng-template>
</ng-template>

<!-- meridiem template -->
<ng-template #meridiemPicker let-items="items">
  <ng-template ngForScrollCustomize [items]="items" [selectedTime]="selectedTime" [hasMeridiemTime]="true" let-item
    (timeChanged)="onSelectedTime($event)">
    <div #meridiemElement class="item mb-1" [ngClass]="{ 'selected': selectedTime | getSelectedTime : item }"
      (click)="onSelectedTime(item, meridiemElement)">{{ item?.value }}</div>
    </ng-template>
    <div class="last-meridiem"></div>
</ng-template>
