<div class="row">
  <ng-container *ngFor="let f of fields">
    <div class="col-12 col-lg-{{f.layout || 12}}" [ngStyle]="{'order': f.rowIndex || 1}" *ngIf="f.isVisible && (f.isSystem && f.template && !hideSystem || !f.isSystem)">
      <div class="form-group">
        <label *ngIf="isShowFieldName(f)"><app-i18n [key]="f.name"></app-i18n></label>
        <div class="control">
          <ng-container *ngIf="f.isSystem && f.template">
            <ng-container *ngTemplateOutlet="f.template"></ng-container>
          </ng-container>

          <ng-container *ngIf="!f.isSystem">
            <app-d-field-input [field]="f" *ngIf="isInputField(f)" (fieldChange)="onFieldChange($event)"></app-d-field-input>
            <app-d-field-date [field]="f" *ngIf="isDateField(f)" (fieldChange)="onFieldChange($event)"></app-d-field-date>
            <app-d-field-select [field]="f" *ngIf="isDropdownField(f)" (fieldChange)="onFieldChange($event)"></app-d-field-select>
            <app-d-field-table [field]="f" *ngIf="isTableField(f)" (fieldChange)="onFieldChange($event)"></app-d-field-table>
            <app-d-field-file [field]="f" *ngIf="isFileField(f)" (fieldChange)="onFieldChange($event)"></app-d-field-file>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
