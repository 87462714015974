import * as moment from 'moment';
import { FORMAT_12_HOURS, UnitOfTime } from '../../_utils';
import { TimeFormat, TimeObject } from '../time-picker/models/time-picker.model';

export const timeLimitPerDay12Hours = 12;
export const timeLimitPerHour = 60;
export const meridiem = ['AM', 'PM'];

export const generateHours = () => {
  const sequence: TimeObject[] = [];
  for (let i = 1; i <= timeLimitPerDay12Hours; i++) {
    const hour = i.toString().padStart(2, '0');
    sequence.push({value: hour, type: UnitOfTime.hours});
  }
  return sequence;
};

export const generateMinutes = () => {
  const sequence: TimeObject[] = [];
  for (let i = 0; i < timeLimitPerHour; i++) {
    const minute = i.toString().padStart(2, '0');
    sequence.push({value: minute, type: UnitOfTime.minutes});
  }
  return sequence;
};

export const generateMeridiem = () => {
  return meridiem.map((e) => {
    return {
      value: e,
      type: UnitOfTime.periods
    };
  });
};

export const getSelectedTime = (currentTime: TimeFormat, time: TimeObject) => {
  if (!time) {
    return;
  }

  const _time = {
    ...currentTime
  };

  switch (time.type) {
    case UnitOfTime.hours:
      _time.hour = time.value;
      break;
    case UnitOfTime.minutes:
      _time.minute = time.value;
      break;
    case UnitOfTime.periods:
      _time.period = time.value;
      break;
    default:
      break;
  }
  return _time;
};



export const parseTimeString = (time?: string) => {
  const timeStr = time ? time : moment(new Date()).format(FORMAT_12_HOURS);
  const [timeParts, period] = timeStr.split(' ');
  const [hour, minute] = timeParts.split(':');

  return {
    hour,
    minute,
    period
  };
};
