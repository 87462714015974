import { UntypedFormControl } from '@angular/forms';
import { TimeFormat, TimeObject } from './models/time-picker.model';
import * as moment from 'moment';
import { FORMAT_12_HOURS } from '../..';

export const timePeriod = ['AM', 'PM'];
export const timeLimitPerDay12Hours = 12;
export const timeLimitPerHour = 60;
export const defaultHour = 11;
export const defaultMinute = 59;
export const defaultPeriod = 'PM';


export class TimePicker {
  static generateNumberSequence(inputNumber: number, type: string) {
    const sequence: TimeObject[] = [];
    for (let i = 0; i < inputNumber; i++) {
      const generateNumber = i.toString().padStart(2, '0');
      sequence.push({value: generateNumber, type});
    }
    return sequence;
  }

  static periodDate(type: string) {
    return timePeriod?.map(time => {
      return {
        value: time,
        type
      };
    });
  }

  static setupSelectedTime(control: UntypedFormControl) {
    if (!control.value) {
      return this.setupDefaultTime();
    }
    const timeMoment = moment(new Date(control.value), FORMAT_12_HOURS);
    const hour = timeMoment.hours() % 12 || timeLimitPerDay12Hours;
    const minute = timeMoment?.minutes() || 0;
    const period = timeMoment?.format('A') || 'AM';
    return {
      hour:  hour.toString().padStart(2, '0'),
      minute: minute.toString().padStart(2, '0'),
      period
    };
  }

  static setupDefaultTime(): TimeFormat{
    return {
      hour: `${defaultHour}`,
      minute: `${defaultMinute}`,
      period: `${defaultPeriod}`
    };
  }
}
