import { Directive, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TimePickerOverlayService } from 'src/app/site-management/_services/time-picker-overlay.service';

@Directive({
  selector: '[bsTimePicker]',
})
export class TimePickerDirective {
  @Input() control: UntypedFormControl;
  @Input() defaultValue: string;

  constructor(
    private timePickerOverlayService: TimePickerOverlayService
  ) {}

  @HostListener('click', ['$event.target'])
  onClickEvent(elementRef: Element) {
    if (elementRef) {
      this.timePickerOverlayService.open(elementRef, this.control, this.defaultValue);
    }
  }
}
