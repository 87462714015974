import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Field, FieldValue, PageInfo, PageableModel, RowValue } from 'src/app/shared/_models';
import { DFieldBaseComponent } from '../d-field-base.component';
import { DFieldTableRowUpsertComponent } from './d-field-table-row-upsert/d-field-table-row-upsert.component';
import { UntypedFormBuilder } from '@angular/forms';
import { FieldService } from 'src/app/site-management/dynamic-field/_services/field-service';
import { takeUntil } from 'rxjs/operators';
import { DynamicFieldTableRequest, FIRST_PAGE, User } from 'src/app/shared';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { SortDirection } from 'src/app/shared/_utils/_enums/table.enum';

@Component({
  selector: 'app-d-field-table',
  templateUrl: './d-field-table.component.html',
  styleUrls: ['./d-field-table.component.scss']
})
export class DFieldTableComponent extends DFieldBaseComponent {
  isEdit = false;
  isLoadingRow = false;
  updatedRow: RowValue;
  rows: RowValue[] = [];
  loggedUser: User;
  pageInfo: PageInfo;
  searchRequest: DynamicFieldTableRequest = {} as DynamicFieldTableRequest;
  pageable: PageableModel = new PageableModel();

  constructor(
    private modalService: BsModalService,
    protected fb: UntypedFormBuilder,
    private fieldService: FieldService,
    private authService: AuthService,
  ) {
    super(fb);
    this.loggedUser = this.authService.getLoggedUser();
  }

  init() {
    super.init();
    this.pageable.sort = SortDirection.descending;
    this.setDefaultSearchRequestValues();
    this.onSearch();
    this.handleRow();
  }

  handleRow() {
    this.fieldService.updateFieldValue$.pipe(takeUntil(this.destroyed$)).subscribe(field => {
      const fieldValue = field.find(e => e.fieldId === this.field.id);
      if (!this.isEdit) {
        this.addRow();
        return;
      }

      if (this.isEdit) {
        this.updateRow(fieldValue);
        return;
      }
    });
  }

  onPage(page: number): void {
    this.pageable.page = page;
    this.onSearch();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageable.page = FIRST_PAGE;
    this.pageable.size = pageSize;
    this.onSearch();
  }

  onSearch() {
    this.fieldService.getFieldValue(this.searchRequest, this.pageable).pipe(takeUntil(this.destroyed$))
    .subscribe(
      (res) => {
        this.rows = res.content;
        this.pageInfo = {
          pageable: res.pageable,
          totalElements: res.totalElements,
          totalPages: res.totalPages
        };
      },
      (error: string) => this.errorFn(error)
    );
  }

  setDefaultSearchRequestValues() {
    this.searchRequest.groupId = this.loggedUser.id;
    this.searchRequest.fieldId = this.field.id;
    this.searchRequest.keyword = '';
  }

  updateRow(fieldValue: FieldValue) {
    const updatedRow = fieldValue?.rows?.find(e => e.id === this.updatedRow?.id);
    const indexOfOldRow = this.rows?.findIndex(row => row.id === updatedRow?.id);
    if (indexOfOldRow !== -1) {
      this.rows[indexOfOldRow] = updatedRow;
    }
  }

  addRow() {
    this.onSearch();
    this.isLoadingRow = false;
  }

  onEdit(row: RowValue) {
    this.showUpsertModal(row);
  }

  onDelete(row: RowValue) {
    row.deleteFlag = true;
    this.emitValue(this.field.value.rows);
  }

  showUpsertModal(row?: RowValue) {
    const modalRef = this.modalService.show(DFieldTableRowUpsertComponent, {
      backdrop: 'static',
      initialState: {
        field: this.field,
        row,
      }
    });
    modalRef.content.objectResponse.subscribe((result: RowValue) => {
      this.modalService.hide();
      if (!result) {
        return;
      }

      result.isUpdatingFlag = true;
      if (row) {
        row = {...result};
        this.updatedRow = row;
        this.isEdit = true;
      } else {
        this.rows.push(result);
        this.isEdit = false;
        this.isLoadingRow = true;
        this.toast.success('Please wait, the system is adding a new record');
      }
      this.emitValue(this.getPayloadOfRow());
    });
  }

  getPayloadOfRow() {
    return this.rows?.map(field => {
      return {
        id: field?.id || null,
        columns: field.columns?.map(e => {
          return {
            text: e?.text,
            fieldId: e?.fieldId,
            number: e?.number,
            date: e?.date,
            attachments: e?.attachments,
            optionIds: e?.optionIds || e?.options?.map(option => option?.id),
            keepIds: e?.file?.map(file => file.fileId)
          };
        })
      };
    });
  }

  canSearchRow() {
    return this.field?.columns?.some(column => column.searchable);
  }

  onSearchText(keyword: string) {
    this.pageable.keyword = keyword;
    this.onSearch();
  }
}
