<div class="modal-header">
  <h4 class="modal-title text-center col-12">{{title}}</h4>
</div>
<div class="modal-body">
  <div>
    <div class="container-fluid">
      <div class="row my-2">
        <div class="text-center col-md-8">
          <image-cropper
            [imageFile]="imageFile"
            [maintainAspectRatio]="maintainAspectRatio"
            [aspectRatio]="1 / 1"
            [onlyScaleDown]="onlyScaleDown"
            [resizeToWidth]="resizeToWidth"
            [resizeToHeight]="resizeToHeight"
            format="png"
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()">
          </image-cropper>
        </div>
        <div class="text-center col-md-4">
          <img style="max-width: 100%;" [src]="croppedImage?.base64" />
        </div>
      </div>

      <div class="mb-5 text-danger" *ngIf="errorLoadingImage">
        {{errorLoadingImage}}
      </div>
    </div>
    <div class="text-center mt-4">
      <button type="button" class="btn btn-white mr-2" style="min-width: 100px"
        (click)="onNoClick()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ml-2" style="min-width: 100px"
        [disabled]="errorLoadingImage"
        (click)="onYesClick()">
        Save
      </button>
    </div>
  </div>
</div>