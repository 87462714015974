import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() show: boolean;

  percent = '-100%';
  myInterval = null;
  isShow = false;
  constructor() { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    if (this.myInterval) {
      clearInterval(this.myInterval);
    }
  }

  ngOnChanges() {
    if (this.show) {
      this.isShow = true;
      this.percent = '-90%';
      this.myInterval = setInterval(() => {
        switch (this.percent) {
          case '-90%':
            this.percent = '-50%';
            break;
          case '-50%':
            this.percent = '-10%';
            break;
          default:
            break;
        }
      }, 500);
    } else {
      clearInterval(this.myInterval);
      this.percent = '0%';
      setTimeout(() => {
        this.isShow = false;
        this.percent = '-100%';
      }, 750);
    }
  }

}
