import { Component, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { DynamicField, Field, User } from 'src/app/shared/_models';
import { FieldService } from 'src/app/site-management/dynamic-field/_services/field-service';
import { AbstractComponent } from '../../_base-component';
import { FieldDataChange } from '../d-field/d-field.component';

@Component({
  selector: 'app-d-tab',
  templateUrl: './d-tab.component.html',
  styleUrls: ['./d-tab.component.scss']
})
export class DTabComponent extends AbstractComponent {
  @Input() tab: DynamicField;

  loggedUser: User;
  fields: Field[] = [];
  sectionFields: {[key: number]: Field[]} = {};

  constructor(
    private fieldService: FieldService,
    private authService: AuthService,
  ) {
    super();
    this.loggedUser = this.authService.getLoggedUser();
  }

  init() {
    this.fieldService.getAllByDFieldId(this.tab.id, this.loggedUser.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (fields) => {
          this.fields = fields;
          this.updateSectionFields();
        },
        (error: string) => {
          this.fields = [];
          this.updateSectionFields();
          this.errorFn(error);
        }
      );
  }

  updateSectionFields() {
    this.sectionFields = {};
    this.tab.childs?.forEach(section => {
      this.sectionFields[section.id] = this.getFieldsBySectionId(section.id);
    });
    this.sectionFields = {...this.sectionFields};
  }

  getFieldsBySectionId(sectionId: number) {
    return this.fields.filter(e => e.sectionId === sectionId);
  }

  hasMoreThanOneTab() {
    return this.tab?.childs?.length > 1;
  }

  onFieldChange(e: FieldDataChange) {
    const payload = {...e.payload};
    payload.id = e.field.id;
    payload.groupId = this.loggedUser.id;
    this.fieldService.updateFieldValue([payload]).pipe(takeUntil(this.destroyed$)).subscribe();
  }
}
