import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BoardSprint, List2Res, Pageable } from 'src/app/shared';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ProjectBoardSprintService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  get(payload: { projectId: number, keyword?: string }, pageable: Pageable): Observable<List2Res<BoardSprint>> {
    const params = `keyword=${payload.keyword || ''}&page=${pageable.page || ''}&size=${pageable.size || ''}&sort=${pageable.sort || ''}`;
    this.loading$.next(true);
    return this.http.get<List2Res<BoardSprint>>(`${environment.apiUrl}/projects/${payload.projectId}/board-sprints/?${params}`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }
}
