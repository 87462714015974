import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BacklogContextMenuActionParams, BoardSprint, Task } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class BacklogContextMenuDataService {
  openMenu$ = new Subject<{
    event: PointerEvent;
  }>();

  moveToBacklog$ = new Subject<BacklogContextMenuActionParams>();

  moveToSprint$ = new Subject<BacklogContextMenuActionParams>();

  deleteTasks$ = new Subject<BacklogContextMenuActionParams>();

  cloneTasks$ = new Subject<BacklogContextMenuActionParams>();
}
