import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-tags',
  templateUrl: './formly-tags.component.html',
  styleUrls: ['./formly-tags.component.scss']
})
export class TagsType extends FieldType<FieldTypeConfig> {
}
