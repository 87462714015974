import { Category } from './category.model';
import { Company } from './company.model';
import { Group } from './group.model';
import { Team } from './team.model';
import { User } from './user.model';

export interface TypeOfLeave {
  id?: number;
  code: string;
  name: string;
  colorCode: string;
  company: Company;
  annualLeaveAmount: number;
  carryOverAmount?: number;
  category?: Category;
  halfDayRequestFlg: boolean;
  registerRequestPastFlg: boolean;
  enforceAllowanceFlg: boolean;
  description?: string;
  activeFlg: boolean;
  paidFlg: boolean;
  team: Team[];
  group: Group[];
  employee: User[];
  applyForAllUser: boolean;
}
