import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import { ToastService } from '../_services/toast.service';
import { routerObject } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService  {
  constructor(
    private router: Router,
    private permissionCheckService: PermissionCheckService,
    private toast: ToastService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const permissionCode: string = route.data?.permissionCode || '';

    if (this.permissionCheckService.hasPermission(permissionCode)) {
      return true;
    }
    this.router.navigate([routerObject.noPermission.fullPath]);
    return false;
  }
}
