import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared';
import { ProfileOverlayService } from 'src/app/site-management/_services/profile-overlay.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    @Input() user: User;

    constructor(private overlay: ProfileOverlayService) {}

    ngOnInit(): void {}

    onClick() {
        this.overlay.overlayRef.dispose();
    }
}
