import { Company } from './company.model';

export interface Shift {
  id?: number;
  alias?: string;
  company?: Company;
  fromTime?: string;
  toTime?: string;
  workDays?: number;
  shiftProfileShiftId?: number;
  shiftDays?: ShiftDayCode[];
}

export interface ShiftProfile {
  id?: number;
  name?: string;
  shifts?: Shift[];
  deleted?: boolean;
}

export interface DayShift {
  dayCode?: string;
  shiftDays?: ShiftDayCode[];
  isActiveDay?: boolean;
}

export interface ShiftDayCode {
  id?: number;
  shift?: Shift;
  dayCode?: string;
  status?: string;
}

export interface WorkingPlan {
  id?: number;
  name?: string;
  shiftProfile?: ShiftProfile;
  dayShifts?: DayShift[];
  defaultFlg?: boolean;
}

export interface WorkingPlanUpsert {
  name: string;
  shiftProfile: ShiftProfile;
}

