import { Component } from '@angular/core';
import { DFieldBaseComponent } from '../d-field-base.component';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-d-field-date',
  templateUrl: './d-field-date.component.html',
  styleUrls: ['./d-field-date.component.scss']
})
export class DFieldDateComponent extends DFieldBaseComponent {
  constructor(protected fb: UntypedFormBuilder) {
    super(fb);
  }

}
