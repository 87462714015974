import { Component, OnInit } from '@angular/core';
import { startWith, debounceTime } from 'rxjs/operators';
import { Policy } from 'src/app/shared';
import { PolicyService } from 'src/app/site-management/policy/policy.service';
import { SearchControlComponent } from '../base/search-base.component';

@Component({
  selector: 'app-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss']
})
export class SearchPolicyComponent extends SearchControlComponent<Policy> implements OnInit {
  constructor(private policyService: PolicyService) {
    super();
  }

  ngOnInit(): void {
    this.typing$.pipe(startWith(''), debounceTime(500)).subscribe((res) => {
      this.searchDto.keyword = res;
      this.fetch();
    });
  }

  fetch(): void {
    this.sub?.unsubscribe();
    this.sub = this.policyService.search(this.searchDto).subscribe((res) => {
      this.items = res.content;
    });
  }
}
