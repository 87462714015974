<tabset class="tabset-d-page-container">
  <ng-container *ngFor="let tab of tabs">
    <tab [heading]="tab.name | translate" [active]="activeTab === tab?.name" (selectTab)="onSelectTab($event, tab)" *ngIf="tab.isVisible">
      <ng-container *ngIf="selectedTab.id === tab.id">
        <ng-container *ngIf="tab?.hash; else tabDynamic">
          <ng-container *ngTemplateOutlet="tab.template"></ng-container>
        </ng-container>
        <ng-template #tabDynamic>
          <app-d-tab [tab]="tab"></app-d-tab>
        </ng-template>
      </ng-container>
    </tab>
  </ng-container>
</tabset>
