import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BenefitRule, BenefitRuleVariable, List2Res, Pageable } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { BenefitRuleVariablePayload, UpsertBenefitRule } from './benefit-rule';
import * as queryString from 'query-string';
import { SystemVariable } from '../salary/_models/payroll.model';

@Injectable({ providedIn: 'root' })
export class BenefitRuleService {
  constructor(private http: HttpClient) {}

  create(payload: UpsertBenefitRule) {
    return this.http.post(`${environment.apiUrl}/hrm/benefits-rules`, payload);
  }

  edit(payload: UpsertBenefitRule, id: number) {
    return this.http.put(`${environment.apiUrl}/hrm/benefits-rules/${id}`, payload);
  }

  search(pageable: Pageable) {
    const params = queryString.stringify(pageable);
    return this.http.get<List2Res<BenefitRule>>(`${environment.apiUrl}/hrm/benefits-rules/search?${params}`);
  }

  getVariable(query: BenefitRuleVariablePayload) {
    const params = queryString.stringify(query);
    return this.http.get<SystemVariable[]>(`${environment.apiUrl}/hrm/benefits-rules/variable?${params}`);
  }

  generateVariable(name: string) {
    const params = queryString.stringify({ name });
    return this.http.get<BenefitRuleVariable>(
      `${environment.apiUrl}/hrm/benefits-rules/generate-variable?${params}`
    );
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/hrm/benefits-rules/${id}`);
  }
}
