<div class="multi-filter-wrapper basic-filter-wrapper">
  <div class="dropdown-wrapper dropdown-wrapper-1" dropdown #basicDropdown="bs-dropdown" (onHidden)="hideFilter.next(null)" (onShown)="setupCurrentFilter()" [insideClick]="true" clickOutside (onClickOutside)="basicDropdown.isOpen=false">
    <div class="form-control dropdown-toggle-control" [ngClass]="{'active': hasFilterSelected}" aria-controls="dropdown-level-1" (click)="basicDropdown.isOpen = true">
      <span class="filter-by" *ngIf="!hasFilterSelected"><app-i18n [key]="'Filter by'"></app-i18n></span>
      <div class="selected-items">
        <ng-container *ngFor="let filter of filters">
          <div class="item" *ngIf="!filter.disabled && filter?.selecteds?.length > 0">
            <span class="label">
              <span class="filter font-semibold"><app-i18n [key]="filter.label"></app-i18n>:</span>
              <ng-container *ngFor="let selected of filter.selecteds; let index = index">
                <span class="name">{{filter.type === FilterType.PeriodDatePicker ? getPeriodLabel(filter) : getOptionLabel(filter, selected)}}</span>
                <span *ngIf="index !== filter.selecteds.length - 1">,&nbsp;</span>
              </ng-container>
            </span>
            <span class="material-icons-outlined buton-item-clear" role="button"
              (click)="removeSelected($event, filter, -1, true)"> clear </span>
          </div>
        </ng-container>
      </div>
      <span class="btn-clear material-icons-outlined mr-1 pointer" [ngClass]="clearable ? 'visible' : 'invisible '"
        (click)="clearSelected($event)">
        clear
      </span>
      <mat-icon [svgIcon]="'arrow-drop-down'" class="icon-dropdown pointer"></mat-icon>
    </div>

    <div class="dropdown-menu basic-dropdown" *ngIf="basicDropdown.isOpen && showFilterBy">
      <div class="basic-dropdown-header">
        <span class="text-16px"><app-i18n [key]="'Filter'"></app-i18n></span>
        <div class="d-flex-center pointer" (click)="basicDropdown.isOpen = false">
          <span class="material-icons-outlined icon-md"> close </span>
        </div>
      </div>
      <div class="row basic-dropdown-content">
        <ng-container *ngFor="let filter of filters">
          <ng-container *ngIf="!filter?.hidden">
            <div class="col-12 basic-dropdown-item" *ngIf="filter?.type === FilterType.CustomQuery else basicFields">
              <div class="basic-custom-filter" *ngFor="let selected of filter.selecteds; let index = index">
                <div class="flex-fill">
                  <app-xql-editor (currentSearchText)="onCustomQueryChanged($event, index)" [enableSearch]="false"></app-xql-editor>
                </div>
                <div class="basic-custom-actions">
                  <div class="btn action pointer" (click)="approveQuery(index)">
                    <span class="material-icons-outlined"> done </span>
                  </div>
                  <div class="btn action pointer" (click)="removeCustomQuery(index)">
                    <span class="material-icons-outlined"> close </span>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #basicFields>
              <div class="col-12 col-sm-6 basic-dropdown-item">
                <ng-container *ngIf="filter?.type === FilterType.Dropdown">
                  <div class="dropdown-filter-wrapper">
                    <span class="filter-label" #fieldLabelRef><app-i18n [key]="filter.label"></app-i18n></span>
                    <ng-select [(ngModel)]="filter.selecteds"
                      [items]="filter.items"
                      class="basic-filter-item"
                      [bindLabel]="filter.bindLabel"
                      [searchable]="true"
                      [clearable]="true"
                      [closeOnSelect]="false"
                      [clearSearchOnAdd]="true"
                      [multiple]="true">
                      <ng-template ng-label-tmp let-item="item" let-index="index">
                        <div class="custom-basic-selected" *ngIf="isSelectedFilter(filter, item)">
                          <span class="label">{{item.name}}</span>
                          <span class="material-icons-outlined buton-item-clear clear-btn" role="button"
                          (click)="stopEvent($event); removeDropdownSelectedItem(filter, item)"> clear </span>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </ng-container>

                <ng-container *ngIf="filter?.type === FilterType.SingleDatePicker">
                  <div class="date-filter-wrapper basic-filter-item form-control">
                    <span class="input-label"><app-i18n [key]="filter.label"></app-i18n></span>
                    <input class="date-picker"
                      [(ngModel)]="filter.selecteds[0]"
                      #dp="bsDatepicker"
                      bsDatepicker
                      [bsConfig]="bsConfig"
                      placement="bottom left"
                      (bsValueChange)="onDateChanged($event, filter)">
                  </div>
                </ng-container>

                <ng-container *ngIf="filter?.type === FilterType.PeriodDatePicker">
                  <div dropdown #periodDropdown="bs-dropdown" class="dropdown-wrapper period-wrapper" [insideClick]="true">
                    <div dropdownToggle class="dropdown-toggle dropdown-toggle-control form-control basic-filter-item">
                      <span class="filter-label m-0"><app-i18n [key]="filter.label"></app-i18n></span>
                      <div class="selected-items ml-2">
                        <ng-container *ngFor="let selected of filter.selecteds; let index = index">
                          <span class="item">
                            <span class="name">{{getPeriodLabel(filter)}}</span>
                            <span class="material-icons-outlined button-clear" role="button"
                              (click)="removeSelected($event, filter, index)"> clear </span>
                          </span>
                        </ng-container>
                      </div>
                    </div>
                    <div id="basic-filter-item" *dropdownMenu class="dropdown-menu">
                      <div class="all-options">
                        <ng-container *ngFor="let i of filter.items; let index = index">
                          <div class="option" *ngIf="index !== filter.items.length - 1"
                            (click)="stopEvent($event); periodDropdown.isOpen = false; selectPeriodOption(filter, i)">
                            <span class="text">{{i.name}}</span>
                          </div>
                          <div class="option"
                            *ngIf="index === filter.items.length - 1"
                            (click)="selectPeriodOption(filter, i)">
                            <span class="text">{{i.name}}</span>
                          </div>
                        </ng-container>
                      </div>
                      <div class="filter-custom-range">
                        <input type="text" placeholder="Daterangepicker" #dateRangePicker
                            class="form-control" bsDaterangepicker placement="bottom left"
                            [(ngModel)]="selectedDateRangePickerValues"
                            (bsValueChange)="changeDates($event, filter)" [bsConfig]="bsConfig">
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="filter?.type === FilterType.Text">
                  <div class="basic-filter-item text-field form-control">
                    <span class="filter-label"><app-i18n [key]="filter.label"></app-i18n></span>
                    <input class="ml-2" [(ngModel)]="filter.selecteds[0]">
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>

      <div class="basic-footer mt-3">
        <div class="d-flex-center basic-more-filter">
          <div class="btn-group" dropdown [insideClick]="true" [dropup]="!isWebLayout">
            <div class="dropdown-toggle-control" dropdownToggle aria-controls="add-filter">
              <span class="basic-more-action pointer">+ <app-i18n [key]="'Add Filter'"></app-i18n></span>
            </div>
            <ul id="add-filter-menu" class="dropdown-menu" *dropdownMenu>
              <li class="menu-item" *ngFor="let filter of filtersWithoutExceptedKey">
                <a class="dropdown-item d-flex-center" (click)="filter.hidden = !filter.hidden">
                  <input type="checkbox" [checked]="!filter.hidden" (change)="onShowFilterChange($event, filter)">
                  <span class="checkbox-label ml-2"><app-i18n [key]="filter.label"></app-i18n></span>
                </a>
              </li>
            </ul>
          </div>
          <!-- <div class="basic-more-action pointer" (click)="addNewCustomQuery()">+ <app-i18n [key]="'Add Custom Filter'"></app-i18n></div> -->
        </div>

        <div class="d-flex-center basic-footer-button">
          <button class="btn btn-white mr-3" (click)="basicDropdown.isOpen = false; onCancelFilter()"><app-i18n [key]="'Cancel'"></app-i18n></button>
          <button type="submit" class="btn btn-primary" appRestrictMultiSubmit (multiClick)="basicDropdown.isOpen = false; onSubmit()"><app-i18n [key]="'Search'"></app-i18n></button>
        </div>
      </div>
    </div>
  </div>
</div>
