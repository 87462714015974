import { BoardReleaseStatus } from '..';

export interface BoardReleaseDetailNote {
  id: number;
  boardRelease: BoardReleaseNote;
  settingType: SettingType[];
  taskFormat: string;
  showSummary: boolean;
}

export interface BoardReleaseNote {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  deleted: boolean;
  status: BoardReleaseStatus;
  releaseDate: string;
  storyline: boolean;
  showPlanning: boolean;
}

export interface SettingType {
  id: number;
  projectType: BoardProjectType;
  checked: boolean;
}

export interface BoardProjectType {
  id: number;
  name: string;
  colorCode: string;
  icon: string;
  defaultFlg: boolean;
  deleted: boolean;
}

export interface BoardReleaseDetailNoteRequest {
  settingType: SettingTypeRequest[];
  taskFormat: string;
  showSummary: boolean;
}

export interface SettingTypeRequest {
  projectType: number;
  checked: boolean;
}

export interface ShortProjectType {
  id: number;
  projectTypeId: number;
  name: string;
  checked: boolean;
}
