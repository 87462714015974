import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mobile-list',
  templateUrl: './mobile-list.component.html',
  styleUrls: ['./mobile-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileListComponent<T = any> implements OnInit {
  @Input() items: T[];
  @Input() templateRef: TemplateRef<any>;
  constructor() { }

  ngOnInit(): void {
  }
}
