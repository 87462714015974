<app-modal-container #modal class="dialog-container">
  <ng-container header><app-i18n [key]="'Bulk Edit Task'"  [params]="{taskLength: tasks?.length}"></app-i18n></ng-container>

  <ng-container body>
    <div class="row" [formGroup]="form">
      <div class="col-12">
        <div class="form-group">
          <label class="required"><app-i18n [key]="'Field Name'"></app-i18n></label>
          <ng-select class="w-100 " formControlName="type" [items]="fields"
            bindLabel="name" [clearable]="true" [searchable]="true">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <span class="option-label"><app-i18n [key]="item.name"></app-i18n></span>
            </ng-template>
          </ng-select>
          <app-form-error [form]="form" [controlName]="'type'"></app-form-error>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="required"><app-i18n [key]="'Value'"></app-i18n></label>
          <div class="control">
            <ng-container *ngTemplateOutlet="getTemplateByType()"></ng-container>
          </div>
          <app-form-error [form]="form" [controlName]="'value'"></app-form-error>
        </div>
      </div>
      <div class="col-12">
        <div class="control d-flex-center">
          <div class="checkbox-control d-flex mr-2">
            <input #input type="checkbox" [formControlName]="formCtrlName.sendMail" id="sendMail">
          </div>
          <span><app-i18n [key]="'sendMailToUser'"></app-i18n></span>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <button type="button" class="btn btn-white mr-3" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-primary" appRestrictMultiSubmit (multiClick)="onSubmit()"><app-i18n [key]="'Save'"></app-i18n></button>
  </ng-container>
</app-modal-container>

<ng-template #assigneeTemplate [formGroup]="form">
    <ng-select
      formControlName="value"
      placeholder="{{'Unassigned' | translate}}"
      [items]="fieldOptions.assignee"
      bindLabel="fullName"
      [searchable]="true"
      [multiple]="false">

      <ng-template ng-option-tmp ng-label-tmp let-item="item">
        <div class="custom-option" *ngIf="item">
          <app-avatar
            [url]="item.avatar"
            [name]="item.fullName"
          ></app-avatar>
          <span class="option-label">{{item.fullName}}</span>
        </div>
      </ng-template>
    </ng-select>
</ng-template>

<ng-template #reporterTemplate [formGroup]="form">
  <ng-select
    formControlName="value"
    placeholder="{{'Unassigned' | translate}}"
    [items]="fieldOptions.reporter"
    bindLabel="fullName"
    [searchable]="true"
    [clearable]="true"
    [multiple]="false">

    <ng-template ng-option-tmp ng-label-tmp let-item="item">
      <div class="custom-option" *ngIf="item">
        <app-avatar
          [url]="item.avatar"
          [name]="item.fullName"
          [hideTooltip]="true"
        ></app-avatar>
        <span class="option-label">{{item.fullName}}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #typeTemplate [formGroup]="form">
  <ng-select
    formControlName="value"
    placeholder="{{'None' | translate}}"
    [items]="fieldOptions.type"
    bindLabel="name"
    [searchable]="true"
    [clearable]="false"
    [multiple]="false">

    <ng-template ng-option-tmp ng-label-tmp let-item="item">
      <div class="custom-option">
        <div class="option-icon-wrap" *ngIf="item?.icon">
          <img class="option-icon" [src]="item.icon" />
        </div>
        <span class="option-label"><app-i18n-master-data [object]="item"></app-i18n-master-data></span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #priorityTemplate [formGroup]="form">
    <ng-select
      formControlName="value"
      placeholder="{{'None' | translate}}"
      [items]="fieldOptions.priority"
      bindLabel="name"
      [searchable]="true"
      [clearable]="false"
      [multiple]="false">

      <ng-template ng-option-tmp ng-label-tmp let-item="item">
        <div class="custom-option">
          <div class="option-icon-wrap" *ngIf="item?.icon">
            <img class="option-icon" [src]="item.icon" />
          </div>
          <span class="option-label"><app-i18n-master-data [object]="item"></app-i18n-master-data></span>
        </div>
      </ng-template>
    </ng-select>
</ng-template>

<ng-template #epicTemplate [formGroup]="form">
  <ng-select
        formControlName="value"
        placeholder="{{'None' | translate}}"
        [items]="fieldOptions.epic"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="false">

        <ng-template ng-option-tmp ng-label-tmp let-item="item">
          <div class="custom-option">
            <span class="badge-label text-ellipsis" [ngStyle]="{'background-color': item.colorCode}">{{item.name}}</span>
          </div>
        </ng-template>
      </ng-select>
</ng-template>

<ng-template #releasesTemplate [formGroup]="form">
  <ng-select
        formControlName="value"
        placeholder="{{'None' | translate}}"
        [items]="fieldOptions.releases"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true">

        <ng-template ng-multi-label-tmp  let-items="items" let-clear="clear">
          <div class="custom-option mr-1" *ngFor="let item of items">
            <span class="badge-label text-ellipsis">{{item.name}}</span>
            <span class="material-icons btn-clear ng-value-icon" (click)="clear(item)"> clear </span>
          </div>
        </ng-template>
      </ng-select>
</ng-template>

<ng-template #sprintTemplate [formGroup]="form">
  <ng-select formControlName="value"
    placeholder="{{'None' | translate}}"
    [items]="fieldOptions.sprint"
    bindLabel="name"
    [searchable]="true"
    [clearable]="true"
    [multiple]="false">
  </ng-select>
</ng-template>

<ng-template #statusTemplate  [formGroup]="form">
  <ng-select class="task-item-select"
    formControlName="value"
    [items]="fieldOptions.status"
    [searchable]="true"
    [clearable]="false"
    [multiple]="false"
    bindLabel="name">

    <ng-template ng-option-tmp ng-label-tmp let-item="item">
      <div class="custom-option" *ngIf="item">
        <div class="custom-option">
          <img class="option-icon" [src]="item.icon"  *ngIf="item?.icon"/>
          <span class="option-label">{{item?.name}}</span>
        </div>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #ccTemplate [formGroup]="form">
  <ng-select formControlName="value" placeholder="{{'None' | translate}}" [items]="fieldOptions.cc"
    bindLabel="fullName" [searchable]="true" [clearable]="true" [multiple]="true" [typeahead]="userTypeahead$" >

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="custom-option mr-1" *ngFor="let item of items">
        <ng-container *ngIf="item">
          <app-avatar
            [url]="item.avatar"
            [name]="item.fullName"
          ></app-avatar>
          <span class="option-label">{{item.fullName}}</span>
          <span class="material-icons btn-clear ng-value-icon" (click)="clear(item)"> clear </span>
        </ng-container>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <div class="custom-option mr-1">
        <app-avatar
          [url]="item.avatar"
          [name]="item.fullName"
        ></app-avatar>
        <span class="option-label">{{item.fullName}}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #labelTemplate [formGroup]="form">
  <ng-select
        formControlName="value"
        placeholder="{{'None' | translate}}"
        [items]="fieldOptions.label"
        class="label-control"
        bindLabel="name"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true"
        [typeahead]="labelTypeahead$">

        <ng-template ng-multi-label-tmp  let-items="items" let-clear="clear">
          <div class="custom-option mr-1" *ngFor="let item of items">
            <span class="badge-label text-ellipsis" [style.backgroundColor]="item.colorCode">{{item.name}}</span>
            <span class="material-icons btn-clear ng-value-icon" (click)="clear(item)"> clear </span>
          </div>
        </ng-template>
      </ng-select>
</ng-template>

<ng-template #dateTemplate [formGroup]="form">
  <input formControlName="value" placeholder="{{'None' | translate}}" class="form-control" [placement]="'auto'" bsDatepicker
  [bsConfig]="bsConfig">
</ng-template>

<ng-template #platformTemplate [formGroup]="form">
  <ng-select formControlName="value"
    placeholder="{{'None' | translate}}"
    [items]="fieldOptions.platform"
    [searchable]="false"
    [clearable]="true"
    [multiple]="false">

    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      <div class="custom-option">
        <app-icon [icon]="item"></app-icon>
        <span class="ml-2">{{item.name}}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>

<ng-template #storylineTemplate [formGroup]="form">
  <ng-select
  formControlName="value"
  placeholder="{{'None' | translate}}"
  [items]="fieldOptions.storylines"
  bindLabel="name"
  [searchable]="true"
  [clearable]="true"
  [multiple]="false" [typeahead]="storylineTypeahead$">

  <ng-template ng-option-tmp ng-label-tmp let-item="item">
    <div class="custom-option" *ngIf="item">
      <img class="option-icon" [src]="item.type?.icon" />
      <span class="option-label"><span class="text-primary mr-1">{{item.key}}</span>{{item.name}}</span>
    </div>
  </ng-template>
</ng-select>
</ng-template>

<ng-template #followUpTemplate  [formGroup]="form">
  <ng-select
  formControlName="value"
  placeholder="{{'None' | translate}}"
  [items]="followUpItems"
  bindLabel="name"
  [searchable]="true"
  [clearable]="true"
  [multiple]="false">
  </ng-select>
</ng-template>
