import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { TaskComment, UNSAVED_CACHES, UNSAVED_COMMENT_KEY, UNSAVED_REPLY_COMMENT_KEY,
  UNSAVED_TASK_KEY, UNSAVED_USER_KEY } from 'src/app/shared';

@Pipe({ name: 'localComment' })

export class LocalCommentPipe implements PipeTransform {
  constructor(private authService: AuthService) { }
  transform(row: TaskComment, isReply: boolean = false) {
    const unsavedCache = JSON.parse(localStorage.getItem(UNSAVED_CACHES)) ?? {};
    const currentUserKey = UNSAVED_USER_KEY + this.authService.getLoggedUser()?.id;
    const currentTaskKey = UNSAVED_TASK_KEY + row?.task?.key;

    if (isReply) {
      const replyCommentKey = UNSAVED_REPLY_COMMENT_KEY + row.id;
      return unsavedCache?.[currentUserKey]?.[currentTaskKey]?.[replyCommentKey];
    }

    const commentKey = UNSAVED_COMMENT_KEY + row.id;
    return unsavedCache?.[currentUserKey]?.[currentTaskKey]?.[commentKey] || row.html || row.comment;
  }
}
