<div @fade class="p-4 bg-white shadow profile-overlay">
    <div class="row">
        <div class="col-12 custom-tabset mb-4">
            <div class="tab-container">
                <ul class="nav nav-tabs">
                    <li class="nav-item" [ngClass]="{ active: activeIndex === 1 }" (click)="onActive(1)">
                        <a class="nav-link" [ngClass]="{ active: activeIndex === 1 }">Profile Info</a>
                    </li>
                    <!-- <li class="nav-item" [ngClass]="{ active: activeIndex === 2 }" (click)="onActive(2)">
                        <a class="nav-link" [ngClass]="{ active: activeIndex === 2 }">Leave History</a>
                    </li> -->
                    <li class="nav-item" [ngClass]="{ active: activeIndex === 3 }" (click)="onActive(3)">
                        <a class="nav-link" [ngClass]="{ active: activeIndex === 3 }">Report History</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <app-profile [ngClass]="{ 'd-none': activeIndex !== 1 }" [user]="user$ | async"></app-profile>

    <app-leave-history
        *ngIf="activatedIndex[2] || activeIndex === 2"
        [ngClass]="{ 'd-none': activeIndex !== 2 }"
        [user]="user$ | async"
    ></app-leave-history>

    <app-user-report-history
        *ngIf="activatedIndex[3] || activeIndex === 3"
        [ngClass]="{ 'd-none': activeIndex !== 3 }"
        [userId]="(user$ | async)?.id"
    >
    </app-user-report-history>
</div>
