import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AbstractComponent, SharingItemResponse, SharingType } from '../..';

export interface TargetSharePayload {
  permission?: string[];
  target?: SharingItemResponse[];
}

@Component({
  selector: 'app-share-target',
  templateUrl: './share-target.component.html',
  styleUrls: ['./share-target.component.scss']
})
export class ShareTargetComponent extends AbstractComponent {
  @Input() shareTargets: SharingItemResponse[];
  @Input() sharePermissions: string[];
  @Input() defaultPermission: string;
  @Output() add = new EventEmitter<TargetSharePayload>();
  @Output() search = new EventEmitter<string>();

  form: UntypedFormGroup;
  SharingType = SharingType;
  userTypeahead$ = new Subject<string>();

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  init() {
    this.createForm();
    this.userTypeahead$.pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(value => {
        this.search.emit(value);
      });
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      target: [null, [Validators.required]],
      permission: [this.defaultPermission, [Validators.required]]
    });
  }

  onAdd() {
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const rawValue = this.form.getRawValue();
    const payload: TargetSharePayload = {
      permission: rawValue.permission,
      target: rawValue.target
    };

    this.add.emit(payload);
    this.form.get('target').setValue([]);
    this.form.get('target').markAsUntouched();
  }

  removeItem(item: SharingItemResponse) {
    const targetValue = this.form.get('target').value;
    const index = targetValue.findIndex(el => el?.objectId === item?.objectId);
    if (index !== -1) {
      targetValue.splice(index, 1);
    }
    this.form.get('target').setValue(targetValue);
  }
}
