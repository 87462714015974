import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-crop-image-popup',
  templateUrl: './crop-image-popup.component.html',
  styleUrls: ['./crop-image-popup.component.scss']
})
export class CropImagePopupComponent implements OnInit {
  title = '';
  imageFile: File;
  maintainAspectRatio = true;
  onlyScaleDown = true;
  resizeToWidth = 0; // disabled
  resizeToHeight = 0; // disabled
  croppedImage: ImageCroppedEvent = null;
  errorLoadingImage = '';
  croppedImage$: Subject<File> = new Subject<File>();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  loadImageFailed() {
    this.errorLoadingImage = 'Load image failed. Please change image and try again. Only png, jpge are supported.';
  }

  onNoClick() {
    this.croppedImage$.next(null);
    this.closeModal();
  }

  onYesClick() {
    // const rawData = this.croppedImage.base64.substring(this.croppedImage.base64.indexOf(',') + 1);
    // const blob = this.b64toBlob(rawData, this.imageFile.type);
    const blob = this.croppedImage.blob;
    const file = new File([blob], this.imageFile.name, { type: this.imageFile.type });
    this.croppedImage$.next(file);
    this.closeModal();
  }

  closeModal() {
    if (!this.bsModalRef) {
      return;
    }

    this.bsModalRef.hide();
    this.bsModalRef = null;
  }

  /**
  * Convert a base64 string in a Blob according to the data and contentType.
  *
  * @param b64Data Pure base64 string without contentType
  * @param contentType the content type of the file i.e (image/jpeg - image/png - text/plain)
  * @param sliceSize SliceSize to process the byteCharacters
  * @return Blob
  */
  b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
