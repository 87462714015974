<app-modal-container #modal>
  <ng-container header><app-i18n [key]="object ? 'Update Record' : 'Create Record'"></app-i18n></ng-container>

  <ng-container body>
    <div class="dynamic-field-upsert-wrapper">
      <app-d-field
        [fields]="fields"
        (fieldsChange)="onFieldsChange($event)">
      </app-d-field>
    </div>
  </ng-container>

  <ng-container footer>
    <button type="button" class="btn btn-white mr-3" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-primary" (click)="onSubmit()"><app-i18n [key]="'Save'"></app-i18n></button>
  </ng-container>
</app-modal-container>
