import { Overlay, OverlayConfig, OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, ElementRef, Optional, Inject } from '@angular/core';
import { PositionOverlay } from 'src/app/shared';
import { UntypedFormControl } from '@angular/forms';
import { DateTimeInputComponent } from 'src/app/shared/_components/date-time-input/date-time-input.component';
import { DATE_TIME_PICKER_CONFIG, DateTimePickerConfig } from './token/date-time-picker.token';

@Injectable({ providedIn: 'root' })
export class DateTimePickerOverlayService {
  overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayContainer: OverlayContainer,

    @Optional()
    @Inject(DATE_TIME_PICKER_CONFIG)
    private config: DateTimePickerConfig
  ) {}

  open(elementRef: ElementRef, control: UntypedFormControl, title: string) {
    this.overlayRef?.dispose(); // clean profile overlay when open new overlay
    this.overlayRef = this.overlay.create(this.getConfig(elementRef)); // create overlay with config
    const overlayContainerElement = this.overlayContainer.getContainerElement();
    overlayContainerElement.classList.add('date-time-picker-container'); // add class to cdk container to css
    const ref = this.overlayRef.attach(new ComponentPortal(DateTimeInputComponent));
    ref.instance.control = control;
    ref.instance.title = title;
  }

  hide() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  getConfig(elementRef: ElementRef): OverlayConfig {
    const positionPair = this.config?.position || [
      PositionOverlay.bottomRight,
      PositionOverlay.topRight,
      PositionOverlay.topLeft,
      PositionOverlay.bottomLeft
    ];
    return {
      width: 'max-content',
      height: 'max-content',
      maxHeight: 'auto',
      hasBackdrop: false,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(elementRef)
        .withPositions(positionPair)
        .withFlexibleDimensions(true)
        .withViewportMargin(40)
        .withGrowAfterOpen(true)
        .withPush(false),
      panelClass: 'date-time-picker-panel'
    };
  }
}
