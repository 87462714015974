import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { UpsertComponent } from '../_base-component/upsert.component';
import { AdvanceExpFilter, AdvanceFilter, AdvanceFilterObject, AdvanceFilters, DATE_FORMAT, FieldCompareData,
  FieldCompareValue, FieldNullValue, FieldSuggestion, FilterBy, KeyFilterBy, LogicOperator, MultiOperatorFields,
  QueryToken, TaskFieldDetail, datePickerFilters } from '../..';
import { TaskService } from 'src/app/site-management/tasks/_services/task.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { AdvanceFilterService } from 'src/app/site-management/tasks/task-list/_services/advance-filter.service';
import { TaskFilterService } from 'src/app/site-management/tasks/task-list/_services/task-filter.service';
import { TaskFilterRequest } from 'src/app/site-management/tasks/task-list/_models/task-filter.models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-advance-filter',
  templateUrl: './advance-filter.component.html',
  styleUrls: ['./advance-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvanceFilterComponent extends UpsertComponent {
  @Input() filterBy: FilterBy;
  @Output() filterSaved = new EventEmitter<any>();

  initAdvanceFilter: AdvanceFilter = {
    logicOperator: LogicOperator.AND,
    expr: [
      {
        lhs: null,
        operator: QueryToken.EQUAL,
        rhs: null
      }
    ],
  };
  advanceFilters!: AdvanceFilter;
  LogicOperator = LogicOperator;

  compareFields: string[] = [];
  operatorFields!: FieldCompareData;
  valueFields!: FieldCompareData;
  bsConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: DATE_FORMAT,
    rangeInputFormat: DATE_FORMAT,
  };
  filterId: number = null;
  changed = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private taskService: TaskService,
    private advanceFilterService: AdvanceFilterService,
    private taskFilterService: TaskFilterService,
    private route: ActivatedRoute) {
    super(formBuilder);
    this.filterId = this.route?.snapshot?.queryParams?.filterId ?? null;
  }

  init() {
    super.init();
    this.initFilter();
    this.operatorFields = FieldCompareValue;
    this.valueFields = JSON.parse(JSON.stringify(FieldCompareValue));
  }

  getCompareField(field: string = '', prefix: string = '', ) {
    field = field ?? '';
    this.taskService.getFieldDetail(field).pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        this.compareFields = [];
        // Validate copied value
        if (!value?.childs && value?.operators) {
          this.compareFields.push(field);
          return;
        }

        if (value?.childs) {
          value.childs.forEach(child => {
            this.compareFields.push(`${prefix}${child.name}`);
          });
        }

        if (field.length > 0) {
          this.getOperatorField(value, field);
          this.getValueField(field);
        }
      });
  }

  getOperatorField(taskDetail: TaskFieldDetail, field: string) {
    this.operatorFields[field] = taskDetail?.operators;
  }

  getValueField(field: string) {
    this.taskService.getFieldValue({field, includeId: false}, {page: 0, size: 100})
      .pipe(takeUntil(this.destroyed$))
      .subscribe(value => {
        if (value) {
          let tempValue = [];
          tempValue.push(FieldNullValue);
          tempValue = [
            ...tempValue,
            ...value.map(item => item?.value ?? item?.name ?? item.id ?? '')
          ];

          // Flatten array
          tempValue = [].concat.apply([], tempValue);
          // Filter duplicates
          this.valueFields[field] = tempValue.filter((item, index, self) => self.indexOf(item) === index);
        }
      });
  }

  loadOperatorField(conditionRow: AdvanceExpFilter) {
    // load operator for the first time in case: navigated from user dashboard
    if (!this.operatorFields[conditionRow.lhs] || this.operatorFields[conditionRow.lhs].length === 0) {
      this.taskService.getFieldDetail(conditionRow.lhs).pipe(takeUntil(this.destroyed$))
        .subscribe(value => {
          this.getOperatorField(value, conditionRow.lhs);
        });
    }
  }

  loadValueField(conditionRow: AdvanceExpFilter) {
    // load value field for the first time in case: navigated from user dashboard
    if (!this.valueFields[conditionRow.lhs] || this.valueFields[conditionRow.lhs].length === 0) {
      this.getValueField(conditionRow.lhs);
    }
  }

  isDatePickerField(field: string) {
    return datePickerFilters.find(filter => filter === field);
  }

  dateUpdated(conditionRow: AdvanceExpFilter, date: Date | null) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      conditionRow.rhs = moment(date).format(DATE_FORMAT);
    } else {
      conditionRow.rhs = null;
    }
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.object?.name ?? null, [Validators.required]],
      description: [this.object?.description ?? null],
    });
  }

  initFilter() {
    if (this.object?.filter) {
      this.advanceFilters = this.object.filter;
    } else {
      this.advanceFilters = JSON.parse(JSON.stringify(this.initAdvanceFilter));
    }
  }

  isPossibleOperator(row: AdvanceFilter, operator: LogicOperator) {
    if (operator === LogicOperator.NOT) {
      if ((row.expr.length > 0 && row?.child) || row.expr.length > 1 || (row?.child && row.child.length > 1)) {
        return false;
      }
    }
    return true;
  }

  canAddFilter(row: AdvanceFilter) {
    if (row.logicOperator === LogicOperator.NOT) {
      return row.expr.length < 1 && !row?.child;
    }
    return true;
  }

  addNewFilterRow(row: AdvanceFilter, xql: boolean = false) {
    const emptyExpr = {
      lhs: null,
      operator: QueryToken.EQUAL,
      rhs: null
    };
    if (xql) {
      row.expr.push({xql: null});
    } else {
      row.expr.push(emptyExpr);
    }
    this.onDataChanged();
  }

  canRemoveFilterRow(row: AdvanceFilter, parent: AdvanceFilter) {
    return row.expr.length > 1 || parent;
  }

  removeFilterRow(row: AdvanceFilter, conditionRow: AdvanceExpFilter, parent: AdvanceFilter) {
    const index = row.expr.findIndex(item => item === conditionRow);
    row.expr.splice(index, 1);
    if (row.expr.length === 0 && parent) {
      const childIndex = parent.child.findIndex(item => item === row);
      parent.child.splice(childIndex, 1);
    }
    if (parent && parent.child.length === 0) {
      parent.child = undefined;
    }
    this.onDataChanged();
  }

  addNewConditionGroup(currentRow: AdvanceFilter) {
    if (!currentRow.child) {
      currentRow.child = [];
    }
    currentRow.child.push(JSON.parse(JSON.stringify(this.initAdvanceFilter)));
    this.onDataChanged();
  }

  transferKey(key: string) {
    if (key === KeyFilterBy.TASK_TYPE) {
      return FieldSuggestion.TYPE;
    }
    if (key === KeyFilterBy.TASK_STATUS) {
      return FieldSuggestion.STATUS;
    }
    if (key === KeyFilterBy.TASK_KEY) {
      return FieldSuggestion.KEY;
    }
    if (key === KeyFilterBy.TEAM) {
      return FieldSuggestion.TEAM;
    }
    if (key === KeyFilterBy.GROUP) {
      return FieldSuggestion.GROUP;
    }
  }

  updateFieldCompare(conditionRow: AdvanceExpFilter) {
    this.getCompareField(conditionRow.lhs, '');
    // Reset value field
    conditionRow.rhs = null;
    this.onDataChanged();
  }

  onTypingCompareField(object) {
    const searchText = object.term;
    // Get the next suggestion when typing '.'
    if (searchText[searchText.length - 1] === QueryToken.DOT) {
      const currentField = searchText.slice(0, -1);
      this.getCompareField(currentField, searchText);
    } else if (searchText.length === 0) {
      this.getCompareField();
    } else {
      // Case search field is not in the current data
      if (this.compareFields.findIndex(field => field === searchText) === -1) {
        this.getCompareField(searchText);
      }
    }
  }

  isMultiField(operator: string) {
    return MultiOperatorFields.includes(operator);
  }

  onSubmit(saveFilter: boolean = false) {
    this.advanceFilterService.removeEmptyRow(this.advanceFilters);
    this.markAllTouched(this.advanceFilters);

    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const formValue = this.form.getRawValue();
    const filterObject = {
      name: formValue.name,
      description: formValue.description,
      filter: this.advanceFilters
    };

    if (this.isValidFilter(this.advanceFilters)) {
      if (saveFilter) {
        this.handleSaveFilter(filterObject);
      }
      this.objectResponse.next(filterObject);
    }
  }

  markAllTouched(advanceFilters: AdvanceFilter) {
    advanceFilters.expr.forEach(el => {el.touched = true; });
    if (advanceFilters?.child) {
      advanceFilters.child.forEach(el => {
        this.markAllTouched(el);
      });
    }
  }

  isValidFilter(advanceFilters: AdvanceFilter, valid = true) {
    if (!valid) {
      return false;
    }
    advanceFilters.expr.forEach(el => {
      if (!datePickerFilters.includes(el.lhs)) {
        if (!el.lhs || !el.rhs) {
          valid = false;
        }
      }
    });

    if (advanceFilters?.child) {
      advanceFilters.child.forEach(el => {
        valid = this.isValidFilter(el, valid);
      });
    }

    return valid;
  }

  handleSaveFilter(filterObject: AdvanceFilterObject) {
    const advanceObj = new AdvanceFilters(filterObject.filter);
    const payload = {
      name: filterObject.name,
      description: filterObject.description,
      expression: advanceObj.getFinalQuery()
    };

    if (this.filterId) {
      this.editFilter(payload);
    } else {
      this.saveFilter(payload);
    }
  }

  saveFilter(payload: TaskFilterRequest) {
    this.taskFilterService.saveFilter(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: res => {
          this.filterSaved.emit(res);
          this.toast.success('Saved Filter Successfully!');
        },
        error: (error: string) => this.errorFn(error)
      });
  }

  editFilter(payload: TaskFilterRequest) {
    this.taskFilterService.updateFilter(this.filterId, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: res => {
          this.filterSaved.emit(res);
          this.toast.success('Edited Filter Successfully!');
        },
        error: (error: string) => this.errorFn(error),
      });
  }

  onDataChanged() {
    this.changed = true;
  }
}
