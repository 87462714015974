import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  @Input() placeholder = 'Drop files to attach, or browse.';
  @Output() selectFile: EventEmitter<File[]> = new EventEmitter<File[]>();
  constructor() { }

  ngOnInit(): void {
  }

  onFileChanged(files: File[]) {
    this.selectFile.emit(files);
  }

}
