<div class="d-tab-wrapper">
  <ng-container *ngFor="let section of sections">
    <ng-container *ngIf="section.isVisible">
      <ng-container *ngIf="section?.hash; else sectionDynamic">
        <ng-container *ngTemplateOutlet="section.template"></ng-container>
      </ng-container>
      <ng-template #sectionDynamic>
        <div class="d-section-wrapper">
          <div class="section-header">
            <div class="section-icon" *ngIf="section.icon">
              <mat-icon class="icon-x" [svgIcon]="section.icon"></mat-icon>
            </div>
            <div class="section-title fw-6">
              <span>{{section?.name}}</span>
            </div>
          </div>
          <div class="section-content">
            <app-d-field [fields]="sectionFields[section.id]" (fieldChange)="onFieldChange($event)">
            </app-d-field>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
