<div class="d-field-file-wrapper">
  <div>
    <app-upload-file (selectFile)="selectFile($event)"></app-upload-file>
  </div>

  <div class="file-list mt-2" *ngIf="field.value?.file?.length > 0 || uploadFile.length > 0">
    <ng-container *ngFor="let file of field.value?.file; let index=index">
      <div class="file-item pointer w-100" (click)="onPreviewFile(file, index)">
        <div class="d-flex file-name">
          <file-icon class="file-icon"
            [set]="'square-o'"
            [size]="'md'"
            [type]="getUrlExtension(file.name)">
          </file-icon>
          <div class="ml-2 text-ellipsis">
            <div class="d-flex flex-wrap">
              <span class="mr-2 text-ellipsis">{{file.name}}</span>
              <span class="text-gray text-ellipsis">
                <span>{{getLastModified(file.createdAt)}}</span>
              </span>
            </div>
          </div>
        </div>
        <button class="btn btn-light btn-icon btn-sm circle" (click)="removeOldFile(index)">
          <span class="material-icons">close</span>
        </button>
      </div>
    </ng-container>

    <div class="file-item opacity-50 w-100" *ngFor="let file of uploadFile; let index=index"
      [tooltip]="'This file is pending for saving'"
      [placement]="'top left'">
      <div class="d-flex file-name">
        <file-icon class="file-icon"
          [set]="'square-o'"
          [size]="'md'"
          [type]="getUrlExtension(file.name)">
        </file-icon>
        <div class="ml-2 text-ellipsis">
          <div class="d-flex flex-wrap">
            <span class="mr-2 text-ellipsis">{{file.name}}</span>
          </div>
        </div>
      </div>
      <button class="btn btn-light btn-icon btn-sm circle ml-3" (click)="removeUploadFile(index)">
        <span class="material-icons">close</span>
      </button>
    </div>
  </div>
</div>
