<div #dateTimePicker class="date-time-wrapper">
  <div class="d-flex">
    <bs-datepicker-inline [bsValue]="datePickerValue" (bsValueChange)="onDateChange($event)" [bsConfig]="bsConfig"></bs-datepicker-inline>
    <div class="time-picker">
      <app-time-picker [title]="title" [control]="control" (timeChangeValue)="onTimeChange($event)"></app-time-picker>
    </div>
  </div>
  <div class="mt-2 d-flex justify-content-end">
    <button type="button" class="btn btn-white mr-3" (click)="onCancel()"><app-i18n [key]="'Cancel'"></app-i18n></button>
    <button type="submit" class="btn btn-primary" [disabled]="isDisabled" appRestrictMultiSubmit (multiClick)="onSave()"><app-i18n [key]="'Save'"></app-i18n></button>
  </div>
</div>
