import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app.module';

export function translateExtension(field: FormlyFieldConfig) {
  const translateService = AppInjector.get(TranslateService);

  if (!field?.props) {
    return;
  }

  const { placeholder, label } = field.props;

  field.props = {
    ...field.props,
    placeholder: placeholder ? translateService.instant(placeholder) : '',
    label: label ? translateService.instant(label) : '',
  };
}
