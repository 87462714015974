import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FileUtilService {
  constructor() {}

  async resizeImage(file: File, height: number, width: number): Promise<File> {
    return new Promise((resolve, reject) => {
      // Convert file to blob
      const blob = new Blob([file], { type: file.type });

      // Create an image element and load the blob as the image source
      const img = new Image();
      img.src = URL.createObjectURL(blob);

      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set the canvas dimensions to new width and height
      canvas.width = width;
      canvas.height = height;

      // wait for the image to load, then draw it onto the canvas and resize it to fit
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((resizedBlob) => {
          const finalFile = new File([resizedBlob], file.name, { type: file.type });
          resolve(finalFile);
        }, blob.type, 1);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  }
}