import { Policy } from "..";

export interface BenefitRule {
  id:          number;
  key:         string;
  name:        string;
  description: string;
  value:       number;
  policies:    Policy[];
  type:        string;
  variable:    string;
  applyCondition: string;
}

export interface BenefitRuleVariable {
  variable: string;
}
