import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() patternMessage = 'Invalid value!';
  @Input() maxLengthMessage = 'Too long';
  @Input() minMessage = 'Invalid value!';
  @Input() errors: string[];
  @Input() customError: {
    error: string;
    message: string;
  };
  constructor() { }

  ngOnInit(): void {
  }

}
