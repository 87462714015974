import { Directive, Output, EventEmitter, HostBinding, HostListener, ElementRef } from '@angular/core';
import { TreeFolder } from 'src/app/shared';

@Directive({
  selector: '[upload-folder]'
})
export class UploadFolderDirective {
  @Output() folderChange = new EventEmitter();

  constructor(private el: ElementRef) {}

  @HostListener('change', ['$event']) onChange(event: any) {
    const files: FileList = event.target?.files;
    const tree: TreeFolder = this.buildTreeFromFile(files);
    this.folderChange.emit(tree);
  }

  private buildTreeFromFile(files: FileList) {
    const tree: TreeFolder = { name: '', files: [], directories: [] };
    for (const file of files) {
      this.buildTree(file, tree);
    }
    return tree;
  }

  private buildTree(file: File, tree: TreeFolder,  dir?: string[]) {
    if (!dir) {
      dir = file.webkitRelativePath?.split('/');
    }
    if (dir?.length > 1) {
      const dirName = dir[0];

      let current = tree.directories.find(e=> e.name === dirName);
      if (!current) {
        current = { name: dirName , files: [], directories: [] };
        tree.directories.push(current);
      }

      this.buildTree(file, current, dir.slice(1));
      return;
    }
    tree.files.push(file);
  }

}
