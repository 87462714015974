import { Pipe, PipeTransform } from '@angular/core';
import { UnitOfTime } from 'src/app/shared/_utils';
import { TimeFormat, TimeObject } from '../../time-picker/models/time-picker.model';

@Pipe({
  name: 'getSelectedTime'
})

export class GetSelectedTimePipe implements PipeTransform {
  transform(selectedTime: TimeFormat, time: TimeObject) {
    switch (time.type) {
      case UnitOfTime.hours:
        return time.value === selectedTime?.hour;
      case UnitOfTime.minutes:
        return time.value === selectedTime?.minute;
      case UnitOfTime.periods:
        return time.value === selectedTime?.period;
      default:
        return false;
    }
  }
}
