import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { FileAttachment, FileLink } from 'src/app/shared/_models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  uploadImage(file: File): Observable<FileLink> {
    const formData = new FormData();
    formData.append('file', file);

    this.loading$.next(true);
    return this.http.post<FileLink>(`${environment.apiUrl}/storage/uploadImage`, formData).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  uploadFile(file: File): Observable<HttpEvent<FileAttachment>> {
    const formData = new FormData();
    formData.append('file', file);

    this.loading$.next(true);
    return this.http.post<FileAttachment>(`${environment.apiUrl}/storage/uploadFile`,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      }
    ).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  deleteFile(url: string): Observable<any> {
    const formData = new FormData();
    formData.append('url', url);

    this.loading$.next(true);
    return this.http.delete<any>(`${environment.apiUrl}/storage/delete-file?url=${url}`)
      .pipe(
        catchError(this.errorMgmt),
        finalize(() => this.loading$.next(false)),
      );
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

}
