import { Pipe, PipeTransform } from '@angular/core';
import { FieldHistory } from 'src/app/shared';

@Pipe({
  name: 'isTaskTimeField'
})

// Input: History field
// Output: Time fields || null
export class IsTaskTimeFieldPipe implements PipeTransform {
  transform(field: string): string {
    const fields = [FieldHistory.startTime, FieldHistory.endTime];
    return fields.find(el => el === field);
  }
}
