import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormat } from 'src/app/shared';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, format: NumberFormat = NumberFormat.DEFAULT): string {
    if (isNaN(value)) {
      return value;
    }

    const num = Number(value);

    if (format === NumberFormat.TWODECIMAL) {
      return this.customFormat(num, { useGrouping: false });
    }

    if (format === NumberFormat.INTEGER) {
      return this.customFormat(num, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false
      });
    }

    if (format === NumberFormat.ACCOUNTING) {
      let ACCOUNTING = this.customFormat(Math.abs(num), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return num >= 0 ? ACCOUNTING : `(${ACCOUNTING})`;
    }

    if (format === NumberFormat.ACCOUNTING_RED) {
      let ACCOUNTING_RED = this.customFormat(Math.abs(num));
      return num >= 0 ? ACCOUNTING_RED : `(${ACCOUNTING_RED})`;
    }

    if (format === NumberFormat.PERCENTAGE_TWODECIMAL) {
      return this.customFormat(num * 100, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false
      }) + '%';
    }

    if (format === NumberFormat.PERCENTAGE) {
      return this.customFormat(num * 100, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: false
      }) + '%';
    }

    if (format === NumberFormat.CURRENCY_RED) {
      let CURRENCY_RED = '$' + this.customFormat(Math.abs(num));
      return num >= 0 ? CURRENCY_RED : `(${CURRENCY_RED})`;
    }

    // format 1
    return this.customFormat(num);
  }

  customFormat = (
    value: number,
    {
      minimumFractionDigits = 2,
      maximumFractionDigits = 2,
      useGrouping = true
    } = {}
  ) => {
    const formattedValue = value.toLocaleString('en-US', {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
      useGrouping: useGrouping
    });

    return formattedValue;
  };
}
