import { Component, Input } from '@angular/core';
import { AbstractComponent } from '../_base-component';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent  extends AbstractComponent {
  @Input() control: UntypedFormControl;

  constructor() {
    super();
  }

  clearDateInput() {
    this.control.reset();
  }
}
