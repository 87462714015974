import { Component, Input, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { AbstractComponent, EmojiComment, MAXIMUM_EMOJI_AMOUNT, User } from '../..';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { EmojiUsersOverlayService } from 'src/app/site-management/_services';

@Component({
  selector: 'app-emoji-comment',
  templateUrl: './emoji-comment.component.html',
  styleUrls: ['./emoji-comment.component.scss'],
})
export class EmojiCommentComponent extends AbstractComponent {
  @Input() emojiComment: EmojiComment;
  @Output() selectedEmoji = new EventEmitter<string>();
  @ViewChild('pickerTrigger') pickerTrigger: ElementRef;
  @ViewChild('dropdownTrigger') dropdownTrigger: ElementRef;

  showEmojiPicker = false;
  showEmojiUsers = false;
  emojiLists: string[] = [];
  loggedUser!: User;
  MAXIMUM_EMOJI_AMOUNT = MAXIMUM_EMOJI_AMOUNT;

  constructor(
    private authService: AuthService,
    private emojiUsersOverlay: EmojiUsersOverlayService
  ) {
    super();
    this.loggedUser = this.authService.getLoggedUser();
  }

  init() {
    this.setupEmojiData();
  }

  setupEmojiData() {
    this.emojiLists = this.emojiComment ? Object.keys(this.emojiComment).map(key => key) : [];
  }

  addEmoji(event) {
    this.selectedEmoji.next(event);
    this.showEmojiPicker = false;
    this.hideUsersDropdown();
    this.setupEmojiData();
  }

  getTotalEmoji(emojiCode: string) {
    return this.emojiComment[emojiCode]?.totalUser;
  }

  isMyEmoji(emojiCode: string) {
    return this.emojiComment[emojiCode].users.find(user => user.id === this.loggedUser.id);
  }

  isExistedMe(user: User) {
    return user.id === this.loggedUser.id;
  }

  showUsersDropdown(element: ElementRef, users: User[]) {
    this.emojiUsersOverlay.open(element, users);
  }

  hideUsersDropdown() {
    this.emojiUsersOverlay.hide();
  }
}
