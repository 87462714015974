import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavbarService {
  private templateRefSubject = new BehaviorSubject<TemplateRef<any>>(null);
  templateRef$ = this.templateRefSubject.asObservable();

  constructor() { }

  getTemplateRef() {
    return this.templateRefSubject.getValue();
  }

  changeTemplate(template: TemplateRef<any>) {
    this.templateRefSubject.next(template);
  }
}
