import { Component } from '@angular/core';
import { UtilService } from 'src/app/core';
import { DFieldCellBaseTableComponent } from '../d-field-cell-base-table';

@Component({
  selector: 'app-d-field-cell-date-table',
  templateUrl: './d-field-cell-date-table.component.html',
  styleUrls: ['./d-field-cell-date-table.component.scss']
})
export class DFieldCellDateTableComponent extends DFieldCellBaseTableComponent{

  cellDate: string;

  constructor() {
    super();
  }

  init() {
    super.init();
    this.cellDate = this.getDataCell() as string;
  }

  getDateUI() {
    return UtilService.getDateUI(this.cellDate);
  }
}
