import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { List2Res, Pageable, PageableModel, TaskType } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import * as queryString from 'query-string';

@Injectable({
  providedIn: 'root'
})
export class ProjectTaskTypeService {
  public loading$ = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  get(projectId: number, searchParams: PageableModel): Observable<List2Res<TaskType>> {
    const params = queryString.stringify({...searchParams});
    this.loading$.next(true);
    return this.http
      .get<List2Res<TaskType>>(`${environment.apiUrl}/projects/${projectId}/task-types/?${params}`)
      .pipe(finalize(() => this.loading$.next(false)));
  }

  edit(projectId: number, taskTypeId: number, body: TaskType) {
    return this.http.put(`${environment.apiUrl}/projects/${projectId}/task-types/${taskTypeId}`, body);
  }

  post(projectId: number, body: TaskType) {
    const postUrl = `${environment.apiUrl}/projects/${projectId}/task-types/`;
    return this.http.post(postUrl, body);
  }
}
