import { NgControl } from '@angular/forms';
import { Input, Directive } from '@angular/core';

@Directive({
  selector: '[disableIf]'
})
export class DisableIfDirective {

  @Input() set disableIf(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control[action]();
  }

  constructor(private ngControl: NgControl) {
  }

}
