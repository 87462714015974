import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AbstractComponent, AppInit } from './abstract.component';

export interface FormUpsert {
  createForm();
  onSubmit();
  onCancel();
}


@Directive()
export class UpsertComponent<T = any> extends AbstractComponent implements AppInit, FormUpsert {
  @Input() object: T;
  @Output() objectResponse = new EventEmitter<T>();
  @Output() deleted = new EventEmitter<T>();
  @Input() form: UntypedFormGroup;

  constructor(
    protected fb: UntypedFormBuilder,
  ) {
    super();
  }

  get formValue() {
    return this.form.value;
  }

  init(): void {
    this.createForm();
  }

  createForm() {
    if (!this.form) {
      this.form = this.fb.group({});
    }
  }

  getFormCtrl(controlName: string): UntypedFormControl {
    return this.form.get(controlName) as UntypedFormControl;
  }

  onSubmit() {
    if (this.form) {
      this.form.updateValueAndValidity();
      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }
    }

    this.submit();
  }

  submit() {
    if (this.object) {
      this.edit();
    } else {
      this.add();
    }
  }

  add() { }

  edit() { }

  deleteSuccess() {
    this.deleted.emit(this.object);
  }

  onCancel() {
    this.objectResponse.next(null);
  }
}
