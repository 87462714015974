import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalContainerComponent } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import { ToastService } from 'src/app/core';
import { PasswordValidator } from 'src/app/core/_validators/password.validator';
import { UserService } from 'src/app/site-management/user/_services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]]
      },
      {
        validators: PasswordValidator.Matching()
      }
    );
  }

  onSubmit(modal: ModalContainerComponent) {
    const { currentPassword, password: newPassword } = this.form.value;

    const payload = {
      currentPassword,
      newPassword
    };

    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.loading = true;
      this.userService.changePassword(payload, this.auth.loggedUser.id).subscribe(
        () => {
          this.loading = false;
          this.toast.success('Change password successfully');
          modal.hide();
        },
        (err) => {
          this.loading = false;
          const httpError = JSON.parse(err);
          this.toast.error(httpError?.message || 'Cannot change password');
        }
      );
    }
  }
}
