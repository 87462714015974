import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataTableSelectionService {
  public isPageSelected(rows: any[], selected: any[]) {
    if (!rows || rows.length <= 0 ){
      return false;
    }

    const selectedIds = selected.map(e => e.id);
    const rowIds = rows.map(e => e.id);

    return rowIds.every(e => {
      return selectedIds.indexOf(e) >= 0;
    });
  }

  public clickAllCheckbox(checked: boolean, rows: any[], selected: any[]) {
    for (const item of rows) {
      this.clickRowCheckbox(checked, item, selected);
    }
  }

  public clickRowCheckbox(checked: boolean, item: any, selected: any[]) {
    if (checked) {
      this.addSelection(item, selected);
    } else {
      this.deleteSelection(item, selected);
    }
  }

  public addSelection(item: any, selected: any[]) {
    const idx = selected.findIndex(e => e.id === item.id);
    if (idx < 0) {
      selected.push(item);
    }
  }

  public deleteSelection(item: any, selected: any[]) {
    const idx = selected.findIndex(e => e.id === item.id);
    if (idx >= 0) {
      selected.splice(idx, 1);
    }
  }
}
