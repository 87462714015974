import { Component, Input, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { takeUntil } from 'rxjs';
import { ProjectBoardStatusService } from 'src/app/site-management/projects/_services';
import { AbstractComponent } from '../_base-component';

@Component({
  selector: 'app-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent extends AbstractComponent {
  @ViewChild('statusDropdown') statusDropdown: NgSelectComponent;

  @Input() projectId : number;
  @Input() name : string;
  @Input() icon : string;
  @Input() status: any;
  @Input() statusOption: any;
  @Input() isSmallWidth: boolean;

  constructor(
    private projectService: ProjectBoardStatusService
  ) {
    super();
  }
  
  openStatusDropdown() {
    if (this.status.length <= 0) {
      this.getStatus();
    }
    this.statusDropdown?.toggle();
  }

  onChangeStatus() {}

  compareWith(item, selected) {
    return item.id === selected.id;
  }

  getStatus() {
    this.projectService.get(this.projectId, { page: 0, size: 1000 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.status = res.content;
        },
        (error) => {
          this.status = [];
        }
      );
  }
}
