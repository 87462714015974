import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { debounceTime, skip, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appRestrictMultiSubmit]'
})
export class RestrictMultiSubmitDirective {
  @Output() multiClick = new EventEmitter();

  submit$ = new BehaviorSubject(false);
  submitSubscription: Subscription;
  DELAY_TIME = 2500;

  constructor() {
    this.submitSubscription?.unsubscribe();
    this.submitSubscription = this.submit$.pipe(skip(1), throttleTime(this.DELAY_TIME)).subscribe(e => {
      this.submit();
    });
  }

  submit() {
    this.multiClick.emit();
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.submit$.next(true);
  }
}
