import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, ComponentRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PositionOverlay } from 'src/app/shared';
import { TimePickerInputComponent } from 'src/app/shared/_components/time-picker-input/time-picker-input.component';

@Injectable({ providedIn: 'root' })
export class TimePickerOverlayService {
  overlayRef: OverlayRef;
  componentRef: ComponentRef<TimePickerInputComponent>;

  constructor(
    private overlay: Overlay,
  ) {}

  open(elementRef: Element, control: UntypedFormControl, defaultValue: string) {
    this.overlayRef?.dispose(); // clean profile overlay when open new overlay
    this.overlayRef = this.overlay.create(this.getConfig(elementRef)); // create overlay with config
    this.componentRef = this.overlayRef.attach(new ComponentPortal(TimePickerInputComponent));
    this.componentRef.instance.formControl = control;
    this.componentRef.instance.defaultValue = defaultValue;
    this.overlayRef
      .outsidePointerEvents()
      .pipe()
      .subscribe((res) => {
        this.hide();
      });
  }

  hide() {
    if (this.overlayRef) {
      this.overlayRef.dispose();

    }
  }

  getConfig(elementRef: Element): OverlayConfig {
    const positionPair = [
      PositionOverlay.bottomCenter,
      PositionOverlay.topCenter,
    ];

    return {
      width: 'max-content',
      height: 'max-content',
      maxHeight: 'auto',
      hasBackdrop: false,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(elementRef)
        .withPositions(positionPair)
        .withFlexibleDimensions(true)
        .withViewportMargin(40)
        .withGrowAfterOpen(true)
        .withPush(false),
    };
  }
}
