import { Component, Input } from '@angular/core';
import { DATE_FORMAT_SHORT, Task } from '../..';

@Component({
  selector: 'app-task-due-date',
  templateUrl: './task-due-date.component.html',
  styleUrls: ['./task-due-date.component.scss']
})
export class TaskDueDateComponent {
  @Input() task: Task;
  DATE_FORMAT_SHORT = DATE_FORMAT_SHORT;

  constructor() { }
}
