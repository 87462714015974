import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Pageable } from 'src/app/shared';


@Injectable({
    providedIn: 'root'
})
export class DataFilterService<T extends Pageable> {

    protected searchParam: T;

    get CurrentSearchParam() {
        if (this.searchParam) {
            const cloneParam = {};
            Object.assign(cloneParam, this.searchParam);
            return cloneParam as T;
        }
        return null;
    }
    filterSubject: Subject<T>;
    constructor() {
        this.filterSubject = new Subject<T>();
    }

    init(param: T) {
        this.searchParam = param;
    }
    setSearchParam(param: T) {
        this.searchParam = param;
        this.filterSubject.next(this.searchParam);
    }
}
