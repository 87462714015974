<input
  #input
  class="form-control"
  type="input"
  bsDateTimePicker
  [ngClass]="{ 'is-invalid': showError }"
  [control]="formControl"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [title]="props.title"
/>
