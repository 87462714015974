import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DynamicField } from 'src/app/shared/_models';
import { AbstractComponent } from '../../_base-component';

@Component({
  selector: 'app-d-page',
  templateUrl: './d-page.component.html',
  styleUrls: ['./d-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DPageComponent extends AbstractComponent {
  @Input() tabs: DynamicField[];

  selectedTab: DynamicField;
  activeTab: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    super();
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.activeTab = res.tab;
      }
    });
  }

  init() {
    super.init();
    this.selectedTab = this.tabs[0];
    if (!this.activeTab) {
      this.activeTab = this.tabs[0].name;
    }
  }

  onSelectTab(event, tab: DynamicField) {
    if (tab.id !== this.selectedTab?.id) {
      this.selectedTab = tab;
    }
  }
}
