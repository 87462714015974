<div class="wrapper">
  <!-- Control -->
  <div
    class="input-list form-control dropdown-toggle-control d-inline-flex align-items-center"
    (click)="toggleDropdown()"
  >
    <div class="label"><app-i18n [key]="'Group by'"></app-i18n></div>
    <div class="selected-items d-inline-flex">
      <div class="selected-item" *ngFor="let g of selectedItems?.slice(0, views); let i = index">
        {{ i + 1 }}.{{ g.name | translate }}
      </div>
      <div class="selected-item" *ngIf="selectedItems.length > views">
        +{{selectedItems.length - views}}
      </div>
    </div>
    <span class="ml-auto material-icons-outlined"> menu </span>
  </div>

  <!-- Group By -->
  <div class="dropdown-menu py-0 w-100" [ngClass]="{ show: isShowDropDown }">
    <div class="header"><app-i18n [key]="'Group by'"></app-i18n></div>
    <ng-container *ngFor="let item of selectedItems; let i = index">
      <hr class="my-0" />
      <div class="selected-item pointer" (click)="removeItem(item)">
        <div class="py-2 d-inline-flex align-items-center">
          <span
            class="pre-icon mr-1 material-icons-outlined"
            [ngStyle]="{ 'padding-left.px': i * 10 }"
          >
            subdirectory_arrow_right
          </span>
          {{ item.name | translate}}
          <div class="icon" *ngIf="item.removable">
            <span class="material-icons-outlined"> close </span>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Remain List -->
    <div class="remain-list">
      <hr class="my-0" />
      <div class="header"><app-i18n [key]="'Inactive'"></app-i18n></div>
      <div class="remain-item" *ngFor="let item of items">
        <hr class="my-0" />
        <div
          class="py-2 d-inline-flex w-100 align-items-center justify-content-between pointer"
          (click)="addItem(item)"
        >
          <div>{{ item.name | translate }}</div>
          <div class="icon">
            <span class="material-icons-outlined"> add </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
