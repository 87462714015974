<div class="form-group">
  <label><app-i18n [key]="'Icon'"></app-i18n></label>
  <div>
    <div class="checkbox-icon-list">
      <ng-container
        *ngFor="let icon of icons; let index = index">
        <div class="checkbox-icon-wrapper d-flex align-items-center"
          [ngClass]="{'selected': parentIcon === icon.icon}"
          (click)="onSelectIconIndex(icon.icon, index)"
        >
          <img [src]="icon.icon" alt="" *ngIf="!iconOfDynamic">
          <mat-icon [svgIcon]="icon.icon" class="icon" *ngIf="iconOfDynamic"></mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="form-group" *ngIf="!onlyIcon">
  <label><app-i18n [key]="'style'"></app-i18n></label>
  <div>
    <div class="checkbox-icon-list">
      <ng-container
        *ngFor="let icon of icons[iconIndex]?.child">
        <div class="checkbox-icon-wrapper"
          [ngClass]="{'selected': form.get('colorCode')?.value === icon?.colorCode}"
          (click)="onSelectIcon(icon.icon, icon.colorCode, iconIndex)"
        >
          <img [src]="icon.icon" alt="">
        </div>
      </ng-container>
    </div>
  </div>
</div>
