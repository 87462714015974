import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnChanges {
  @Input('hasPermission') permission: string | string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionCheckService: PermissionCheckService
  ) { }

  ngOnChanges() {
    this.viewContainer.clear();

    const permissions = typeof this.permission === 'string' ? [this.permission] : this.permission;
    if (permissions.some(e => this.permissionCheckService.hasPermission(e))) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
