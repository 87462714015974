import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BoardResponse, BoardSprint, BoardStatus, BoardStatusCreateRequest, List2Res, Pageable, SettingStatusRequest } from 'src/app/shared';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ProjectBoardService {
  public loading$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) { }

  getByProjectId(projectId: number): Observable<BoardResponse> {
    this.loading$.next(true);
    return this.http.get<BoardResponse>(`${environment.apiUrl}/projects/${projectId}/boards/`).pipe(
      finalize(() => this.loading$.next(false)),
    );
  }

  setStartStatus(projectId: number, statusId: number): Observable<BoardResponse> {
    this.loading$.next(true);
    return this.http.put<BoardResponse>(`${environment.apiUrl}/projects/${projectId}/boards/setting-start-status`, { statusId }).pipe(
      finalize(() => this.loading$.next(false)));
  }


  settingStatuses(projectId: number, request: SettingStatusRequest): Observable<BoardResponse> {
    return this.http.put<BoardResponse>(`${environment.apiUrl}/projects/${projectId}/boards/setting-statuses`, request);
  }

  setCloseStatus(projectId: number, statusId: number): Observable<BoardResponse> {
    this.loading$.next(true);
    return this.http.put<BoardResponse>(`${environment.apiUrl}/projects/${projectId}/boards/setting-close-status`, { statusId }).pipe(
      finalize(() => this.loading$.next(false)));
  }
}
