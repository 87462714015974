import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface Tag {
  label: string;
  value: number;
}

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsComponent),
      multi: true
    }
  ]
})
export class TagsComponent implements ControlValueAccessor {
  @Input() options: Tag[] = [];

  activeItem: Tag;
  onChange: (data: any) => void;
  onTouched: () => void;

  writeValue(value: number) {
    this.activeItem = this.options.find((e) => e.value === value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onClick(event: Tag) {
    this.writeValue(event.value);
    this.onChange(event.value);
  }

}
