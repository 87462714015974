import { AppIcon } from '../../_models';

export const LogoCompany = 'assets/icons/logo_company.svg';

export const DFieldSectionIcons: AppIcon[] = [
  {
    icon: 'section_0',
  },
  {
    icon: 'section_1',
  },
  {
    icon: 'section_2',
  },
  {
    icon: 'section_3',
  },
  {
    icon: 'section_4',
  },
  {
    icon: 'section_5',
  },
  {
    icon: 'section_6',
  },
  {
    icon: 'section_7',
  },
  {
    icon: 'section_8',
  },
  {
    icon: 'section_9',
  },
  {
    icon: 'section_10',
  },
];

export const DFieldMenuIcons: AppIcon[] = [
  {
    icon: 'menu_0',
  },
  {
    icon: 'menu_1',
  },
  {
    icon: 'menu_2',
  },
  {
    icon: 'menu_3',
  },
  {
    icon: 'menu_4',
  },
  {
    icon: 'menu_5',
  },
  {
    icon: 'menu_6',
  },
  {
    icon: 'menu_7',
  },
  {
    icon: 'menu_8',
  },
  {
    icon: 'menu_9',
  },
];
