import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FileService } from 'src/app/site-management/document/_services/file.service';

@Component({
  selector: 'app-aws-signature',
  templateUrl: './aws-signature.component.html',
  styleUrls: ['./aws-signature.component.scss']
})
export class AwsSignatureComponent implements OnInit {
  @Input() url: string;
  @Input() objectURL: string;
  @Input() template: TemplateRef<any>;
  @Output() objectURLChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
    if (!this.objectURL) {
      this.getObjectURL();
    }
  }

  getObjectURL() {
    this.fileService.getAuthenticatedUrl(this.url).subscribe(
      objectURL => {
        this.objectURL = objectURL;
        this.objectURLChange.emit(objectURL);
      }
    );
  }

}
