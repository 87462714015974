import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-emoji-preview',
  templateUrl: './emoji-preview.component.html',
  styleUrls: ['./emoji-preview.component.scss']
})
export class EmojiPreviewComponent implements OnInit {
  @Input() selectedEmoji: string;

  constructor() { }

  ngOnInit(): void {
  }

}
