import { Directive, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Validator, AbstractControl } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[requiredIf]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: RequiredIfDirective, multi: true }
  ]
})
export class RequiredIfDirective implements Validator, OnChanges {

  @Input('requiredIf') requiredIf: boolean;
  private _onChange: () => void;

  constructor() { }

  validate(c: AbstractControl) {
    const value = c.value;
    if ((value == null || value === undefined || value === '') && this.requiredIf) {
      return { required: true };
    }
    return null;
  }

  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

  ngOnChanges(changes: SimpleChanges): void {
    if ('requiredIf' in changes) {
      if (this._onChange) {
        this._onChange();
      }
    }
  }
}
