import { Pipe, PipeTransform } from '@angular/core';
import { FieldHistory } from 'src/app/shared';

@Pipe({
  name: 'isTaskHistoryEmpty'
})

export class IsTaskHistoryEmptyPipe implements PipeTransform {
  transform(value: any, fieldTask: string) {
    return (
      (!value && fieldTask !== FieldHistory.self) ||
      value?.length === 0
    )
  }
}
