import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UrlHelperService {
    constructor() {
    }

    static apiUrl(): string {
        return environment.apiUrl;
    }

    static resolveApiUrl(relativePath: string, queryParams: [{ key: string, value: string }] = null) {
        let url = relativePath ? `${environment.apiUrl}/${relativePath}`
            : `${environment.apiUrl}/`;

        if (queryParams && queryParams.length > 0) {
            const parsedQueryParams = queryParams.map(p => `${p.key}=${p.value}`).join('&');

            if (url.indexOf('?') > 0) {
                url = url + '?' + parsedQueryParams;
            } else if (!url.endsWith('/')) {
                url = url + '/?' + parsedQueryParams;
            }
        }
        return url;
    }
}
