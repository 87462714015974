import { BoardStatus } from './board-status.model';
import { Task, TaskStatusItem } from './task.model';

export interface BoardRelease {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  deleted: boolean;
  status: BoardReleaseStatus;
  numberTask: number;
  progress: TaskStatusItem[];
}

export interface BoardReleaseListResponse {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  deleted: boolean;
  status: BoardReleaseStatus;
  releaseDate: string;
  numberTask: number;
  numberTaskStart: number;
  numberTaskInProgress: number;
  numberTaskDone: number;
  numberTaskResolved: number;
  startStatus: BoardStatus;
  inprogressStatus: BoardStatus;
  doneStatus: BoardStatus;
}

export interface CountStatus {
  id: number;
  name: string;
  colorCode: string;
  defaultFlg: boolean;
  icon: string;
  deleted: boolean;
  orderNumber: number;
  numTask: number;
  count: number;
}

export enum BoardReleaseStatus {
  Released = 'Released',
  UnReleased = 'UnReleased',
}

export interface BoardReleaseSearchRequest {
  projectId?: number;
  keyword?: string;
  statuses?: string[];
  excludeIds?: number[];
}

export interface BoardReleaseCreateRequest {
  name: string;
  startDate?: string;
  endDate?: string;
  description?: string;
}

export interface BoardReleaseUpdateRequest {
  id: number;
  name: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  showPlanning?: boolean;
  storyline?: boolean;
}

export interface BoardReleaseAddTaskRequest {
  task?: Task;
  taskId: number;
  startDateTime: string;
  endDateTime: string;
}

export interface BoardReleaseCompleteRequest {
  moveToReleaseId: number;
  ignoreResoveTaskFlg: boolean;
  releaseDate: string;
}

export interface SprintRequest {
  sprintIds: number[];
}
