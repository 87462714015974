import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit, TemplateRef,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContextMenuService } from './_services/context-menu.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  @ViewChild('menu') menu: ElementRef<HTMLDivElement>;
  template: TemplateRef<any>;
  initialState?: any;

  isOpen = false;
  xPosition = 0;
  yPosition = 0;
  destroyed$ = new Subject<void>();
  constructor(
    private contextMenuService: ContextMenuService,
  ) {}

  ngOnInit(): void {
    this.contextMenuService.show$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        // this.isOpen = false;
        // setTimeout(() => {
        this.reset();
        this.template = data.template;
        this.initialState = data.config?.initialState;
        this.show(data.pointerEvent);
        // }, 0);
      });

    this.contextMenuService.hide$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.hide();
        this.template = null;
        this.initialState = null;
        this.reset();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  hide() {
    this.isOpen = false;
  }

  show(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isOpen = true;

    setTimeout(() => {
      this.detectOverflow(event.clientX, event.clientY);
    }, 100);
  }

  detectOverflow(clientX, clientY) {
    // get window width, height
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // get menu width, height
    const menuWidth = this.menu.nativeElement.offsetWidth;
    const menuHeight = this.menu.nativeElement.offsetHeight;

    // calculate menu width show out of window
    const totalHeight = menuHeight + clientY - windowHeight;
    const totalWidth = menuWidth + clientX - windowWidth;

    // space between edge of window and menu
    const SPACE = 20;

    if (totalHeight > 0) {
      this.yPosition = clientY - (totalHeight + SPACE);
    } else {
      this.yPosition = clientY;
    }

    if (totalWidth > 0) {
      this.xPosition = clientX - (totalWidth + SPACE);
    } else {
      this.xPosition = clientX;
    }
  }

  reset() {
    this.xPosition = null;
    this.yPosition = null;
  }

  onClose() {
    this.isOpen = false;
  }
}
