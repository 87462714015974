import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { PageableModel } from 'src/app/shared/_models';

@Component({
  template: ``
})
export class SearchControlComponent<T> {
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() control: UntypedFormControl;
  @Output() change = new EventEmitter();

  typing$ = new Subject<string>();
  items: T[];
  searchDto = new PageableModel();
  sub: Subscription;

  fetch() {}
}
