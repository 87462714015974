import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator, Logger, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LeaveRequest } from 'src/app/shared/_models/leave-request.model';

@Injectable({
  providedIn: 'root'
})

export class LeaveRequestDataService extends DefaultDataService<LeaveRequest> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, config: DefaultDataServiceConfig) {
    super('leave-requests', http, httpUrlGenerator, config);
  }

  // getWithQuery(queryParams: QueryParams | string): Observable<LeaveRequest[]> {
  //   return super.getWithQuery(queryParams).pipe(map((res) => {
  //     const data = res['content'];
  //     data.page = {
  //       totalElements: res['totalElements'],
  //       totalPages: res['totalPages'],
  //       pageable: res['pageable'],
  //     };
  //     return data as any;
  //   }));
  // }
}
