import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Field, FieldValue } from '../../../_models/dynamic-field.model';
import { DATE_FORMAT, FieldType } from '../../../_utils';
import { AbstractComponent } from '../../_base-component';
import { FieldDataChange } from './d-field.component';
import { takeUntil } from 'rxjs/operators';

@Directive()
export class DFieldBaseComponent extends AbstractComponent {
  @Input() field: Field;
  @Input() fieldValues: FieldValue[] = [];
  @Output() fieldChange = new EventEmitter<FieldDataChange>();
  fieldType = FieldType;
  form: UntypedFormGroup;

  constructor(
    protected fb: UntypedFormBuilder,
  ) {
    super();
  }

  init() {
    this.createForm();
    const types = [FieldType.datePicker, FieldType.select, FieldType.multiSelect];
    if (types.includes(this.field.type)) {
      this.getFormCtrl().valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
        this.emitValue(value);
      });
    }
  }

  createForm() {
    this.form = this.fb?.group({
      control: [null, this.field && this.field.isRequired ? Validators.required : []]
    });
    this.updateCtrlValue();
    if (this.field?.disabled) {
      this.form.disable();
    }
  }

  getFormCtrl(): UntypedFormControl {
    return this.form.get('control') as UntypedFormControl;
  }

  isRequiredField() {
    return this.field && this.field.isRequired;
  }

  emitValue(value: any) {
    this.fieldChange.emit({
      field: this.field,
      value,
    });
  }

  updateCtrlValue() {
    this.getFormCtrl().patchValue(this.getCurrentValue(this.field.type, this.field.value), { emitEvent: false });
  }

  getCurrentValue(type: FieldType, value: FieldValue) {
    if (!value) {
      return null;
    }

    if (type === FieldType.text) {
      return value.text;
    }

    if (type === FieldType.number) {
      return value.number;
    }

    if (type === FieldType.datePicker) {
      return value.date ? new Date(moment(value.date).format(DATE_FORMAT)) : null;
    }

    if (type === FieldType.select) {
      return value.options?.[0] ?? null;
    }

    if (type === FieldType.multiSelect) {
      return value.options ?? null;
    }

    if (type === FieldType.table) {
      return value.rows ?? [];
    }

    return null;
  }
}
