<div class="no-permission-container">
  <div><mat-icon class="" [svgIcon]="'no-permission'" class="icon-security"></mat-icon></div>
  <div class="title fw-6">
    <app-i18n key="Sorry, you can not view the page"></app-i18n>
  </div>
  <div><app-i18n key="No permission to access this page"></app-i18n>.</div>
  <div>
    <app-i18n key="To access, please contact the administrator"></app-i18n>.
  </div>
</div>
