import { NzNotificationDataOptions } from "ng-zorro-antd/notification";

export const DEFAULT_CONFIG: NzNotificationDataOptions = {
  nzPlacement: 'bottomRight',
  nzPauseOnHover: true
};

export enum ToastType {
  Success = 'Success',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}

export enum NzToastType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}