import { Component, OnInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../_models';
import { EmojiUsersOverlayService } from 'src/app/site-management/_services';
import { AuthService } from 'src/app/authentication/_services/auth.service';

@Component({
  selector: 'app-emoji-user-lists',
  templateUrl: './emoji-user-lists.component.html',
  styleUrls: ['./emoji-user-lists.component.scss']
})
export class EmojiUserListsComponent implements OnInit {
  users$: Observable<User[]>;
  loggedUser!: User;

  constructor(
    protected emojiUsersOverlay: EmojiUsersOverlayService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.users$ = this.emojiUsersOverlay.users$;
    this.loggedUser = this.authService.getLoggedUser();
  }

  isExistedMe(user: User) {
    return user.id === this.loggedUser.id;
  }
}
