import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared';
import { FADE } from 'src/app/shared/_utils/animate';
import { ProfileOverlayService } from '../../_services/profile-overlay.service';

@Component({
    selector: 'app-profile-overlay',
    templateUrl: './profile-overlay.component.html',
    styleUrls: ['./profile-overlay.component.scss'],
    animations: [FADE]
})
export class ProfileOverlayComponent implements OnInit, OnDestroy {
    user$: Observable<User>;
    activeIndex: number = 1;
    activatedIndex = {};

    constructor(protected overlay: ProfileOverlayService) {}

    ngOnInit(): void {
        this.user$ = this.overlay.user$;
    }

    ngOnDestroy(): void {
        this.overlay.user$.next(null);
    }

    onActive(index: number) {
        this.activeIndex = index;
        this.activatedIndex[index] = true;
    }
}
