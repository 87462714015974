import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserShort } from '../../_models';
import { Router } from '@angular/router';
import { BsPlacement, routerObject } from '../../_utils';

@Component({
  selector: 'app-user-avatar-list',
  templateUrl: './user-avatar-list.component.html',
  styleUrls: ['./user-avatar-list.component.scss']
})
export class UserAvatarListComponent implements OnChanges {
  @Input() users: UserShort[] = [];
  @Input() avatarLimit = 4;
  @Input() placement = BsPlacement.right;

  avatarMembers: UserShort[] = [];
  dropdownMembers: UserShort[] = [];
  isShowDropdown = false;

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.users) {
      this.getAvatarMembers();
      this.getDropdownMembers();
    }
  }

  getAvatarMembers() {
    this.avatarMembers = [];
    for (let i = 0; i < this.avatarLimit; i++) {
      if (this.users?.[i]) {
        this.avatarMembers.push(this.users[i]);
      }
    }
  }

  getDropdownMembers() {
    this.dropdownMembers = [];
    for (let i = this.avatarLimit; i < this.users?.length; ++i) {
      if (this.users?.[i]) {
        this.dropdownMembers.push(this.users[i]);
      }
    }
  }

  getShortName(user: UserShort) {
    return (
      (user.firstName?.[0] || '').toUpperCase() +
      (user.lastName?.[0] || '').toUpperCase()
    );
  }

  openDropdown() {
    if (!this.isShowDropdown) {
      setTimeout(() => {
        this.isShowDropdown = true;
      }, 50);
    }
  }

  closeMemberDropdown() {
    if (this.isShowDropdown) {
      this.isShowDropdown = false;
    }
  }

  navigateUser(user: UserShort) {
    this.router.navigate([
      `${routerObject.pageMyProfile.fullPath}/${user?.id}`
    ]);
  }
}
